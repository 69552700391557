import { useSelector } from 'react-redux';
import { Team, User, UserTeam } from 'typings';

import { Store } from 'store/reducers';

interface TeamMemberProps {
    belongsToTeamId: number | undefined;
}

const useIsATeamMember = (teamMemberProps?: TeamMemberProps): boolean => {
    const { user, selectedTeam }: { user: User; selectedTeam: Team } = useSelector((state: Store) => ({
        user: state.User as User,
        selectedTeam: state.Team.selectedTeam as Team,
    }));

    if (teamMemberProps && teamMemberProps.belongsToTeamId === undefined) {
        return false;
    }
    if (teamMemberProps?.belongsToTeamId) {
        return user?.userTeams?.some((userTeam: UserTeam) => userTeam.teamId === teamMemberProps.belongsToTeamId);
    }

    return user?.userTeams?.some((userTeam: UserTeam) => userTeam.teamId === selectedTeam?.teamId);
};

export default useIsATeamMember;
