import { BaseTextFieldProps, IconButton, InputAdornment } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { DatePickerProps, DateTimePicker } from '@material-ui/pickers';

interface Props extends DatePickerProps {
    onChange: (e: any) => void;
    value: string | null;
    id: string;
    testId?: string;
    maxDate?: Date | string;
    minDate?: Date | string;
    error?: BaseTextFieldProps['error'];
    okLabel?: string;
    onAccept?: (e: any) => void;
    onClose?: () => void;
}

export default ({
    onChange,
    value,
    id,
    testId,
    maxDate,
    minDate,
    error,
    okLabel,
    onAccept,
    onClose,
    ...muiDatePickerProps
}: Props): JSX.Element => {
    return (
        <DateTimePicker
            {...muiDatePickerProps}
            data-testid={testId}
            error={error}
            // variant="inline"
            id={id}
            ampm={false}
            inputVariant="outlined"
            value={value}
            format="DD MMM yyyy HH:mm"
            onChange={onChange}
            maxDate={maxDate}
            minDate={minDate}
            okLabel={okLabel}
            onAccept={onAccept}
            onClose={onClose}
            InputProps={{
                placeholder: 'dd mmm yyyy hhmm',
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="Date Selector">
                            <TodayIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            DialogProps={{
                PaperProps: {
                    style: {
                        padding: 0,
                    },
                },
            }}
            PopoverProps={{
                style: {
                    padding: 0,
                },
                PaperProps: {
                    style: {
                        padding: 0,
                    },
                },
            }}
        />
    );
};
