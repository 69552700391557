import { Grid } from '@material-ui/core';

import Header from 'components/layouts/header/Header';
import './App.scss';
import './assets/sass/root.css';

export default (): JSX.Element => {
    return (
        <Grid container direction="column" alignItems="stretch" wrap="nowrap">
            <Header />
        </Grid>
    );
};
