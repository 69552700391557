import { ReactElement } from 'react';

import { Grid, Link, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import { routePaths } from 'components/providers/ReactRouter';
import { WorkItem } from '../../typings/generated';

export interface Props {
    workItem: WorkItem;
    wrapText?: boolean;
    isSubtask?: boolean;
}

type TaskTitleStyleProps = {
    wrapText?: Props['wrapText'];
};

const useStyles = makeStyles((theme) =>
    createStyles({
        title: (props: TaskTitleStyleProps) => ({
            color: '#2e60c3',
            cursor: 'pointer',
            overflow: 'hidden',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            fontWeight: 'bold',
            fontSize: '16px',
            '-webkit-line-clamp': props?.wrapText ? '2' : '1',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',

            '&:hover': {
                color: '#2e60c3',
            },
        }),
        isNew: {
            color: theme.palette.secondary.main,
        },
    })
);

const TaskTitle = ({ workItem, wrapText = false, isSubtask = false }: Props): ReactElement => {
    const classes = useStyles({ wrapText });
    const { name } = workItem;

    const path = isSubtask
        ? `${routePaths.WORK_ITEM}/${workItem.parentWorkItemId}/${workItem.workItemId}`
        : `${routePaths.WORK_ITEM}/${workItem.workItemId}`;

    const LinkRouter = (props: any) => <Link {...props} className={classes.title} component={RouterLink} />;

    const isNew = workItem.isRequested && workItem?.history && workItem.history?.length < 1;

    return (
        <Tooltip title={name} placement="top-start" arrow aria-label={name}>
            <Grid item>
                <LinkRouter color="inherit" to={path}>
                    {name} {isNew && <span className={classes.isNew}> *New</span>}
                </LinkRouter>
            </Grid>
        </Tooltip>
    );
};

export default TaskTitle;
