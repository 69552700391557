import { ReactElement } from 'react';

import { KeyboardArrowDown } from '@material-ui/icons';

import { FormValueTypography } from 'components/formUtils';
import useIsATeamMember from 'hooks/useIsATeamMember';
import { StatusSelectSubComponentProps } from '../selectInterfaces';

const StatusSelectView = ({
    workItem,
    disabled,
    statuses,
    setShowForm,
    isSubtask = false,
}: StatusSelectSubComponentProps): ReactElement => {
    let disableForm = disabled;
    const userIsATeamMember = useIsATeamMember({ belongsToTeamId: workItem?.team?.teamId });
    if (!userIsATeamMember) {
        disableForm = true;
    }

    return (
        <FormValueTypography
            deleteIcon={<KeyboardArrowDown />}
            disabled={disableForm}
            value={statuses.find((s) => s.statusId === workItem.statusId)?.name}
            variant="chip"
            onClick={() => setShowForm(true)}
        />
    );
};

export default StatusSelectView;
