import { KeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { ReactElement, useState } from 'react';

interface Props {
    children: ReactElement;
}

let auth = Keycloak('/keycloak.json');

if (window.location.hostname.includes('edge-ts-afwerx')) {
    auth = Keycloak('/keycloak-afwerx-edge.json');
} else if (window.location.hostname.includes('qa-ts-afwerx')) {
    auth = Keycloak('/keycloak-afwerx-qa.json');
} else if (window.location.hostname.includes('uat-ts-afwerx')) {
    auth = Keycloak('/keycloak-afwerx-uat.json');
} else if (window.location.hostname.includes('portal.afwerx')) {
    auth = Keycloak('/keycloak-afwerx-prod.json');
} else if (window.location.hostname.includes('edge.mile-two.com')) {
    auth = Keycloak('/keycloak-m2-edge.json');
}

export default ({ children }: Props): ReactElement => {
    const [keycloakAuth] = useState(auth || Keycloak('/keycloak.json'));
    return <KeycloakProvider keycloak={keycloakAuth}>{children}</KeycloakProvider>;
};
