import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
} from '@material-ui/core';

import { UPDATE_WORK_ITEM } from '../../api/mutations/workItem';
import { WorkItem } from '../../typings/workItem';
import AddedFile, { ClickResponse } from './AddedFile';
import DropZone, { LocalFile } from './DropZone';

const useStyles = makeStyles({
    addedItemContainer: {
        marginTop: '24px',
    },
});

export interface Props {
    isOpen: boolean;
    workItem: WorkItem;
    onClose: (updateWorkItemData?: any) => void;
}

export default ({ isOpen = false, workItem, onClose }: Props): JSX.Element => {
    const [files, setFiles] = useState<Array<LocalFile>>([]);
    const [sizes, setSizes] = useState<Array<number>>([]);
    const [disableButton, setDisableButton] = useState(true);
    const clearState = () => {
        setFiles([]);
        setSizes([]);
    };

    const [updateWorkItem] = useMutation(UPDATE_WORK_ITEM, {
        onCompleted: (response) => {
            if (response?.updateWorkItem) {
                clearState();
                onClose(response.updateWorkItem);
            }
        },
    });
    const classes = useStyles();

    useEffect(() => {
        files.forEach((file) => URL.revokeObjectURL(file.url));
    }, [files]);

    const onDrop = (newFiles: File[]) => {
        setFiles((prevState) => [
            ...prevState,
            ...newFiles.map((file) => Object.assign(file, { url: URL.createObjectURL(file) })),
        ]);

        setSizes((prevState) => [...prevState, ...newFiles.map((file) => file.size)]);
        setDisableButton(false);
    };

    const onUpload = () => {
        setDisableButton(true);
        updateWorkItem({
            variables: {
                workItemId: workItem.workItemId,
                data: {},
                files,
                sizes,
            },
        });
    };

    const onCancel = () => {
        clearState();
        onClose();
    };

    const onOpenLocalFile = ({ url }: ClickResponse) => {
        window.open(url);
    };

    const onDelete = ({ url }: ClickResponse) => {
        setFiles((prevState) => {
            if (prevState.length === 1) {
                setDisableButton(true);
            }
            return prevState.filter((file) => file.url !== url);
        });
    };

    return (
        <Dialog open={isOpen} fullWidth>
            <DialogTitle>Upload file</DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary">
                    Select a document to attach to this work item.
                </DialogContentText>
                <DropZone onDropFiles={onDrop} />
                <div className={classes.addedItemContainer}>
                    {files.map((f) => (
                        <AddedFile
                            key={f.url}
                            isDownloadable={false}
                            url={f.url}
                            fileName={f.name}
                            lastModified={f.lastModified}
                            onClick={onOpenLocalFile}
                            onDelete={onDelete}
                        />
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="primary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" disabled={disableButton} onClick={onUpload}>
                    Upload File
                </Button>
            </DialogActions>
        </Dialog>
    );
};
