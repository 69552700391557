import update from 'immutability-helper';

import { WorkItem } from 'typings/generated';
import {
    ADD_CHILDREN,
    CLEAR_SELECTED_WORK_ITEM,
    REMOVE_CHILD,
    SET_IS_DIRTY,
    SET_SELECTED_WORK_ITEM,
    UPDATE_CHILD,
    UPDATE_PARENT,
} from '../actions/SelectedItem';
import { Action } from '../index';

export interface State {
    workItem: WorkItem | undefined;
    isDirty: boolean;
}

const initialState: State = {
    workItem: undefined,
    isDirty: false,
};

export default (prevState: State, action: Action): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;
    switch (type) {
        case SET_SELECTED_WORK_ITEM: {
            return { ...state, workItem: payload.selectedWorkItem };
        }
        case CLEAR_SELECTED_WORK_ITEM: {
            return { ...state, workItem: undefined };
        }
        case UPDATE_PARENT: {
            return { ...state, workItem: { ...state.workItem, ...(payload.modifiedWorkItem as WorkItem) } };
        }
        case UPDATE_CHILD: {
            if (!state.workItem?.children || state?.workItem?.children.length === 0) {
                return { ...state };
            }

            const childIndex = state.workItem?.children?.findIndex((child) => child.workItemId === payload.id);

            if (childIndex !== -1) {
                return {
                    ...state,
                    workItem: update(state.workItem, {
                        children: {
                            [childIndex]: {
                                $merge: {
                                    ...payload.modifiedWorkItem,
                                },
                            },
                        },
                    }),
                };
            }

            return { ...state };
        }
        case ADD_CHILDREN: {
            return {
                ...state,
                workItem: update(state.workItem, {
                    children: {
                        $push: [...(payload.items as WorkItem[])],
                    },
                }),
            };
        }
        case REMOVE_CHILD: {
            return {
                ...state,
                workItem: update(state.workItem, {
                    children: {
                        $set: state.workItem?.children?.filter((child) => child.workItemId !== payload.id) || [],
                    },
                }),
            };
        }
        case SET_IS_DIRTY: {
            return { ...state, ...payload };
        }
        default:
            return { ...state };
    }
};
