import { SET_NAVIGATION } from '../actions/Navigation';
import { Action } from '../index';

export type NavPayload = any;

export type State = {
    navLocation: string;
};

const initialState: State = {
    navLocation: '/',
};

export default (prevState: State, action: Action): State => {
    const state = prevState ?? initialState;
    const { type } = action;

    // Change this back to a switch statement when the actions exceed 2
    // sonarqube requires at least 3 cases in a switch

    if (type === SET_NAVIGATION) {
        const navLocation = action.payload as NavPayload;
        return { ...state, navLocation };
    }

    return { ...state };
};
