// eslint-disable-next-line no-use-before-define
import React, { ReactElement, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import axe from '@axe-core/react';
import { Paper } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import PrivateRoute from 'PrivateRoute';
import ReactDOM from 'react-dom';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Organization, User, UserTeam } from 'typings';

import { GET_TEAMS } from 'api/queries/team';
import useFetchTypeTables from 'hooks/useFetchTypeTables';
import AssignUser from 'pages/AssignUser';
import { setOrganizations, setSelectedOrganization } from 'store/actions/Organization';
import { setSelectedTeam, setTeams } from 'store/actions/Team';
import { setUserState } from 'store/actions/User';
import { setViews } from 'store/actions/View';
import { UserState } from 'store/reducers/User';
import { Team as TeamType, useGetUserLazyQuery, View } from 'typings/generated';
import { getStorageItem, setStorageItem } from 'utils/TypeSafeStorage';
import NoPage from '../../pages/404';
import Team from '../../pages/Team';
import Work from '../../pages/Work';
import WorkDetails from '../../pages/WorkDetails';
import { Store } from '../../store/reducers';
import AppContext from './App';

export const routePaths = {
    ASSIGN_USER: 'assignUser',
    NOT_FOUND: '404',
    TEAMS: 'teams',
    WORK_ITEM: 'workItem',
    WORK_ITEMS: 'workItems',
};

export const ReactRouter = (): ReactElement => {
    const dispatch = useDispatch();
    const [keycloak, initialized] = useKeycloak();
    const [fetchAllTypeTables] = useFetchTypeTables();
    const user = useSelector((state: Store) => state.User);
    const colorScheme = useSelector((state: Store) => state.MuiTheme.colorScheme);
    const { selectedOrganization } = useSelector((state: Store) => state.Organization);

    const { id } = useSelector((state: Store) => state.User as User);

    const [tabIndex, changeTabIndex] = useState(0);

    const gridSpacing = 3;

    const getUserTeams = (teams: TeamType[]): TeamType => {
        const userTeamIds = user?.userTeams?.map((userTeam: UserTeam) => userTeam.teamId);
        const defaultTeamInSelectedOrganization: TeamType = teams.filter((team: TeamType) =>
            userTeamIds.includes(team.teamId)
        )[0];

        const teamInSessionStorage = getStorageItem<TeamType>('selected-team');
        const isTeamInTheSelectedOrganization =
            teamInSessionStorage?.organizationId === defaultTeamInSelectedOrganization?.organizationId;

        if (teamInSessionStorage && isTeamInTheSelectedOrganization) {
            // Is the selected team from the team selector, a team in the current organization
            return teamInSessionStorage;
        }
        return defaultTeamInSelectedOrganization;
    };

    const [getTeams] = useLazyQuery(GET_TEAMS, {
        onCompleted: (data) => {
            dispatch(setTeams(data.teams));
            const newSelectedTeam: TeamType = getUserTeams(data.teams);
            dispatch(setSelectedTeam(newSelectedTeam));
            setStorageItem('selected-team', newSelectedTeam);
        },
    });

    const [getUser] = useGetUserLazyQuery({
        onCompleted: (data) => {
            dispatch(
                setUserState({
                    ...data.user,
                    organizations: data.user.organizations?.map((userOrg) => userOrg.organization),
                } as UserState)
            );
            dispatch(setViews(data.user.views as View[]));

            dispatch(
                setOrganizations(data.user.organizations?.map((userOrg) => userOrg.organization) as Organization[])
            );
            const organizationSession = getStorageItem<Organization>('selected-organization');
            const newSelectedOrganization = organizationSession || data.user.organizations?.[0].organization;

            dispatch(setSelectedOrganization(newSelectedOrganization as Organization));
            setStorageItem('selected-organization', newSelectedOrganization);
            fetchAllTypeTables();
        },
    });

    useEffect(() => {
        if (selectedOrganization?.organizationId) {
            getTeams({
                variables: {
                    organizationId: selectedOrganization.organizationId,
                },
            });
        }
    }, [selectedOrganization, getTeams]);

    const setTabIndex = (index: number) => {
        changeTabIndex(index);
    };

    useEffect(() => {
        if (initialized && keycloak.token && !id) {
            getUser();
        }
    }, [initialized, keycloak, getUser, id]);

    if (initialized && !keycloak.authenticated) {
        keycloak.login();
    }

    /**
     * Accessibility tool - outputs to devtools console on dev only and client-side only.
     * @see https://github.com/dequelabs/axe-core-npm
     */
    if (process.env.NODE_ENV === 'development') {
        axe(React, ReactDOM, 1000);
    }

    const validRender = id && selectedOrganization?.organizationId;

    return (
        <Paper elevation={0} square style={{ height: '100vh', padding: 0 }}>
            <MetaTags>
                <meta name="color-scheme" content={colorScheme} />
            </MetaTags>
            <AppContext.Provider value={{ tabIndex, setTabIndex, gridSpacing, unavailable: '' }}>
                {validRender && (
                    <div className="content">
                        <Switch>
                            <PrivateRoute exact path="/">
                                <Redirect to={`/${routePaths.WORK_ITEMS}`} />
                            </PrivateRoute>
                            <PrivateRoute path={`/${routePaths.WORK_ITEMS}`} component={Work} exact />
                            <Redirect from="/team" to={`/${routePaths.TEAMS}`} />
                            <PrivateRoute path={`/${routePaths.TEAMS}`} component={Team} exact />
                            <PrivateRoute path={`/${routePaths.WORK_ITEM}/new`} component={WorkDetails} exact />
                            <PrivateRoute
                                path={`/${routePaths.WORK_ITEM}/:id/:subtaskId?`}
                                component={WorkDetails}
                                exact
                            />
                            <PrivateRoute path={`/${routePaths.ASSIGN_USER}`} component={AssignUser} exact />
                            <Route path={`/${routePaths.NOT_FOUND}`} component={NoPage} exact />
                            <Route component={NoPage} />
                        </Switch>
                    </div>
                )}
            </AppContext.Provider>
        </Paper>
    );
};

export default ReactRouter;
