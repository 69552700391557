import update from 'immutability-helper';

import { WorkItem as Item } from '../../typings/workItem';
import { Payload, State } from './WorkItems';

const removeDuplicateSubtasks = (workItems: Item[]): Item[] =>
    workItems.filter((workItem) => (workItem.parent ? workItem.parent.teamId !== workItem.teamId : workItem));

const handleAddItemToPlanned = (unshiftedState: State, payload: Payload): State => {
    return update(unshiftedState, {
        planned: {
            $apply: (items: Item[]): Item[] => {
                return removeDuplicateSubtasks(items);
            },
        },
    });
};

const handleUpdatePlannedItem = (state: State, payload: Payload): State => {
    // if (payload.parentWorkItemId) {
    // const parentIndex = state.planned.findIndex((planned) => planned.workItemId === payload.parentWorkItemId);
    // if (parentIndex !== -1) {
    //     const childIndex =
    //         state.planned[parentIndex].children?.findIndex(
    //             (child) => child.workItemId === payload.item.workItemId
    //         ) || 0;
    //     return {
    //         ...state,
    //         planned: update(state.planned, {
    //             [parentIndex]: {
    //                 children: {
    //                     [childIndex]: {
    //                         $merge: {
    //                             ...payload.item,
    //                         },
    //                     },
    //                 },
    //             },
    //         }),
    //     };
    // }
    // }
    const index = state.planned.findIndex((planned) => planned.workItemId === payload.item.workItemId);
    return {
        ...state,
        planned: update(state.planned, {
            [index]: {
                $set: {
                    ...state.planned[index],
                    ...payload.item,
                },
            },
        }),
    };
};

export { handleAddItemToPlanned, removeDuplicateSubtasks, handleUpdatePlannedItem };
