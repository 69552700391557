import { makeStyles } from '@material-ui/core';
import { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import { SetWorkDetailsTab } from 'pages/WorkDetails';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Attachment } from 'typings';
import { Attachment as GeneratedAttachment, WorkItem as GeneratedWorkItem } from 'typings/generated';
import { WorkItem } from '../../../typings/workItem';
import SidePanelLayout from '../../layouts/contentLayouts/SidePanelLayout';
import AttachmentsSidebar from '../AttachmentsSidebar';
import CenterContent from './center/CenterContent';

const useStyles = makeStyles({
    container: {
        height: '100%',
    },
});

interface Props {
    workItem: GeneratedWorkItem;
    onUpdateWorkItem: UpdateWorkItemHandler;
    setWorkDetailsTab: SetWorkDetailsTab;
}

export interface CurrentTeam {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
}

export default ({ workItem, onUpdateWorkItem, setWorkDetailsTab }: Props): ReactElement => {
    const methods = useForm({ defaultValues: workItem, criteriaMode: 'all' });
    const classes = useStyles();
    const [fieldIsBeingEdited, setFieldIsBeingEdited] = useState(false);

    const { reset } = methods;

    useEffect(() => {
        reset(workItem);
    }, [reset, workItem]);

    const onEnableEditingOnOtherFields = () => setFieldIsBeingEdited(false);
    const onDisableEditingOnOtherFields = () => setFieldIsBeingEdited(true);

    const getSubtaskAttachments = (subtasks: WorkItem[] | undefined): Attachment[] => {
        return (
            subtasks?.flatMap((subtask) => {
                if (subtask?.attachments?.length !== 0) {
                    return subtask.attachments as Attachment[];
                }
                return [];
            }) || []
        );
    };

    const getAttachmentRollup = () => {
        const workItemAttachments = workItem?.attachments || [];
        const workItemSubtasks = workItem?.children?.filter((child) => !child.isRemoved) || [];
        const subtaskAttachments = getSubtaskAttachments(workItemSubtasks as WorkItem[]);

        return [...(workItemAttachments || []), ...(subtaskAttachments || [])];
    };

    return (
        <FormProvider {...methods}>
            <div className={classes.container}>
                <SidePanelLayout
                    rightPanel={
                        <AttachmentsSidebar
                            workItem={workItem as WorkItem}
                            onUpdateWorkItem={onUpdateWorkItem}
                            attachments={getAttachmentRollup() as GeneratedAttachment[]}
                        />
                    }
                >
                    <CenterContent
                        workItem={workItem}
                        onUpdateWorkItem={onUpdateWorkItem}
                        fieldIsBeingEdited={fieldIsBeingEdited}
                        enableEditingOnOtherFields={onEnableEditingOnOtherFields}
                        disableEditingOnOtherFields={onDisableEditingOnOtherFields}
                        setWorkDetailsTab={setWorkDetailsTab}
                    />
                </SidePanelLayout>
            </div>
        </FormProvider>
    );
};
