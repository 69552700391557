import { Organization } from '../../typings/organization';
import { SET_ORGANIZATIONS, SET_SELECTED_ORGANIZATION } from '../actions/Organization';
import { Action } from '../index';

export interface State {
    organizations: Organization[];
    selectedOrganization: Organization | null;
}

const initialState: State = {
    organizations: [],
    selectedOrganization: null,
};

interface Payload {
    organizations: Organization[];
    organization: Organization | null;
}

interface OrganizationAction extends Omit<Action, 'payload'> {
    payload: Payload;
}

export default (prevState: State, action: OrganizationAction): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;
    switch (type) {
        case SET_ORGANIZATIONS: {
            return { ...state, organizations: payload.organizations };
        }
        case SET_SELECTED_ORGANIZATION: {
            return { ...state, selectedOrganization: payload.organization };
        }
        default:
            return { ...state };
    }
};
