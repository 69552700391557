import { ReactElement } from 'react';

import { Provider } from 'react-redux';
import store from 'store';

interface Props {
    children: ReactElement[] | ReactElement;
}

export default function Redux({ children }: Props): JSX.Element {
    return <Provider store={store}>{children}</Provider>;
}
