import { Action } from 'store';

import { View } from 'typings/generated';

export const ADD_VIEW = 'ADD_VIEW';
export const SET_VIEWS = 'SET_VIEWS';
export const UPDATE_VIEW = 'UPDATE_VIEW';

export const addView = (view: View): Action => ({
    type: ADD_VIEW,
    payload: { view },
});

export const setViews = (views: View[]): Action => ({
    type: SET_VIEWS,
    payload: { views },
});

export const updateView = (view: Partial<View>, isDirty?: boolean): Action => ({
    type: UPDATE_VIEW,
    payload: { view, isDirty },
});
