import { ReactElement, useEffect, useState } from 'react';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WorkItem } from 'typings';

import SidePanelLayout from 'components/layouts/contentLayouts/SidePanelLayout';
import { routePaths } from 'components/providers/ReactRouter';
import { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import { SetWorkDetailsTab } from 'pages/WorkDetails';
import { addChildren } from 'store/actions/SelectedItem';
import { Store } from 'store/reducers';
import { useCreateWorkItemMutation, WorkItem as GeneratedWorkItem } from 'typings/generated';
import AttachmentsSidebar from '../AttachmentsSidebar';
import CenterContent from '../overview/center/CenterContent';
import CreateSubtaskButton from './CreateSubtaskButton';
import ProjectStatusSidebar from './leftPanel/ProjectStatusSidebar';

interface Props {
    workItem: GeneratedWorkItem | Partial<GeneratedWorkItem>;
    subtaskId: number | undefined;
    onUpdateWorkItem: UpdateWorkItemHandler;
    filterSubItem: (workItemId: number | undefined) => void;
    requested?: boolean;
    setWorkDetailsTab: SetWorkDetailsTab;
}

interface OnCompleted {
    workItem: GeneratedWorkItem;
    teamId: number;
}

export default ({
    workItem,
    subtaskId: urlSubTaskId,
    onUpdateWorkItem,
    filterSubItem,
    requested = false,
    setWorkDetailsTab,
}: Props): ReactElement => {
    const [selectedSubtask, setSelectedSubtask] = useState<GeneratedWorkItem | undefined>(undefined);
    const { selectedTeam } = useSelector((state: Store) => state.Team);
    const { statuses } = useSelector((state: Store) => state.Status);
    const [fieldIsBeingEdited, setFieldIsBeingEdited] = useState(false);

    const notStarted = statuses.find((status) => status.name === 'Not Started');

    const history = useHistory();
    const dispatch = useDispatch();

    const methods = useForm({ defaultValues: selectedSubtask, criteriaMode: 'all' });
    const { reset } = methods;

    const onSetSelected = (newSubtaskId: number | undefined) => {
        const parentId = workItem?.workItemId;
        if (parentId && newSubtaskId) {
            history.push(`/${routePaths.WORK_ITEM}/${workItem?.workItemId}/${newSubtaskId}`);
        }
    };

    const handleSubItemRoute = () => {
        setSelectedSubtask(undefined);
        const path = history.location.pathname;
        history.replace(path.slice(0, path.lastIndexOf('/')));
        filterSubItem(selectedSubtask?.workItemId);
    };

    const handleOnCompleted = ({ workItem: createdWorkItem, teamId }: OnCompleted) => {
        dispatch(addChildren([createdWorkItem]));
    };

    const [createNewSubtask, { loading }] = useCreateWorkItemMutation({
        onCompleted: (response): void => {
            handleOnCompleted({
                workItem: response.createWorkItem as GeneratedWorkItem,
                teamId: selectedTeam?.teamId as number,
            });
            history.push(`/${routePaths.WORK_ITEM}/${workItem?.workItemId}/${response.createWorkItem.workItemId}`);
            setSelectedSubtask(response.createWorkItem as GeneratedWorkItem);
        },
    });

    const onClickCreateSubtask = () => {
        createNewSubtask({
            variables: {
                data: {
                    name: `New Subtask of ${workItem?.name}`,
                },
                foreignKeys: {
                    itemTypeId: workItem?.itemTypeId as number,
                    teamId: workItem?.team?.teamId as number,
                    parentWorkItemId: workItem?.workItemId as number,
                    statusId: notStarted?.statusId as number,
                },
            },
        });
    };

    useEffect(() => {
        reset(selectedSubtask);
    }, [reset, selectedSubtask]);

    useEffect(() => {
        if (urlSubTaskId) {
            const subTaskFromUrl = workItem?.children?.find((subtask) => subtask.workItemId === urlSubTaskId);
            setSelectedSubtask(subTaskFromUrl);
        }
    }, [urlSubTaskId, workItem?.children, setSelectedSubtask]);

    const onEnableEditingOnOtherFields = () => setFieldIsBeingEdited(false);
    const onDisableEditingOnOtherFields = () => setFieldIsBeingEdited(true);

    const centerContent = selectedSubtask ? (
        <CenterContent
            workItem={selectedSubtask}
            onUpdateWorkItem={onUpdateWorkItem}
            fieldIsBeingEdited={fieldIsBeingEdited}
            enableEditingOnOtherFields={onEnableEditingOnOtherFields}
            disableEditingOnOtherFields={onDisableEditingOnOtherFields}
            useSubtaskView
            setWorkDetailsTab={setWorkDetailsTab}
            handleSubTaskRoute={handleSubItemRoute}
        />
    ) : (
        <Grid direction="column" container>
            <Typography variant="h5" component="h2">
                No Subtask Selected
            </Typography>
            <CreateSubtaskButton onClickCreateSubtask={onClickCreateSubtask} teamId={workItem.teamId as number} />
        </Grid>
    );

    return (
        <FormProvider {...methods}>
            <SidePanelLayout
                leftPanel={
                    <ProjectStatusSidebar
                        workItem={workItem as GeneratedWorkItem}
                        selectedSubtask={selectedSubtask}
                        setSelectedSubtask={onSetSelected}
                        onClickCreateSubtask={onClickCreateSubtask}
                    />
                }
                rightPanel={
                    selectedSubtask ? (
                        <AttachmentsSidebar
                            workItem={selectedSubtask as WorkItem}
                            attachments={selectedSubtask?.attachments || []}
                            onUpdateWorkItem={onUpdateWorkItem}
                        />
                    ) : null
                }
            >
                {loading && (
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <CircularProgress color="secondary" aria-label="Content Loading" />
                        </Grid>
                    </Grid>
                )}
                {!loading && centerContent}
            </SidePanelLayout>
        </FormProvider>
    );
};
