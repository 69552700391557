import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addChildren } from 'store/actions/SelectedItem';
import { openSnackbar } from 'store/actions/Snackbar';
import { useDuplicateWorkItemMutation, useGetWorkItemDescendantCountLazyQuery } from 'typings/generated';

import { IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import useIsATeamMember from '../../hooks/useIsATeamMember';
import { Store } from '../../store/reducers';
import { WorkItem } from '../../typings';
import { WorkItem as GeneratedWorkItem } from '../../typings/generated';
import Delete from './DeleteAction';

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 'bold',
    },
}));

interface Props {
    selectedId: number | null;
    workItem: WorkItem | Partial<WorkItem>;
    disabled: boolean;
    isSubTask?: boolean;
    handleSubTaskRoute?: () => void;
}

export default ({ workItem, selectedId, disabled, isSubTask, handleSubTaskRoute }: Props): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { user } = useSelector((state: Store) => ({
        user: state.User,
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [disableMenu, setDisableMenu] = useState(false);
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const classes = useStyles();
    const userIsATeamMember = useIsATeamMember();

    const openMenu = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const closeMenu = () => setAnchorEl(null);
    const [getDescendantTotalCount, { data }] = useGetWorkItemDescendantCountLazyQuery({ fetchPolicy: 'network-only' });

    const [duplicateWorkItem] = useDuplicateWorkItemMutation({
        variables: {
            workItemId: workItem.workItemId as number,
        },
        onCompleted: ({ duplicateWorkItem: newWorkItem }) => {
            if (isSubTask) {
                // Update the list of subtasks in the subtasks tab
                dispatch(addChildren([newWorkItem] as GeneratedWorkItem[]));
            }
            dispatch(
                openSnackbar({
                    message: 'Your Work Item has been duplicated',
                    variant: 'Success',
                    actionName: 'View Work Item',
                    onActionClick: () => {
                        history.push(`${newWorkItem.workItemId}`);
                    },
                })
            );
        },
    });

    const duplicateWork = () => {
        duplicateWorkItem();
        closeMenu();
    };

    const onOpenDelete = () => {
        closeMenu();
        setDeleteIsOpen(true);
        if (workItem?.workItemId) {
            getDescendantTotalCount({ variables: { workItemId: workItem?.workItemId } });
        }
    };

    const onCloseDelete = () => setDeleteIsOpen(false);

    useEffect(() => {
        const isMenuDisabled = !userIsATeamMember;

        setDisableMenu(isMenuDisabled);
    }, [workItem, user, userIsATeamMember]);

    return (
        <div>
            <IconButton
                color="primary"
                disabled={disableMenu || disabled}
                onClick={openMenu}
                data-testid="more-vert"
                aria-label="Work Item Actions"
            >
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div>
                    <MenuItem button onClick={duplicateWork}>
                        <Typography variant="body2" className={classes.bold}>
                            Duplicate Item
                        </Typography>
                    </MenuItem>
                    <MenuItem button onClick={onOpenDelete}>
                        <Typography variant="body2" className={classes.bold}>
                            Delete Item
                        </Typography>
                    </MenuItem>
                </div>
            </Menu>
            <Delete
                isSubTask={isSubTask}
                isOpen={deleteIsOpen}
                onClose={onCloseDelete}
                workItem={workItem}
                descendantCount={data?.workItemDescendantCount || 0}
                handleSubTaskRoute={handleSubTaskRoute}
            />
        </div>
    );
};
