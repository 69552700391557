import { Typography } from '@material-ui/core';

interface Props {
    title: string;
    editing?: boolean;
    required?: boolean;
}

export default ({ title, required, editing = false }: Props): JSX.Element => {
    return (
        <div>
            <Typography
                variant="body2"
                color={editing ? 'textPrimary' : 'textSecondary'}
                gutterBottom={!required}
                aria-label={title}
            >
                {title}
            </Typography>
            {required && editing ? (
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    * required
                </Typography>
            ) : null}
        </div>
    );
};
