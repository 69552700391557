import { Action } from 'store';
import { Organization } from '../../typings/organization';

export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';

export const setOrganizations = (organizations: Organization[]): Action => ({
    type: SET_ORGANIZATIONS,
    payload: { organizations },
});

export const setSelectedOrganization = (organization: Organization): Action => ({
    type: SET_SELECTED_ORGANIZATION,
    payload: { organization },
});
