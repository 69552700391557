import { DialogContent, Grid, makeStyles, Typography } from '@material-ui/core';
import AddedFile, { Props as AddedFileProps } from '../../attachments/AddedFile';
import DropZone, { LocalFile, Props as DropZoneProps } from '../../attachments/DropZone';
import NewWorkForm, { Props as NewWorkFormProps } from '../NewWorkForm';

const useStyles = makeStyles({
    attachmentTitle: {
        fontWeight: 'bold',
    },
    dialogContent: {
        // Remove extra scrollbar in dialog content
        overflow: 'hidden',
    },
});

type ChildrenComponentProps = NewWorkFormProps & DropZoneProps;

interface Props extends ChildrenComponentProps {
    files: LocalFile[];
    onOpenLocalFile: AddedFileProps['onClick'];
    onDelete: AddedFileProps['onDelete'];
}

export default ({ files, onDropFiles, requested, onDelete, onOpenLocalFile }: Props): JSX.Element => {
    const classes = useStyles();
    return (
        <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                    <NewWorkForm requested={requested} />
                </Grid>

                <Grid item>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        component="div"
                        gutterBottom
                        className={classes.attachmentTitle}
                    >
                        Related Attachments
                    </Typography>
                    <DropZone onDropFiles={onDropFiles} />
                </Grid>
                <Grid item container spacing={2}>
                    {files.map((f) => (
                        <Grid item xs={12}>
                            <AddedFile
                                isDownloadable={false}
                                fileName={f.name}
                                lastModified={f.lastModified ?? new Date().toLocaleString()}
                                url={f.url}
                                onClick={onOpenLocalFile}
                                onDelete={onDelete}
                                isGoogleDriveFile={f.isGoogleDriveFile}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </DialogContent>
    );
};
