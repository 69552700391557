import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    bold: {
        fontWeight: 'bold',
    },
});

interface Props {
    title: string;
}

export default ({ title }: Props): JSX.Element => {
    const classes = useStyles();
    return (
        <Typography variant="body1" className={classes.bold}>
            {title}
        </Typography>
    );
};
