import { ChangeEvent, ReactElement } from 'react';

import { Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { defaultBorder } from 'assets/themes/base';
import { a11yProps } from 'components/tabs/TabPanel';

export const SUB_HEADER_HEIGHT = 60;

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.background.default,
        borderBottom: defaultBorder(theme),
        height: SUB_HEADER_HEIGHT,
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    subHeader: {
        paddingLeft: '1.75em',
        paddingRight: '1.25em',
    },
    tabs: {
        paddingLeft: '2em',
    },
    tab: {
        textTransform: 'none',
        fontSize: '14px',
        minWidth: '60px',
    },
}));

export type Props =
    | {
          show: true;
          tabs: string[];
          currentTab: number;
          previousTab?: number;
          onTabChange: (event: ChangeEvent<unknown>, newValue: number) => void;
          leftContent?: ReactElement | ReactElement[] | null;
          rightContent?: ReactElement | ReactElement[] | null;
          responsiveTabs?: boolean;
      }
    | {
          show?: false;
          tabs?: never;
          currentTab?: never;
          previousTab?: never;
          onTabChange?: never;
          leftContent?: never;
          rightContent?: never;
          responsiveTabs?: boolean;
      };

export default ({
    tabs,
    currentTab,
    onTabChange,
    previousTab,
    show,
    leftContent = null,
    rightContent = null,
    responsiveTabs = false,
}: Props): JSX.Element | null => {
    const classes = useStyles();
    const theme = useTheme();

    if (!show) {
        return null;
    }

    return (
        <Grid container className={classes.header} alignItems="center" id="subheader">
            <Grid container item className={classes.subHeader} justify="center" alignItems="center">
                <Grid item xs={5}>
                    <Grid container justify="flex-start" alignItems="center" spacing={0}>
                        {leftContent}
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Tabs
                        className={classes.tabs}
                        value={
                            window.innerWidth > theme.breakpoints.values.xl && currentTab === 3
                                ? previousTab
                                : currentTab
                        }
                        onChange={onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        {((responsiveTabs && window.innerWidth < theme.breakpoints.values.xl) || !responsiveTabs) &&
                            tabs?.map((tabName, index) => (
                                <Tab
                                    className={classes.tab}
                                    label={tabName}
                                    key={index}
                                    {...a11yProps(index, 'subheader', tabName)}
                                />
                            ))}
                    </Tabs>
                </Grid>

                <Grid item xs={3}>
                    {rightContent}
                </Grid>
            </Grid>
        </Grid>
    );
};
