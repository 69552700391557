import { Button, ButtonProps } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/actions/Snackbar';
import { useCreateFeedbackMutation } from 'typings/generated';
import FeedbackDialog, { FormData } from './FeedbackDialog';

const Feedback = ({ className }: ButtonProps): ReactElement => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [createFeedback] = useCreateFeedbackMutation({
        onCompleted: () => {
            setIsOpen(false);
            dispatch(openSnackbar({ message: 'Your feedback has been submitted.  Thank you!', variant: 'Success' }));
        },
    });

    const onClick = () => setIsOpen(true);

    const onCancel = () => setIsOpen(false);

    const onSubmit = (formData: FormData) => {
        createFeedback({
            variables: {
                data: formData,
            },
        });
    };

    return (
        <>
            <Button variant="text" className={className} onClick={onClick}>
                Submit Feedback
            </Button>
            {isOpen ? <FeedbackDialog isOpen={isOpen} onSubmit={onSubmit} onCancel={onCancel} /> : null}
        </>
    );
};

export default Feedback;
