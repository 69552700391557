import { ChangeEvent, ReactElement, useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import FieldTitle from 'components/formUtils/FieldTitle';
import { setSelectedTeam } from 'store/actions/Team';
import { setUserState } from 'store/actions/User';
import { setViews } from 'store/actions/View';
import { Store } from 'store/reducers';
import { UserState } from 'store/reducers/User';
import { Team, useAddUserToTeamMutation, useCreateViewMutation, useGetUserLazyQuery, View } from 'typings/generated';
import { sortByName } from 'utils/sortBy';
import { setStorageItem } from 'utils/TypeSafeStorage';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingBottom: '10px',
    },
    placeholder: {
        color: theme.palette.text.hint,
        fontSize: '14px',
    },
    title: {
        marginBottom: '16px',
    },
    bold: {
        fontWeight: 'bold',
    },
    titleText: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    fieldNameText: {
        fontSize: '14px',
    },
    attachmentsBody: {
        margin: '16px 0',
    },
    infoIcon: {
        marginLeft: '4px',
    },
}));

const Onboarding = (): ReactElement | null => {
    const dispatch = useDispatch();
    const { userTeams, firstName, lastName, email, id } = useSelector((state: Store) => state.User);
    const { teams } = useSelector((state: Store) => state.Team);
    const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
    const classes = useStyles();

    const teamsAlphabetized = sortByName(teams);

    const handleTeamSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
        setSelectedTeamId(event.target.value as number);
    };

    const [executeGetUser] = useGetUserLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            dispatch(
                setUserState({
                    ...data.user,
                    organizations: data.user.organizations?.map((userOrg) => userOrg.organization),
                } as UserState)
            );
            dispatch(setViews(data.user.views as View[]));
            const selectedTeam = teams.find((team) => team.teamId === (selectedTeamId as number)) as Team;
            dispatch(setSelectedTeam(selectedTeam));
            setStorageItem('selected-team', selectedTeam);
        },
    });

    const [executeCreateView] = useCreateViewMutation({ onCompleted: () => executeGetUser() });

    const [executeAddUserToTeam] = useAddUserToTeamMutation({
        onCompleted: () => {
            executeCreateView({
                variables: {
                    data: {
                        email,
                        name: `${teams.find((team) => team.teamId === (selectedTeamId as number))?.name}'s Work`,
                        viewTypeId: 1,
                        isOpen: true,
                        filter: {
                            teamId: {
                                in: [selectedTeamId as number],
                            },
                            statusId: {
                                in: [1, 2, 5, 7, 8, 9],
                            },
                        },
                    },
                },
            });
        },
    });

    const onSubmit = () => executeAddUserToTeam({ variables: { email, teamId: selectedTeamId as number } });

    if (userTeams.length === 0 && teams.length > 0) {
        return (
            <Dialog open maxWidth="md">
                <DialogTitle>
                    Welcome {firstName} {lastName}!
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">Please review and complete the following fields.</Typography>
                    <Grid container>
                        <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                            <Grid item xs={12}>
                                <FieldTitle title="First Name" editing required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">{firstName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                            <Grid item xs={12}>
                                <FieldTitle title="Last Name" editing required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">{lastName}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                            <Grid item xs={12}>
                                <FieldTitle title="Email" editing required />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">{email}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                            <Grid item xs={12}>
                                <FieldTitle title="Assigned Division" editing required />
                            </Grid>
                            <Grid item xs={5}>
                                <Select
                                    id="teamId"
                                    name="teamId"
                                    variant="outlined"
                                    color="primary"
                                    value={selectedTeamId}
                                    onChange={handleTeamSelectChange}
                                    displayEmpty
                                    fullWidth
                                >
                                    {teamsAlphabetized.map((t) => (
                                        <MenuItem key={t.teamId} value={t.teamId}>
                                            {t.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" disabled={!selectedTeamId} onClick={onSubmit}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return null;
};

export default Onboarding;
