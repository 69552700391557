import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { AppBar, Box, Grid, makeStyles, Tab, Tabs, Toolbar, Typography } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Feedback from 'components/feedback/Feedback';
import { routePaths } from 'components/providers/ReactRouter';
import { a11yProps } from 'components/tabs/TabPanel';
import { clearSelectedWorkItem } from 'store/actions/SelectedItem';
import GroupImg from '../../../groups-white.svg';
import TMTLogo from '../../../tmt_white.svg';
import OrganizationSelector from '../../organizationSelector/OrganizationSelector';
import Avatar from './Avatar';

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 'auto',
        background: theme.palette.appBar.background,
        borderBottom: `1px solid ${theme.palette.appBar.background}`,
    },
    tab: {
        textTransform: 'none',
        fontSize: '16px',
        minWidth: '60px',
        height: 'auto',
        width: '150px',
        color: theme.palette.appBar.tabIndicator,
        alignItems: 'center',
    },
    tabs: {
        marginLeft: '40px',
        alignItems: 'center',
    },
    tabIndicator: {
        background: theme.palette.appBar.tabIndicator,
    },
    text: {
        color: theme.palette.appBar.tabIndicator,
    },
}));

const Header = (): any => {
    const tabValues = useMemo(
        () => [
            { name: `/${routePaths.WORK_ITEMS}`, index: 0 },
            { name: `/${routePaths.TEAMS}`, index: 1 },
        ],
        []
    );
    const [tabIndex, setTabIndex] = useState(0);
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
        // Get index of new tab
        const thisTabIndex = tabValues.find((tab) => tab.name === location.pathname)?.index;
        // Check if we need to change the tab
        if (thisTabIndex !== newValue) {
            setTabIndex(newValue);
            const thisTabName = tabValues.find((tab) => tab.index === newValue)?.name;
            if (thisTabName) {
                history.push(thisTabName);
            }
        }

        dispatch(clearSelectedWorkItem());
    };

    // Resets the app to the default tab when the user clicks the app logo
    const resetTabToDefault = () => {
        // Set the default tab. Maybe eventually this is grabbed from user preferences
        const defaultTabNumber = 0;
        setTabIndex(defaultTabNumber);
        const thisTabName = tabValues.find((tab) => tab.index === defaultTabNumber)?.name;
        if (thisTabName) {
            history.push(thisTabName);
        }
    };

    useEffect(() => {
        // Get current pathname. Set that tab to active
        const thisTabIndex = tabValues.find((tab) => tab.name === location.pathname)?.index;
        if (thisTabIndex) {
            setTabIndex(thisTabIndex);
        }
    }, [location.pathname, tabValues]);

    return (
        <header>
            <AppBar position="static" elevation={0} className={classes.appBar}>
                <Toolbar>
                    <Grid container justify="center" alignItems="center">
                        <Grid container item xs={7} direction="row" justify="flex-start">
                            <Grid container item justify="flex-start" alignItems="center" xs={7}>
                                <h1>
                                    <Link
                                        to="/"
                                        className="logo-link"
                                        onClick={resetTabToDefault}
                                        aria-label="Return to Home Page"
                                    >
                                        <Box display="flex">
                                            <img src={TMTLogo} alt="Tasker Management Tool" />
                                        </Box>
                                    </Link>
                                </h1>
                            </Grid>
                            <Grid container item justify="flex-start" alignItems="center" xs={5}>
                                <Tabs
                                    aria-label="Header Tabs"
                                    value={tabIndex}
                                    onChange={handleChange}
                                    TabIndicatorProps={{ className: classes.tabIndicator }}
                                    centered
                                    className={classes.tabs}
                                >
                                    <Tab
                                        className={classes.tab}
                                        label="Work Items"
                                        icon={<ListIcon fontSize="large" />}
                                        {...a11yProps(0, 'header', 'Work')}
                                    />
                                    <Tab
                                        className={classes.tab}
                                        label="Team Directory"
                                        icon={<img src={GroupImg} alt="Group" />}
                                        {...a11yProps(1, 'header', 'Teams')}
                                    />
                                </Tabs>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center" justify="flex-end" item xs={5}>
                            <Grid item xs="auto">
                                <Link
                                    to={`/${routePaths.ASSIGN_USER}`}
                                    className={`logo-link ${classes.text}`}
                                    onClick={resetTabToDefault}
                                >
                                    <Typography component="span" variant="subtitle2" className={classes.text}>
                                        Assign User
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs="auto">
                                <Feedback className={classes.text} />
                            </Grid>
                            <Grid item xs="auto">
                                <OrganizationSelector />
                            </Grid>
                            <Grid item xs="auto">
                                <Avatar />
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </header>
    );
};

export default Header;
