import { Button, Grid, IconButton, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { DatePicker } from '@material-ui/pickers';
import FieldTitle from 'components/formUtils/FieldTitle';
import moment from 'moment';
import { ReactElement, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setTags } from 'store/actions/Tag';
import { Store } from 'store/reducers';
import {
    FilterWorkItemInput,
    Status,
    Tag,
    Team,
    useGetTagsLazyQuery,
    useGetUsersByOrganizationQuery,
    User,
} from '../../typings/generated';
import {
    AssigneeAutoComplete,
    PriorityAutoComplete,
    StatusAutoComplete,
    TagAutoComplete,
    TeamAutoComplete,
} from '../formUtils/autoCompleteFields';
import ConfirmModal from '../modal/ConfirmModal';

interface FilterBuilderProps {
    currentFilter: FilterWorkItemInput;
    onUpdate: (filter: FilterWorkItemInput) => void;
    onClearAll: () => void;
}

const useStyles = makeStyles(() => ({
    dateRangeText: {
        textAlign: 'center',
    },
    root: {
        '& .MuiInputBase-root': {
            height: 'fit-content',
        },
        '& .MuiChip-root': {
            borderColor: '#ae4e01',
            color: '#ae4e01',
            '& .MuiSvgIcon-root': {
                color: '#ae4e01',
            },
        },
    },
}));

const FilterBuilder = ({ currentFilter, onUpdate, onClearAll }: FilterBuilderProps): ReactElement => {
    const dispatch = useDispatch();

    const classes = useStyles();
    const { assignees, urgencyScale, dueDate, workItemTags: currentTags, teamId, statusId } = currentFilter;
    const methods = useForm({
        defaultValues: {
            assignees: [],
            priorities: [],
            statuses: [],
            tags: [],
            teams: [],
            dateRangeFrom: null,
            dateRangeTo: null,
        },
    });
    const { control, getValues, reset } = methods;
    const { selectedOrganization } = useSelector((state: Store) => state.Organization);
    const { tags } = useSelector((state: Store) => state.Tag);
    const { teams } = useSelector((state: Store) => state.Team);
    const { statuses } = useSelector((state: Store) => state.Status);
    const [getTags] = useGetTagsLazyQuery({ onCompleted: (data) => dispatch(setTags(data.tags as Tag[])) });

    const { data: getUsersByOrgData } = useGetUsersByOrganizationQuery({
        variables: { organizationId: selectedOrganization?.organizationId as number },
        onCompleted: (data) => {
            reset({
                assignees: data.usersByOrganization.filter((user) =>
                    assignees?.userId?.in?.includes(user.id)
                ) as never[],
                priorities: (urgencyScale?.in as never[]) || ([] as never[]),
                statuses: statuses.filter((status) => statusId?.in?.includes(status.statusId)) as never[],
                tags: tags.filter((tag) => currentTags?.tagId?.in?.includes(tag.tagId)) as never[],
                teams: teams.filter((team) => teamId?.in?.includes(team.teamId)) as never[],
                dateRangeFrom: dueDate?.between?.startDate || dueDate?.greaterThanOrEqualTo || null,
                dateRangeTo: dueDate?.between?.endDate || dueDate?.lessThanOrEqualTo || null,
            });
        },
    });
    const [open, setOpen] = useState(false);
    const onOpen = () => {
        getTags();
        reset({
            assignees: getUsersByOrgData?.usersByOrganization.filter((user) =>
                assignees?.userId?.in?.includes(user.id)
            ) as never[],
            priorities: (urgencyScale?.in as never[]) || ([] as never[]),
            statuses: statuses.filter((status) => statusId?.in?.includes(status.statusId)) as never[],
            tags: tags.filter((tag) => currentTags?.tagId?.in?.includes(tag.tagId)) as never[],
            teams: teams.filter((team) => teamId?.in?.includes(team.teamId)) as never[],
            dateRangeFrom: dueDate?.between?.startDate || dueDate?.greaterThanOrEqualTo || null,
            dateRangeTo: dueDate?.between?.endDate || dueDate?.lessThanOrEqualTo || null,
        });
        setOpen(true);
    };

    const onCancel = () => setOpen(false);

    const onClear = () => {
        reset({
            assignees: [],
            priorities: [],
            statuses: [],
            tags: [],
            teams: [],
            dateRangeFrom: null,
            dateRangeTo: null,
        });
    };

    const onConfirm = () => {
        const teamValues = getValues('teams');
        const teamFilter: FilterWorkItemInput =
            teamValues.length > 0
                ? {
                      teamId: {
                          in: teamValues.map((team: Team) => team.teamId),
                      },
                  }
                : {};

        const priorityValues = getValues('priorities');
        const priorityFilter: FilterWorkItemInput =
            priorityValues.length > 0
                ? {
                      urgencyScale: {
                          in: priorityValues,
                      },
                  }
                : {};

        const statusValues = getValues('statuses');
        const statusFilter: FilterWorkItemInput =
            statusValues.length > 0
                ? {
                      statusId: {
                          in: statusValues.map((status: Status) => status.statusId),
                      },
                  }
                : {};

        const assigneeValues = getValues('assignees');
        const assignFilter: FilterWorkItemInput =
            assigneeValues.length > 0
                ? { assignees: { userId: { in: assigneeValues.map((user: User) => user.id) } } }
                : {};

        const tagValues = getValues('tags');
        const tagFilter: FilterWorkItemInput =
            tagValues.length > 0 ? { workItemTags: { tagId: { in: tagValues.map((tag: Tag) => tag.tagId) } } } : {};

        const dateRangeFromValues: typeof moment | null = getValues('dateRangeFrom');
        const dateRangeToValues: typeof moment | null = getValues('dateRangeTo');

        let dueDateFilter: FilterWorkItemInput['dueDate'] | null = null;
        if (!!dateRangeFromValues && !!dateRangeToValues) {
            const from = moment(dateRangeFromValues);
            const to = moment(dateRangeToValues);
            dueDateFilter = {
                between: { startDate: from.utc().startOf('day').format(), endDate: to.utc().endOf('day').format() },
            };
        } else if (dateRangeFromValues) {
            const from = moment(dateRangeFromValues);
            dueDateFilter = { greaterThanOrEqualTo: from.utc().startOf('day').format() };
        } else if (dateRangeToValues) {
            const to = moment(dateRangeToValues);
            dueDateFilter = { lessThanOrEqualTo: to.utc().endOf('day').format() };
        }

        const dateRangeFilter: FilterWorkItemInput = dueDateFilter
            ? {
                  dueDate: dueDateFilter,
              }
            : {};

        const filter: FilterWorkItemInput = {
            ...teamFilter,
            ...statusFilter,
            ...assignFilter,
            ...tagFilter,
            ...priorityFilter,
            ...dateRangeFilter,
        };
        setOpen(false);
        onUpdate(filter);
    };

    return (
        <Grid item xs={12}>
            <Button color="primary" variant="outlined" onClick={onOpen}>
                Filter View ({Object.keys(currentFilter).length})
            </Button>
            {open ? (
                <ConfirmModal
                    isOpen
                    title="Apply Filters"
                    confirmColor="primary"
                    confirmText="Update Filters"
                    showActions
                    confirmVariant="contained"
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    showClear
                    onClear={onClear}
                    clearText="Clear All"
                    maxWidth="sm"
                    content={
                        <form className={classes.root}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <FieldTitle title="Status" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label htmlFor="statuses">
                                            <Controller
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <StatusAutoComplete
                                                        id="statuses"
                                                        onChange={onChange}
                                                        value={value}
                                                        disableCreation
                                                    />
                                                )}
                                                name="statuses"
                                            />
                                        </label>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <FieldTitle title="Division" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label htmlFor="teams">
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <TeamAutoComplete
                                                            id="teams"
                                                            onChange={onChange}
                                                            value={value}
                                                            disableCreation
                                                        />
                                                    )}
                                                    name="teams"
                                                />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <FieldTitle title="Assignee" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label htmlFor="assignees">
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <AssigneeAutoComplete
                                                            id="assignees"
                                                            onChange={onChange}
                                                            value={value}
                                                            disableCreation
                                                            users={getUsersByOrgData?.usersByOrganization as User[]}
                                                        />
                                                    )}
                                                    name="assignees"
                                                />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <FieldTitle title="Priority" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label htmlFor="priorities">
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <PriorityAutoComplete
                                                            id="priorities"
                                                            onChange={onChange}
                                                            value={value}
                                                            disableCreation
                                                        />
                                                    )}
                                                    name="priorities"
                                                />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <FieldTitle title="Tag" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label htmlFor="tags">
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <TagAutoComplete
                                                            id="tags"
                                                            onChange={onChange}
                                                            value={value}
                                                            disableCreation
                                                        />
                                                    )}
                                                    name="tags"
                                                />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12}>
                                            <FieldTitle title="Deadline Date Range" />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <label htmlFor="dateRangeFrom">
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <DatePicker
                                                            id="dateRangeFrom"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoOk
                                                            inputVariant="outlined"
                                                            format="DD MMM YYYY"
                                                            InputProps={{
                                                                placeholder: 'DD MMM YYYY',
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton aria-label="Date Selector">
                                                                            <TodayIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                    name="dateRangeFrom"
                                                />
                                            </label>
                                        </Grid>

                                        <Grid item xs={1} className={classes.dateRangeText}>
                                            <Typography variant="body1">To</Typography>
                                        </Grid>

                                        <Grid item xs={5}>
                                            <label htmlFor="dateRangeTo">
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <DatePicker
                                                            id="dateRangeTo"
                                                            value={value}
                                                            onChange={onChange}
                                                            autoOk
                                                            inputVariant="outlined"
                                                            format="DD MMM YYYY"
                                                            InputProps={{
                                                                placeholder: 'DD MMM YYYY',
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton aria-label="Date Selector">
                                                                            <TodayIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                    name="dateRangeTo"
                                                />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    }
                />
            ) : null}
        </Grid>
    );
};

export default FilterBuilder;
