import { useState } from 'react';

import {
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { CalendarToday, Cancel } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import DateTimePicker from 'components/dateTimePicker/DateTimePicker';
import { FormValueTypography, renderValue } from 'components/formUtils';
import { WorkItem } from '../../../typings/generated';
import getFormattedDateString from '../../../utils/formatDateString';

interface SubtasksTableProps {
    subtasks: Partial<WorkItem>[];
    onRemoveRow: (teamId: number) => void;
    onDateChange: (newValue: MaterialUiPickersDate, teamId: number) => void;
}

const useStyles = makeStyles({
    ellipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': '1',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },
    column: {
        minWidth: 200,
    },
    table: {
        overflow: 'hidden',
    },
});

export const sortSubtasksByTeamName = (inputArray: Record<string, any>[]): Record<string, any>[] => {
    if (typeof inputArray !== 'undefined') {
        const alphabetizedInputArray = [...inputArray];
        alphabetizedInputArray.sort((a, b) => a.team.name.localeCompare(b.team.name));
        return alphabetizedInputArray;
    }
    return inputArray;
};

const SubtasksTable = ({ subtasks, onRemoveRow, onDateChange }: SubtasksTableProps): JSX.Element => {
    const [editingRow, setEditingRow] = useState<number | null>(null);
    const onCancel = () => setEditingRow(null);
    const classes = useStyles();

    const sortedSubtasks = sortSubtasksByTeamName(subtasks);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer component={Paper} elevation={0}>
                    <Table aria-label="simple table" size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Assigned Teams</TableCell>
                                <TableCell>Subtask Title</TableCell>
                                <TableCell>Deadline</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedSubtasks.map((workItem) => (
                                <TableRow key={workItem?.teamId}>
                                    <TableCell component="th" scope="workItem">
                                        <Grid
                                            container
                                            wrap="nowrap"
                                            justify="center"
                                            alignItems="center"
                                            direction="row"
                                            spacing={3}
                                        >
                                            <Grid item xs={4}>
                                                <IconButton onClick={() => onRemoveRow(workItem.teamId as number)}>
                                                    <Cancel />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {workItem?.team?.name}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell className={classes.column}>
                                        <Typography className={classes.ellipsis}>{workItem.name}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.column}>
                                        {renderValue(
                                            editingRow === workItem?.teamId,
                                            <DateTimePicker
                                                id={`teamId${workItem?.teamId}_datePicker`}
                                                testId="edit-due-date"
                                                value={workItem?.dueDate}
                                                onChange={(date: MaterialUiPickersDate) => {
                                                    onDateChange(date, workItem?.teamId as number);
                                                }}
                                                onClose={onCancel}
                                            />,
                                            <FormValueTypography
                                                deleteIcon={<CalendarToday />}
                                                value={getFormattedDateString(workItem?.dueDate)}
                                                onClick={() => setEditingRow(workItem?.teamId as number)}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default SubtasksTable;
