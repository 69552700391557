import { useEffect, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Grid, IconButton, Link, makeStyles, Typography } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { useSelector } from 'react-redux';
import { WorkItem } from 'typings';

import SectionHeader from 'components/workItemDetails/overview/SectionHeader';
import useIsATeamMember from 'hooks/useIsATeamMember';
import { Store } from 'store/reducers';
import { Attachment, CreateAttachmentMutation, Maybe, useCreateAttachmentMutation } from 'typings/generated';
import { DELETE_ATTACHMENT, GET_DOWNLOAD_URL } from '../../api/queries/attachment';
import getFormattedDateString from '../../utils/formatDateString';
import ConfirmDeletionDialog from './ConfirmDeletionDialog';
import GoogleDrivePicker, { GoogleDriveFile } from './GoogleDrivePicker';
import UploadDialog, { Props as UploadDialogProps } from './UploadDialog';

interface Props extends Omit<UploadDialogProps, 'workItem'> {
    attachments: Maybe<Attachment[]> | undefined;
    onOpen: () => void;
    refetchWorkItem?: () => void;
    onAttachmentDeleted: (attachmentId: number) => void;
    onAddGoogleFile?: (newAttachments: CreateAttachmentMutation['createAttachment'][]) => void;
    workItem?: WorkItem;
}

const useStyles = makeStyles((theme) => ({
    attachmentText: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        cursor: 'default',
    },
    link: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    primary: {
        color: theme.palette.primary.main,
    },
    iconSmall: {
        fontSize: 15,
    },
    attachmentContainer: {
        backgroundColor: darken(theme.palette.background.paper, 0.25),
        border: `1px solid ${darken(theme.palette.background.paper, 0.5)}`,
        margin: `${theme.spacing(1)}px 0`,
    },
    notDownlodable: {},

    icon: {
        float: 'right',
    },

    hasActionStyle: {
        cursor: 'pointer',
    },
    downloadDisabledStyle: {
        cursor: 'not-allowed',
        '&:hover': {
            textDecoration: 'none',
            cursor: 'not-allowed',
        },
    },
}));

export default ({
    attachments,
    workItem,
    isOpen,
    onOpen,
    onClose,
    refetchWorkItem,
    onAttachmentDeleted,
    onAddGoogleFile,
}: Props): JSX.Element => {
    const [attachmentToDelete, setAttachmentToDelete] = useState<Attachment | null>(null);
    const [getDownloadUrl, { data }] = useLazyQuery(GET_DOWNLOAD_URL, {
        fetchPolicy: 'network-only',
    });
    const [deleteAttachment, { data: successful }] = useMutation(DELETE_ATTACHMENT, {
        onCompleted: () => {
            onAttachmentDeleted(attachmentToDelete?.attachmentId as number);
            setAttachmentToDelete(null);
        },
    });
    const [createAttachment] = useCreateAttachmentMutation();

    const classes = useStyles();

    const onAddAttachment = async (files: GoogleDriveFile[]) => {
        const results = await Promise.all(
            files.map((file) =>
                createAttachment({
                    variables: {
                        data: {
                            fileName: file.name,
                            path: file.url,
                            isGoogleDriveFile: true,
                            workItemId: workItem?.workItemId as number,
                            size: file.sizeBytes,
                        },
                    },
                })
            )
        );
        const newAttachments: CreateAttachmentMutation['createAttachment'][] = [];
        results.forEach((result) => {
            if (result.data) {
                newAttachments.push(result.data.createAttachment);
            }
        });
        if (onAddGoogleFile) {
            onAddGoogleFile(newAttachments);
        }
    };

    const onGetDownloadUrl = (attachmentId: number) => {
        getDownloadUrl({
            variables: {
                attachmentId,
            },
        });
    };

    const onDelete = (attachment: Attachment) => setAttachmentToDelete(attachment);
    const onCancel = () => setAttachmentToDelete(null);

    const onConfirmDelete = async () => {
        deleteAttachment({
            variables: {
                attachmentId: attachmentToDelete?.attachmentId,
            },
        });
    };

    const currentUserId = useSelector((state: Store) => state.User.id);

    const isATeamMember = useIsATeamMember({ belongsToTeamId: workItem?.teamId });
    const isParentATeamMember = useIsATeamMember({ belongsToTeamId: workItem?.parent?.teamId });
    const isAttachmentCreatedByUser = (attachment: Attachment) => attachment.createdBy === currentUserId;
    const disableInteraction = !isATeamMember && !isParentATeamMember;

    useEffect(() => {
        if (data?.downloadAttachment) {
            const { url, fileName } = data.downloadAttachment;
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }
    }, [data]);

    useEffect(() => {
        if (successful && refetchWorkItem) {
            refetchWorkItem();
            setAttachmentToDelete(null);
        }
    }, [successful, refetchWorkItem, setAttachmentToDelete]);

    return (
        <Grid>
            {attachments && attachments?.length > 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SectionHeader title="Attachments" />
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        {attachments.map((attachment: Attachment) => {
                            const disableAttachmentInteraction =
                                !isAttachmentCreatedByUser(attachment) && disableInteraction;
                            const size = attachment.size ? `${Math.round(attachment.size / 1000)} KB` : '';
                            return (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    key={attachment.attachmentId}
                                    className={classes.attachmentContainer}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        wrap="nowrap"
                                        className={classes.attachmentText}
                                        spacing={0}
                                    >
                                        <Grid item direction="column" xs={6}>
                                            {!disableAttachmentInteraction && attachment.isGoogleDriveFile && (
                                                <Link
                                                    color="inherit"
                                                    target="_blank"
                                                    rel="noopener"
                                                    href={attachment.path}
                                                    className={classes.link}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        className={`${classes.attachmentText} ${classes.link}`}
                                                    >
                                                        {attachment.fileName}
                                                    </Typography>
                                                </Link>
                                            )}
                                            {!disableAttachmentInteraction && !attachment.isGoogleDriveFile && (
                                                <Typography
                                                    variant="body2"
                                                    onClick={(): void => onGetDownloadUrl(attachment.attachmentId)}
                                                    className={`${classes.attachmentText} ${classes.link}`}
                                                >
                                                    {attachment.fileName}
                                                </Typography>
                                            )}
                                            {disableAttachmentInteraction && (
                                                <Typography
                                                    className={`${classes.attachmentText} ${classes.downloadDisabledStyle}`}
                                                >
                                                    {attachment.fileName}
                                                </Typography>
                                            )}
                                            <Typography variant="body2">
                                                {getFormattedDateString(
                                                    new Date(attachment.modifiedDate)[Symbol.toPrimitive]('string')
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item container xs={4} justify="flex-end">
                                            <Grid item>
                                                <Typography className={classes.attachmentText} variant="body2">
                                                    {attachment.isGoogleDriveFile ? '(Google Drive)' : size}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={2} justify="flex-end">
                                            <IconButton
                                                disabled={disableAttachmentInteraction}
                                                data-testid="times-icon"
                                                size="small"
                                                onClick={(): void => onDelete(attachment)}
                                                aria-label="Delete Attachment"
                                            >
                                                <Cancel />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            ) : (
                <Grid container direction="row" spacing={2}>
                    <SectionHeader title="No Attachments" />
                </Grid>
            )}

            <Grid item container direction="column" spacing={2}>
                <Grid item>
                    <Button
                        disabled={disableInteraction}
                        variant="text"
                        color="primary"
                        startIcon={<AttachmentIcon className={`${classes.primary} ${classes.iconSmall}`} />}
                        onClick={onOpen}
                    >
                        Upload Local File
                    </Button>
                </Grid>
            </Grid>
            <UploadDialog workItem={workItem as WorkItem} isOpen={isOpen} onClose={onClose} />
            <ConfirmDeletionDialog attachment={attachmentToDelete} onCancel={onCancel} onConfirm={onConfirmDelete} />
        </Grid>
    );
};
