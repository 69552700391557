import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

import { Scalars } from 'typings/generated';
import getFormattedDateString from '../../utils/formatDateString';

export interface ClickResponse {
    url: string;
    fileName: string;
    isGoogleDriveFile: boolean;
}

export interface Props {
    isDownloadable?: boolean;
    url: string;
    fileName: string;
    onDelete: (data: ClickResponse) => void;
    onClick: (data: ClickResponse) => void;
    size?: string;
    isGoogleDriveFile?: boolean;
    lastModified: Scalars['DateTime'];
}

const useStyles = makeStyles((theme) => ({
    notDownlodable: {},

    icon: {
        float: 'right',
    },
    attachmentText: {
        fontSize: '0.875em',
        fontWeight: 'bold',
        cursor: 'default',
    },
    dateModifiedText: {
        fontSize: '0.875em',
        cursor: 'default',
    },
    hasActionStyle: {
        cursor: 'pointer',
    },
    downloadDisabledStyle: {
        cursor: 'not-allowed',
    },
}));

export default ({
    url,
    fileName,
    onClick,
    onDelete,
    isDownloadable,
    size,
    lastModified,
    isGoogleDriveFile = false,
}: Props): JSX.Element => {
    const classes = useStyles();

    const download = (): void => {
        if (isDownloadable) {
            onClick({ url, fileName, isGoogleDriveFile });
        }
    };

    return (
        <Grid key={url} container direction="row" alignItems="center">
            <Grid container item direction="column" xs={7}>
                <Typography
                    variant="body1"
                    className={
                        (classes.attachmentText,
                        !isDownloadable ? classes.downloadDisabledStyle : classes.hasActionStyle)
                    }
                    onClick={download}
                >
                    {fileName}
                </Typography>
                <Typography variant="body1" className={classes.dateModifiedText} onClick={download}>
                    {getFormattedDateString(new Date(lastModified)[Symbol.toPrimitive]('string'))}
                </Typography>
            </Grid>
            <Grid item container xs={3} justify="flex-end">
                <Grid item>
                    <Typography className={classes.attachmentText}>{size}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={2} justify="flex-end">
                <IconButton
                    data-testid="times-icon"
                    size="small"
                    onClick={(): void => onDelete({ url, fileName, isGoogleDriveFile })}
                >
                    <Cancel />
                </IconButton>
            </Grid>
        </Grid>
    );
};
