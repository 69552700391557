import { combineReducers } from 'redux';

import ItemType, { State as ItemTypeState } from './ItemType';
import Modal, { State as ModalState } from './Modal';
import MuiTheme, { ThemeState } from './MuiTheme';
import Navigation, { State as NavigationState } from './Navigation';
import Organization, { State as OrganizationState } from './Organization';
import SelectedItem, { State as SelectedItemState } from './SelectedItem';
import SelectedView, { State as SelectedViewState } from './SelectedView';
import Snackbar, { State as SnackbarState } from './Snackbar';
import Status, { State as StatusState } from './Status';
import Tag, { State as TagState } from './Tag';
import Team, { State as TeamState } from './Team';
import User from './User';
import View, { State as ViewState } from './View';
import WorkItems, { State as WorkItemsState } from './WorkItems';

export interface Store {
    Modal: ModalState;
    WorkItems: WorkItemsState;
    SelectedItem: SelectedItemState;
    Status: StatusState;
    ItemType: ItemTypeState;
    Team: TeamState;
    View: ViewState;
    SelectedView: SelectedViewState;
    Navigation: NavigationState;
    User: any;
    Snackbar: SnackbarState;
    Organization: OrganizationState;
    MuiTheme: ThemeState;
    Tag: TagState;
}

export default combineReducers({
    Modal,
    WorkItems,
    SelectedItem,
    User,
    Status,
    ItemType,
    Team,
    View,
    SelectedView,
    Navigation,
    Snackbar,
    Organization,
    MuiTheme,
    Tag,
});
