/* eslint-disable no-param-reassign */
import update from 'immutability-helper';

import { Filters } from '../../typings/filters';
import { WorkItem as Item } from '../../typings/workItem';
import {
    ADD_ITEM_TO_PLANNED,
    ADD_ITEM_TO_UNPLANNED,
    EDIT_PLANNED_ITEM,
    REMOVE_ITEM_FROM_PLANNED,
    REMOVE_ITEM_FROM_UNPLANNED,
    SET_PLANNED,
    SET_SEARCH_TERM,
    SET_UNPLANNED,
} from '../actions/WorkItems';
import { Action } from '../index';
import { handleAddItemToPlanned, handleUpdatePlannedItem, removeDuplicateSubtasks } from './WorkItemHelpers';

export interface State {
    planned: Item[];
    unplanned: Item[];
    searchTerm: string;
    filters: Filters;
}

const initialState: State = {
    planned: [],
    unplanned: [],
    searchTerm: '',
    filters: {
        requester: [],
    },
};

export interface Payload {
    id: number;
    parentWorkItemId: number;
    items: Item[];
    item: Item;
    newPriority: number;
    startDate: string;
    endDate: string;
    searchTerm: string;
    filters: Filters;
}

interface WorkItemAction extends Omit<Action, 'payload'> {
    payload: Payload;
}

export default (prevState: State, action: WorkItemAction): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;
    switch (type) {
        case SET_PLANNED: {
            return { ...state, planned: payload.items };
        }
        case SET_UNPLANNED: {
            return { ...state, unplanned: [...removeDuplicateSubtasks(payload.items)] };
        }
        /*
         We need to have SET_PLANNED called when you want to overwrite the entire 
         planned store. We need a MERGE_PLANNED action to update each existing element with the merged changes (like when subscriptions are called).
         */
        case ADD_ITEM_TO_PLANNED: {
            const unshiftedState = update(state, {
                planned: {
                    $unshift: [payload.item],
                },
            });
            const nextState = handleAddItemToPlanned(unshiftedState, payload);
            return { ...nextState };
        }
        case ADD_ITEM_TO_UNPLANNED: {
            return { ...state, unplanned: [payload.item, ...state.unplanned] };
        }

        case REMOVE_ITEM_FROM_PLANNED: {
            return { ...state, planned: state.planned.filter((item) => item.workItemId !== payload.id) };
        }
        case REMOVE_ITEM_FROM_UNPLANNED: {
            return { ...state, unplanned: state.unplanned.filter((item) => item.workItemId !== payload.id) };
        }

        case EDIT_PLANNED_ITEM: {
            const nextState = handleUpdatePlannedItem(state, payload);

            return { ...nextState };
        }
        case SET_SEARCH_TERM: {
            return { ...state, searchTerm: payload.searchTerm };
        }
        default:
            return { ...state };
    }
};
/* eslint-enable no-param-reassign */
