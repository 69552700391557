import { ReactElement } from 'react';

import { Checkbox, Chip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { User } from '../../../typings/generated';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface StatusAutoCompleteProps {
    disableCreation?: boolean;
    onChange: (newValue: Array<string | Partial<User>>) => void;
    value: User[];
    id: string;
    users: User[];
}
const StatusAutoComplete = ({
    disableCreation,
    onChange,
    id,
    users,
    value: statusValue,
}: StatusAutoCompleteProps): ReactElement => {
    return (
        <Autocomplete
            multiple
            id={id}
            value={statusValue}
            onChange={(_event, newValue) => onChange(newValue)}
            options={users || []}
            freeSolo={!disableCreation}
            getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
            renderOption={(option, { selected }) => (
                <>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        color="primary"
                        checked={selected}
                    />
                    {`${option.firstName} ${option.lastName}`}
                </>
            )}
            renderTags={(value: User[], getStatusProps) =>
                value.map((option: User, index: number) => (
                    <Chip
                        variant="outlined"
                        label={`${option.firstName} ${option.lastName}`}
                        size="small"
                        {...getStatusProps({ index })}
                        icon={<AccountCircleIcon />}
                    />
                ))
            }
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Enter an assignee..." />}
        />
    );
};

export default StatusAutoComplete;
