import { Team } from '../../typings/generated';
import { EXPAND_ALL_WORK_ITEMS, SET_SELECTED_TEAM, SET_TEAMS } from '../actions/Team';
/* eslint-disable no-param-reassign */
import { Action } from '../index';

export interface State {
    teams: Team[];
    expandAllWorkItems: boolean;
    selectedTeam: Team | null;
}

export const expandAllLocalStorageKey = 'prioritize-expand-all';
const storage = localStorage.getItem(expandAllLocalStorageKey);
const localStorageExpandAll = storage && JSON.parse(storage);

const initialState: State = {
    teams: [],
    expandAllWorkItems: localStorageExpandAll || false,
    selectedTeam: null,
};

interface Payload {
    teams: Team[];
    expandAllWorkItems: boolean;
    team: Team | null;
}

interface TeamAction extends Omit<Action, 'payload'> {
    payload: Payload;
}

export default (prevState: State, action: TeamAction): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;
    switch (type) {
        case SET_TEAMS: {
            return { ...state, teams: payload.teams };
        }
        case SET_SELECTED_TEAM: {
            return { ...state, selectedTeam: payload.team };
        }
        case EXPAND_ALL_WORK_ITEMS: {
            return { ...state, expandAllWorkItems: payload.expandAllWorkItems };
        }
        default:
            return { ...state };
    }
};
/* eslint-enable no-param-reassign */
