import { ReactElement } from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import useIsATeamMember from 'hooks/useIsATeamMember';
import { FormValueTypography } from '../../formUtils';
import { AssigneeSelectSubComponentProps, AssigneeStyleProps } from '../selectInterfaces';

const useStyles = makeStyles((theme: Theme) => ({
    text: (props: AssigneeStyleProps) => ({
        maxWidth: props?.small ? 150 : undefined,
    }),
}));

const AssigneeSelectView = ({
    workItem,
    setShowForm,
    currentAssigneeName,
    currentAssigneeAvatar,
    isSubtask = false,
    disabled = false,
    small = false,
    hideDownArrow = false,
}: AssigneeSelectSubComponentProps): ReactElement | null => {
    const classes = useStyles({ small });

    const onClick = () => setShowForm(true);

    let disableForm = disabled;
    const userIsATeamMember = useIsATeamMember({ belongsToTeamId: workItem?.team?.teamId });
    if (!userIsATeamMember) {
        disableForm = true;
    }

    return (
        <FormValueTypography
            deleteIcon={hideDownArrow ? undefined : <KeyboardArrowDown className={classes.text} />}
            disabled={disableForm}
            className={classes.text}
            value={currentAssigneeName(workItem?.assignees?.[0]?.userId || '')}
            variant="assigneeChip"
            assigneeAvatar={currentAssigneeAvatar(workItem?.assignees?.[0]?.userId || '')}
            onClick={onClick}
        />
    );
};

export default AssigneeSelectView;
