import { ReactElement } from 'react';

import { Avatar, CircularProgress, Theme, Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export interface CircleProps {
    border?: string;
    background?: string;
    loading?: boolean;
    color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error' | string;
}

export interface Props extends CircleProps {
    count?: number;
}

export const CIRCLE_COUNT_DIAMETER = 4;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circle: (props: CircleProps) => ({
            // `color` property should to be dark for all themes unless overwritten
            color: props?.color ? props.color : theme.palette.common.black,
            backgroundColor: props?.background ? props.background : theme.palette.secondary.main,
            border: props.border ? `1px solid ${props.border}` : '',
            width: theme.spacing(CIRCLE_COUNT_DIAMETER),
            height: theme.spacing(CIRCLE_COUNT_DIAMETER),
            alignContent: 'center',
            justifyContent: 'center',
        }),
        progress: {
            color: theme.palette.common.black,
        },
    })
);

export default function CircleCount({
    color,
    background = '',
    border = '',
    count = 0,
    loading = false,
}: Props): ReactElement {
    const theme = useTheme();
    const textColor = color || theme.palette.common.black;
    const borderColor = border || 'none';
    const classes = useStyles({ color: textColor, background, border: borderColor });

    return (
        <Avatar alt={`${count}`} className={classes.circle}>
            {loading ? (
                <CircularProgress size={theme.spacing(2)} className={classes.progress} />
            ) : (
                <Typography>{count}</Typography>
            )}
        </Avatar>
    );
}
