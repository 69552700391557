import { Action } from 'store';

import { View } from 'typings/generated';

export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const CLEAR_SELECTED_VIEW = 'CLEAR_SELECTED_VIEW';

export const setSelectedView = (selectedView: View): Action => ({
    type: SET_SELECTED_VIEW,
    payload: { selectedView },
});

export const clearSelectedView = (): Action => ({
    type: SET_SELECTED_VIEW,
    payload: {},
});
