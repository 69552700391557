import { Button, DialogActions } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';

import { FormItem } from '../formItem';

interface Props {
    onCancel: () => void;
    disableButton: boolean;
    onSave: (formItem: FormItem) => void;
    getValues: UseFormMethods['getValues'];
}

export default ({ onCancel, disableButton, onSave, getValues }: Props): JSX.Element => {
    const onCreateSubtaks = () => {
        window.history.replaceState(null, '', '?openSubtaskDialog=true');
        onSave(getValues() as FormItem);
    };

    return (
        <DialogActions>
            <Button variant="text" color="primary" onClick={onCancel}>
                Cancel
            </Button>
            <Button
                variant="outlined"
                data-testid="work-submit-button"
                color="primary"
                onClick={onCreateSubtaks}
                disabled={disableButton}
            >
                Add Work Item and Create Subtasks
            </Button>
            <Button
                variant="contained"
                data-testid="work-submit-button"
                color="primary"
                type="submit"
                disabled={disableButton}
            >
                Add Work Item
            </Button>
        </DialogActions>
    );
};
