import { Dispatch, useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogTitle, Grid, makeStyles, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { EditorState } from 'draft-js';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ForeignKeys, ItemType, WorkItem } from 'typings';

import { CREATE_WORK_ITEM } from 'api/mutations/workItem';
import { ClickResponse } from 'components/attachments/AddedFile';
import { LocalFile } from 'components/attachments/DropZone';
import { routePaths } from 'components/providers/ReactRouter';
import { openSnackbar } from 'store/actions/Snackbar';
import { addTags, setTags } from 'store/actions/Tag';
import { addItemToPlanned } from 'store/actions/WorkItems';
import { Store } from 'store/reducers';
import { Tag, Team, useCreateTagsMutation, useGetTagsLazyQuery, UserTeam } from 'typings/generated';
import { convertEditorStateToString, RICH_TEXT_EDITOR_FIELDS } from '../formUtils/RichTextEditor';
import { Actions, Content } from './dialogComponents';
import { FormItem } from './formItem';
import getStatusId from './getStatusId';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        backgroundColor: theme.palette.background.paper,
        overflow: 'hidden',
    },
    backContainer: {
        background: theme.palette.appBar.background,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '2em',
        maxHeight: '56px',
        color: theme.palette.common.white,
    },
    workTitle: {
        marginLeft: '1em',
    },
    backButton: {
        color: theme.palette.common.white,
    },
    form: {
        backgroundColor: theme.palette.background.default,
        alignSelf: 'center',
        width: 690,
        overflowY: 'scroll',
        padding: '2em',
    },
    attachmentTitle: {
        fontWeight: 'bold',
    },
    addedItemContainer: {
        marginTop: '24px',
    },
}));

type ReduceForm = {
    [x: string]: string;
};

interface OnCompleted {
    dispatch: Dispatch<any>;
    workItem: WorkItem;
}

const handleOnCompleted = ({ dispatch, workItem }: OnCompleted) => {
    if (workItem.status.name === 'Completed') {
        dispatch(openSnackbar({ message: 'The work item was created!' }));
        return;
    }

    dispatch(addItemToPlanned(workItem));
};

const getTeamIdBasedOnFormData = (
    requested: boolean,
    teams: Team[],
    formData: FormItem,
    selectedTeam: Team | null
): number | undefined => {
    return requested
        ? teams.find(({ name }) => name === formData.requestFor || name === formData.assignedTeam)?.teamId
        : selectedTeam?.teamId;
};

const getItemTypeId = (isTasker: boolean, itemTypes: ItemType[]): number | undefined => {
    return isTasker
        ? itemTypes.find((element) => element.code === 'tasker')?.itemTypeId
        : itemTypes.find((element) => element.code === 'default')?.itemTypeId;
};

export default (): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { itemTypes } = useSelector((state: Store) => state.ItemType);
    const { statuses } = useSelector((state: Store) => state.Status);
    const { teams, selectedTeam } = useSelector((state: Store) => state.Team);
    const history = useHistory();
    const user = useSelector((state: Store) => state.User);
    const notStarted = statuses.find((status) => status.name === 'Not Started');
    const paperRef = useRef<null | HTMLElement>(null);
    const dispatch = useDispatch();
    const defaultValues = {
        name: '',
        startDate: null,
        dueDate: null,
        requester: '',
        requestFor: '',
        effort: '',
        priority: '',
        description: '',
        statusId: notStarted?.statusId,
        tags: [],
    };
    const methods = useForm({
        defaultValues: (defaultValues as unknown) as Partial<FormItem>,
    });
    const { handleSubmit, formState, reset, getValues, setError } = methods;
    const [createWorkItem] = useMutation(CREATE_WORK_ITEM, {
        onCompleted: async (response): Promise<void> => {
            handleOnCompleted({
                workItem: response.createWorkItem as WorkItem,
                dispatch,
            });
            setIsOpen(false);

            const shouldRedirect = !!new URLSearchParams(window.location.search).get('openSubtaskDialog');

            if (shouldRedirect) {
                history.push(`/${routePaths.WORK_ITEM}/${response.createWorkItem.workItemId}?openSubtaskDialog=true`);
            }
        },
        onError(error) {
            dispatch(
                openSnackbar({ message: `Please resolve any form field errors and click "Add Work Item" button again` })
            );
        },
    });

    const [createTags] = useCreateTagsMutation({ onCompleted: (data) => dispatch(addTags(data.createTags as Tag[])) });
    const [getTags] = useGetTagsLazyQuery({
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => dispatch(setTags(data.tags as Tag[])),
    });

    const [files, setFiles] = useState<Array<LocalFile>>([]);
    const [sizes, setSizes] = useState([]);

    const [disableButton, setDisableButton] = useState<boolean>(true);
    const classes = useStyles();

    useEffect(() => {
        files.forEach((file) => URL.revokeObjectURL(file.url));
    }, [files]);

    const onDrop = (newFiles: File[]) => {
        setFiles((prevState) => [
            ...prevState,
            ...newFiles.map((file) => Object.assign(file, { url: URL.createObjectURL(file) })),
        ]);

        newFiles.forEach((newFile) => {
            const sizeArrayCopy: any = [...sizes];
            sizeArrayCopy.push(newFile.size);
            setSizes(sizeArrayCopy);
        });
    };

    const onSave = async (formData: FormItem): Promise<void> => {
        if (!formData.name) {
            setError('name', { type: 'manual' });
            return;
        }
        setDisableButton(true);

        const itemTypeId = getItemTypeId(false, itemTypes);
        // Set parentworkitemid to null because we are not supporting that yet
        const parentWorkItemId = null;

        // TODO fix this hardcoded requested flag
        const statusId = getStatusId(statuses, false, formData);

        const workItemFields = Object.keys(formData)
            .filter((key) => key.includes('workItemPartyId') === false && key.includes('teamId') === false)
            .reduce((obj: ReduceForm, key) => {
                return { ...obj, [key]: formData[key] };
            }, {});

        const foreignKeys: Partial<ForeignKeys> = {
            statusId,
            itemTypeId,
            parentWorkItemId,
        };
        const requestBody: { [x: string]: string | number } = {};

        const newTags = formData.tags.filter((tag) => typeof tag === 'string');
        const existingTags = formData.tags.filter((tag) => typeof tag === 'object' && tag?.tagId) as Tag[];

        const createdTags = await createTags({ variables: { data: newTags as string[] } });
        const tags = [
            ...existingTags.map((tag) => tag.tagId),
            ...(createdTags?.data?.createTags.map((tag) => tag.tagId) || []),
        ];

        Object.keys(workItemFields)
            .filter((field) => Object.keys(foreignKeys).includes(field) === false && field !== 'tags')
            .forEach((field) => {
                if (RICH_TEXT_EDITOR_FIELDS.includes(field as keyof WorkItem)) {
                    // Get the description from the wysiwyg
                    requestBody[field] = convertEditorStateToString((formData[field] as unknown) as EditorState);
                } else {
                    requestBody[field] = formData[field as keyof FormItem];
                }
            });

        const assignedTeam = teams.find(({ teamId }) => teamId === +formData.teamId);

        createWorkItem({
            variables: {
                data: {
                    ...requestBody,
                    isRequested:
                        assignedTeam &&
                        !user?.userTeams?.some((userTeam: UserTeam) => userTeam.teamId === assignedTeam?.teamId),
                    priority: 0,
                    urgencyScale: formData?.priority,
                },
                foreignKeys: {
                    ...foreignKeys,
                    teamId: assignedTeam ? assignedTeam.teamId : (selectedTeam?.teamId as number),
                    tags: tags.length > 0 ? tags : undefined,
                },
                files,
                sizes,
            },
        });
    };

    const onClick = () => {
        reset();
        getTags();
        setIsOpen(true);
        window.history.replaceState(null, '', '?');
    };

    const onCancel = () => {
        reset();
        setIsOpen(false);
        window.history.replaceState(null, '', '?');
    };
    const onOpenLocalFile = ({ url }: ClickResponse) => {
        window.open(url);
    };

    const onDelete = ({ url }: ClickResponse) => {
        setFiles((prevState) => prevState.filter((f) => f.url !== url));
    };

    const onError = () => null;

    const { isDirty } = formState;

    useEffect(() => {
        if (isDirty) {
            setDisableButton(false);
        }
    }, [isDirty]);

    return (
        <>
            <Button color="primary" variant="contained" onClick={onClick}>
                Add Work Item
            </Button>
            {isOpen && (
                <Dialog
                    open
                    fullScreen
                    fullWidth
                    className={classes.container}
                    PaperProps={{
                        className: classes.container,
                        ref: paperRef,
                    }}
                    disableEnforceFocus
                    disableAutoFocus
                    disableRestoreFocus
                    style={{ zIndex: 500 }}
                >
                    <FormProvider {...methods}>
                        <Grid item xs className={classes.backContainer}>
                            <Button
                                startIcon={<ArrowBackIosIcon fontSize="small" />}
                                className={classes.backButton}
                                onClick={onCancel}
                            >
                                <Typography>Back</Typography>
                            </Button>
                        </Grid>
                        <Grid item xs className={classes.form}>
                            <form onSubmit={handleSubmit(onSave as SubmitHandler<FormItem>, onError)}>
                                <DialogTitle>Add Work Item</DialogTitle>
                                <Content
                                    onDropFiles={onDrop}
                                    onOpenLocalFile={onOpenLocalFile}
                                    onDelete={onDelete}
                                    files={files}
                                />
                                <Actions
                                    onSave={onSave}
                                    onCancel={onCancel}
                                    disableButton={disableButton}
                                    getValues={getValues}
                                />
                            </form>
                        </Grid>
                    </FormProvider>
                </Dialog>
            )}
        </>
    );
};
