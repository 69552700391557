import { EditorState } from 'draft-js';
import { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addTags } from 'store/actions/Tag';
import { convertEditorStateToString, RICH_TEXT_EDITOR_FIELDS } from '../components/formUtils/RichTextEditor';
import { WorkItem } from '../typings';
import {
    Tag,
    UpdateWorkItemForeignKeys,
    UpdateWorkItemMutation,
    useCreateTagsMutation,
    useUpdateWorkItemMutation,
    WorkItem as GeneratedWorkItemType,
} from '../typings/generated';
import generateUpdateWorkItemVariables from '../utils/generateUpdateWorkItemVariables';

export type UpdateWorkItemHandler = (updatedWorkItem: UpdateWorkItemMutation['updateWorkItem']) => void;

type EditingField = keyof GeneratedWorkItemType | null;
interface Props {
    workItem: Partial<GeneratedWorkItemType>;
    reset?: UseFormMethods['reset'];
    getValues: UseFormMethods['getValues'];
    onUpdateWorkItem: UpdateWorkItemHandler;
    anotherFieldIsBeingEdited?: boolean;
    enableEditingOnOtherFields?: () => void;
    disableEditingOnOtherFields?: () => void;
}

type UseHandleWorkItemChanges = {
    editingField: EditingField;
    setEditingField: (field: EditingField) => void;
    onSubmit: (submissionData: Partial<GeneratedWorkItemType>) => void;
    onCancel: () => void;
    onError: (errorData: UseFormMethods['errors']) => void;
    handleSelectOnChange: () => void;
    handleUpdateTags: () => Promise<void>;
};

export default function useHandleWorkItemChanges({
    workItem,
    getValues,
    onUpdateWorkItem,
    reset = () => null,
    anotherFieldIsBeingEdited = false,
    enableEditingOnOtherFields = () => null,
    disableEditingOnOtherFields = () => null,
}: Props): UseHandleWorkItemChanges {
    const dispatch = useDispatch();
    const [editingField, setEditingField] = useState<EditingField>(null);
    const [createTags] = useCreateTagsMutation({
        onCompleted: (data) => dispatch(addTags(data.createTags as Tag[])),
    });

    const [executeUpdateWorkItem] = useUpdateWorkItemMutation({
        onCompleted: ({ updateWorkItem }) => {
            onUpdateWorkItem(updateWorkItem);
            reset(updateWorkItem);
            enableEditingOnOtherFields();

            if (editingField) {
                setEditingField(null);
            }
        },
    });

    const onSubmit = (submissionData: Partial<GeneratedWorkItemType>) => {
        if (editingField && submissionData[editingField] !== workItem[editingField]) {
            executeUpdateWorkItem({
                variables: generateUpdateWorkItemVariables(workItem as WorkItem, {
                    [editingField]: RICH_TEXT_EDITOR_FIELDS.includes(editingField)
                        ? convertEditorStateToString((submissionData[editingField] as unknown) as EditorState)
                        : submissionData[editingField],
                }),
            });
        }
    };

    const onError = (errorData: UseFormMethods['errors']) => {
        if (editingField && !errorData[editingField]) {
            onSubmit(getValues());
        }
    };

    const onCancel = () => {
        if (editingField) {
            reset({ [editingField]: workItem[editingField] });
            setEditingField(null);
            enableEditingOnOtherFields();
        }
    };

    const onSetEditingField = (field: EditingField) => {
        if (field === null) {
            setEditingField(field);
            enableEditingOnOtherFields();
        } else if (anotherFieldIsBeingEdited === false) {
            setEditingField(field);
            disableEditingOnOtherFields();
        }
    };

    const handleSelectOnChange = () => onSubmit(getValues());

    const handleUpdateTags = async () => {
        if (editingField && editingField === 'workItemTags') {
            const currentTags =
                workItem?.workItemTags?.map((workItemTag) => workItemTag.tag).map((tag: Tag) => tag.tagId) || [];
            const tags = getValues('tags');
            const newTags = tags.filter((tag: Tag) => typeof tag === 'string');
            const formDataTagIds: number[] = tags
                .filter((tag: Tag) => typeof tag === 'object' && tag?.tagId)
                .map((tag: Tag) => tag.tagId);

            if (
                newTags.length > 0 ||
                currentTags.length !== tags.length ||
                !currentTags.every((tagId) => formDataTagIds.includes(tagId))
            ) {
                const createdTags = await createTags({ variables: { data: newTags as string[] } });
                const tagsToRemove: number[] = currentTags.filter((tagId) => !formDataTagIds.includes(tagId));
                const tagsToAdd: number[] = formDataTagIds.filter((tagId) => !currentTags.includes(tagId));

                const workItemTags: UpdateWorkItemForeignKeys = {
                    tags: {
                        add: [...tagsToAdd, ...(createdTags.data?.createTags.map((tag) => tag.tagId) || [])],
                        remove: [...tagsToRemove],
                    },
                };

                executeUpdateWorkItem({
                    variables: {
                        workItemId: workItem.workItemId as number,
                        data: {},
                        foreignKeys: workItemTags,
                    },
                });
            } else {
                setEditingField(null);
                enableEditingOnOtherFields();
            }
        }
    };

    return {
        editingField,
        setEditingField: onSetEditingField,
        onSubmit,
        onCancel,
        onError,
        handleSelectOnChange,
        handleUpdateTags,
    };
}
