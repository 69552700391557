import { gql } from '@apollo/client';

export const GET_TEAMS = gql`
    query GetTeams($organizationId: Float!, $includeRemoved: Boolean) {
        teams(includeRemoved: $includeRemoved, organizationId: $organizationId) {
            teamId
            name
            isRemoved
            organizationId
            organization {
                name
            }
        }
    }
`;

export default { GET_TEAMS };
