import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Attachment = {
  __typename?: 'Attachment';
  attachmentId: Scalars['Int'];
  workItem?: Maybe<WorkItem>;
  workItemId: Scalars['Int'];
  fileName: Scalars['String'];
  isGoogleDriveFile: Scalars['Boolean'];
  path: Scalars['String'];
  size?: Maybe<Scalars['Float']>;
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate: Scalars['DateTime'];
  modifiedBy: Scalars['ID'];
};

export type BetweenFilter = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type Capability = {
  __typename?: 'Capability';
  capabilityId: Scalars['Int'];
  function: Scalars['String'];
  name: Scalars['String'];
  roleCapabilities?: Maybe<Array<RoleCapability>>;
};

export type CreateAttachmentInput = {
  workItemId: Scalars['Float'];
  fileName: Scalars['String'];
  isGoogleDriveFile: Scalars['Boolean'];
  path: Scalars['String'];
  size: Scalars['Float'];
};

export type CreateCapabilityInput = {
  function: Scalars['String'];
  name: Scalars['String'];
};

export type CreateFeedbackInput = {
  notes: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
};

export type CreateOfficeInput = {
  name: Scalars['String'];
  code: Scalars['String'];
};

export type CreatePartyInput = {
  name: Scalars['String'];
  workPhone?: Maybe<Scalars['String']>;
  workPhoneExtension?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  preferredMethod?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export type CreatePartyTypeInput = {
  name: Scalars['String'];
  code: Scalars['String'];
};

export type CreateTeamInput = {
  name: Scalars['String'];
  dept: Scalars['String'];
  code: Scalars['String'];
  organizationId: Scalars['Float'];
};

export type CreateUserInput = {
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type CreateViewInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  filter: FilterWorkItemInput;
  isOpen?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  viewTypeId: Scalars['Float'];
  groupBy?: Maybe<Scalars['String']>;
};

export type CreateWorkItemForeignKeys = {
  itemTypeId: Scalars['Float'];
  parentWorkItemId?: Maybe<Scalars['Float']>;
  statusId: Scalars['Float'];
  teamId: Scalars['Float'];
  workItemPartyForeignKeys?: Maybe<Array<WorkItemPartyForeignKeys>>;
  officeId?: Maybe<Scalars['Float']>;
  assignees?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Scalars['Int']>>;
};

export type CreateWorkItemInput = {
  name: Scalars['String'];
  priority?: Maybe<Scalars['Float']>;
  urgencyScale?: Maybe<Scalars['String']>;
  effort?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  isUrgent?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  requester?: Maybe<Scalars['String']>;
  specialInstruction?: Maybe<Scalars['String']>;
  requestOriginator?: Maybe<Scalars['String']>;
  receivedDate?: Maybe<Scalars['DateTime']>;
  isRequested?: Maybe<Scalars['Boolean']>;
  externalReferenceNumber?: Maybe<Scalars['String']>;
  assignedTeam?: Maybe<Scalars['String']>;
  assignedLead?: Maybe<Scalars['String']>;
  assignedApprover?: Maybe<Scalars['String']>;
};

export type CreateWorkItemTagInput = {
  workItemId: Scalars['Float'];
  tagId: Scalars['Float'];
};

export type CreateWorkItemUserInput = {
  workItemId: Scalars['Float'];
  userId: Scalars['String'];
};

export type DateFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  notEquals?: Maybe<Scalars['DateTime']>;
  greaterThan?: Maybe<Scalars['DateTime']>;
  greaterThanOrEqualTo?: Maybe<Scalars['DateTime']>;
  lessThan?: Maybe<Scalars['DateTime']>;
  lessThanOrEqualTo?: Maybe<Scalars['DateTime']>;
  between?: Maybe<BetweenFilter>;
};


export type DownloadResponse = {
  __typename?: 'DownloadResponse';
  url: Scalars['String'];
  fileName: Scalars['String'];
};

export type DuplicateWorkItemInput = {
  workItemId: Scalars['Float'];
};

export type Feedback = {
  __typename?: 'Feedback';
  feedbackId: Scalars['Int'];
  notes: Scalars['String'];
  page?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
};

export type Filter = {
  __typename?: 'Filter';
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type FilterStatusInput = {
  statusId?: Maybe<NumberFilter>;
  name?: Maybe<StringFilter>;
  icon?: Maybe<StringFilter>;
};

export type FilterTeamInput = {
  name?: Maybe<StringFilter>;
  dept?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  teamId?: Maybe<NumberFilter>;
};

export type FilterWorkItemHistoryInput = {
  workItemId?: Maybe<NumberFilter>;
  field?: Maybe<StringFilter>;
  oldValue?: Maybe<StringFilter>;
  newValue?: Maybe<StringFilter>;
  createdDate?: Maybe<DateFilter>;
};

export type FilterWorkItemInput = {
  workItemId?: Maybe<NumberFilter>;
  priority?: Maybe<NumberFilter>;
  urgencyScale?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  dueDate?: Maybe<DateFilter>;
  teamId?: Maybe<NumberFilter>;
  team?: Maybe<FilterTeamInput>;
  status?: Maybe<FilterStatusInput>;
  statusId?: Maybe<NumberFilter>;
  requester?: Maybe<StringFilter>;
  history?: Maybe<FilterWorkItemHistoryInput>;
  createdDate?: Maybe<DateFilter>;
  or?: Maybe<FilterWorkItemInput>;
  assignees?: Maybe<FilterWorkItemUserInput>;
  workItemTags?: Maybe<FilterWorkItemTagInput>;
};

export type FilterWorkItemTagInput = {
  workItemTagId?: Maybe<NumberFilter>;
  workItemId?: Maybe<NumberFilter>;
  tagId?: Maybe<NumberFilter>;
};

export type FilterWorkItemUserInput = {
  workItemUserId?: Maybe<NumberFilter>;
  workItemId?: Maybe<NumberFilter>;
  userId?: Maybe<StringFilter>;
};

export type ItemType = {
  __typename?: 'ItemType';
  itemTypeId: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteAttachment: Scalars['Boolean'];
  createAttachment: Attachment;
  createCapability: Capability;
  createFeedback: Feedback;
  createOffice: Office;
  updateOffice: Office;
  createOrganization: Organization;
  updateOrganization: Organization;
  removeOrganization: Organization;
  addRoleToOrganization: OrganizationRole;
  removeRoleFromOrganization: OrganizationRole;
  createParty: Party;
  updateParty: Party;
  createPartyType: PartyType;
  updatePartyType: PartyType;
  createTeam: Team;
  updateTeam: Team;
  deleteTeam: Team;
  updateUser: User;
  updateUserById: User;
  addUserToTeam: UserTeam;
  removeUserFromTeam: Scalars['Boolean'];
  createView: View;
  updateView: View;
  deleteWorkItemParty: WorkItemParties;
  createWorkItem: WorkItem;
  duplicateWorkItem: WorkItem;
  updateWorkItem: WorkItem;
  deleteWorkItem: WorkItem;
  createWorkItemUser: WorkItemUser;
  deleteWorkItemUser: Scalars['Boolean'];
  createTags: Array<Tag>;
};


export type MutationDeleteAttachmentArgs = {
  attachmentId: Scalars['Float'];
};


export type MutationCreateAttachmentArgs = {
  data: CreateAttachmentInput;
};


export type MutationCreateCapabilityArgs = {
  data: CreateCapabilityInput;
};


export type MutationCreateFeedbackArgs = {
  data: CreateFeedbackInput;
};


export type MutationCreateOfficeArgs = {
  data: CreateOfficeInput;
};


export type MutationUpdateOfficeArgs = {
  data: UpdateOfficeInput;
  officeId: Scalars['Float'];
};


export type MutationCreateOrganizationArgs = {
  data: OrganizationInputs;
};


export type MutationUpdateOrganizationArgs = {
  data: OrganizationInputs;
  organizationId: Scalars['Float'];
};


export type MutationRemoveOrganizationArgs = {
  organizationId: Scalars['Float'];
};


export type MutationAddRoleToOrganizationArgs = {
  roleId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type MutationRemoveRoleFromOrganizationArgs = {
  roleId: Scalars['Float'];
  organizationId: Scalars['Float'];
};


export type MutationCreatePartyArgs = {
  data: CreatePartyInput;
};


export type MutationUpdatePartyArgs = {
  data: UpdatePartyInput;
  partyId: Scalars['Float'];
};


export type MutationCreatePartyTypeArgs = {
  data: CreatePartyTypeInput;
};


export type MutationUpdatePartyTypeArgs = {
  data: UpdatePartyTypeInput;
  partyTypeId: Scalars['Float'];
};


export type MutationCreateTeamArgs = {
  data: CreateTeamInput;
};


export type MutationUpdateTeamArgs = {
  data: UpdateTeamInput;
  teamId: Scalars['Float'];
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['Float'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  email: Scalars['String'];
};


export type MutationUpdateUserByIdArgs = {
  data: UpdateUserByIdInput;
  id: Scalars['String'];
};


export type MutationAddUserToTeamArgs = {
  teamId: Scalars['Float'];
  email: Scalars['String'];
};


export type MutationRemoveUserFromTeamArgs = {
  teamId: Scalars['Float'];
  userId: Scalars['String'];
};


export type MutationCreateViewArgs = {
  data: CreateViewInput;
};


export type MutationUpdateViewArgs = {
  data: UpdateViewInput;
  viewId: Scalars['Float'];
};


export type MutationDeleteWorkItemPartyArgs = {
  workItemPartyId: Scalars['Float'];
};


export type MutationCreateWorkItemArgs = {
  sizes?: Maybe<Array<Scalars['Float']>>;
  files?: Maybe<Array<Scalars['Upload']>>;
  data: CreateWorkItemInput;
  foreignKeys: CreateWorkItemForeignKeys;
};


export type MutationDuplicateWorkItemArgs = {
  data: DuplicateWorkItemInput;
};


export type MutationUpdateWorkItemArgs = {
  files?: Maybe<Array<Scalars['Upload']>>;
  foreignKeys?: Maybe<UpdateWorkItemForeignKeys>;
  sizes?: Maybe<Array<Scalars['Float']>>;
  data: UpdateWorkItemInput;
  workItemId: Scalars['Float'];
};


export type MutationDeleteWorkItemArgs = {
  workItemId: Scalars['Float'];
};


export type MutationCreateWorkItemUserArgs = {
  data: CreateWorkItemUserInput;
};


export type MutationDeleteWorkItemUserArgs = {
  data: CreateWorkItemUserInput;
};


export type MutationCreateTagsArgs = {
  data: Array<Scalars['String']>;
};

export type NumberFilter = {
  equals?: Maybe<Scalars['Int']>;
  notEquals?: Maybe<Scalars['Int']>;
  greaterThan?: Maybe<Scalars['Int']>;
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
  lessThan?: Maybe<Scalars['Int']>;
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type Office = {
  __typename?: 'Office';
  officeId: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  organizationId: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Team>>;
  createdDate?: Maybe<Scalars['DateTime']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  createdBy: Scalars['ID'];
  modifiedBy?: Maybe<Scalars['ID']>;
};

export type OrganizationInputs = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type OrganizationRole = {
  __typename?: 'OrganizationRole';
  organizationRoleId: Scalars['Int'];
  organizationId: Scalars['Int'];
  organization: Organization;
  roleId: Scalars['Int'];
  role: Role;
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type Party = {
  __typename?: 'Party';
  partyId: Scalars['Int'];
  name: Scalars['String'];
  workPhone?: Maybe<Scalars['String']>;
  workPhoneExtension?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  preferredMethod?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate: Scalars['DateTime'];
  modifiedBy: Scalars['ID'];
  isRemoved: Scalars['Boolean'];
};

export type PartyType = {
  __typename?: 'PartyType';
  partyTypeId: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  downloadAttachment: DownloadResponse;
  attachments: Array<Attachment>;
  capability: Capability;
  capabilities: Array<Capability>;
  itemType: ItemType;
  itemTypes: Array<ItemType>;
  office: Office;
  offices: Array<Office>;
  organization: Organization;
  organizations: Array<Organization>;
  party: Party;
  parties: Array<Party>;
  partyType: PartyType;
  partyTypes: Array<PartyType>;
  roleCapability: RoleCapability;
  roleCapabilities: Array<RoleCapability>;
  role: Role;
  roles: Array<Role>;
  status: Status;
  statuses: Array<Status>;
  team: Team;
  teams: Array<Team>;
  testGetUser: User;
  user: User;
  usersByTeam: Array<User>;
  usersByOrganization: Array<User>;
  userByEmail: User;
  userRole: UserRole;
  userRoles: Array<UserRole>;
  view: View;
  views: Array<View>;
  workItemParty: WorkItemParties;
  workItemParties: Array<WorkItemParties>;
  workItem: WorkItem;
  workItemAndChildren: WorkItemAndChildren;
  workItems: Array<WorkItem>;
  workItemsCount: Scalars['Int'];
  workItemDescendantCount: Scalars['Int'];
  workItemsByTeam: Array<WorkItem>;
  tag: Tag;
  tags: Array<Tag>;
};


export type QueryDownloadAttachmentArgs = {
  attachmentId: Scalars['Float'];
};


export type QueryAttachmentsArgs = {
  workItemId: Scalars['Float'];
};


export type QueryCapabilityArgs = {
  capabilityId: Scalars['Float'];
};


export type QueryItemTypeArgs = {
  itemTypeId: Scalars['Float'];
};


export type QueryOfficeArgs = {
  officeId: Scalars['Float'];
};


export type QueryOrganizationArgs = {
  organizationId: Scalars['Float'];
};


export type QueryOrganizationsArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
};


export type QueryPartyArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  partyId: Scalars['Float'];
};


export type QueryPartiesArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
};


export type QueryPartyTypeArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  partyTypeId: Scalars['Float'];
};


export type QueryPartyTypesArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
};


export type QueryRoleCapabilityArgs = {
  roleCapabilityId: Scalars['Float'];
};


export type QueryRoleCapabilitiesArgs = {
  roleId: Scalars['Float'];
};


export type QueryRoleArgs = {
  roleId: Scalars['Float'];
};


export type QueryStatusArgs = {
  statusId: Scalars['Float'];
};


export type QueryTeamArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['Float'];
};


export type QueryTeamsArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['Float'];
};


export type QueryTestGetUserArgs = {
  userId: Scalars['String'];
};


export type QueryUsersByTeamArgs = {
  teamId: Scalars['Float'];
};


export type QueryUsersByOrganizationArgs = {
  organizationId: Scalars['Float'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserRoleArgs = {
  userRoleId: Scalars['Float'];
};


export type QueryUserRolesArgs = {
  userId: Scalars['String'];
};


export type QueryViewArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  viewId: Scalars['Float'];
};


export type QueryViewsArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
};


export type QueryWorkItemPartyArgs = {
  workItemPartyId: Scalars['Float'];
};


export type QueryWorkItemPartiesArgs = {
  workItemId: Scalars['Float'];
};


export type QueryWorkItemArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  workItemId: Scalars['Float'];
};


export type QueryWorkItemAndChildrenArgs = {
  includeRemoved?: Maybe<Scalars['Boolean']>;
  workItemId: Scalars['Float'];
};


export type QueryWorkItemsArgs = {
  filter?: Maybe<FilterWorkItemInput>;
  includeRemoved?: Maybe<Scalars['Boolean']>;
  take?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  organizationId: Scalars['Float'];
};


export type QueryWorkItemsCountArgs = {
  filter?: Maybe<FilterWorkItemInput>;
  organizationId: Scalars['Float'];
};


export type QueryWorkItemDescendantCountArgs = {
  workItemId: Scalars['Float'];
};


export type QueryWorkItemsByTeamArgs = {
  filter?: Maybe<FilterWorkItemInput>;
  includeRemoved?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['Float'];
};


export type QueryTagArgs = {
  tagId: Scalars['Float'];
};

export type Role = {
  __typename?: 'Role';
  roleId: Scalars['Int'];
  roleName: Scalars['String'];
  userRoles?: Maybe<Array<UserRole>>;
  roleCapabilities?: Maybe<Array<RoleCapability>>;
};

export type RoleCapability = {
  __typename?: 'RoleCapability';
  roleCapabilityId: Scalars['Int'];
  role: Role;
  roleId: Scalars['Int'];
  capability: Capability;
  capabilityId: Scalars['Int'];
};

export type Status = {
  __typename?: 'Status';
  statusId: Scalars['Int'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  notEquals?: Maybe<Scalars['String']>;
  like?: Maybe<Scalars['String']>;
  notLike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newWorkItem: WorkItemMessage;
  updateWorkItemSubscription: WorkItemMessage;
};


export type SubscriptionNewWorkItemArgs = {
  teamId: Scalars['Float'];
};


export type SubscriptionUpdateWorkItemSubscriptionArgs = {
  teamId: Scalars['Float'];
};

export type Tag = {
  __typename?: 'Tag';
  tagId: Scalars['Int'];
  workItemTags?: Maybe<Array<WorkItemTag>>;
  name: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  teamId: Scalars['Int'];
  name: Scalars['String'];
  dept: Scalars['String'];
  code: Scalars['String'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['Int']>;
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate: Scalars['DateTime'];
  modifiedBy: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type UpdateAssignees = {
  add?: Maybe<Array<Scalars['String']>>;
  remove?: Maybe<Array<Scalars['String']>>;
};

export type UpdateOfficeInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Float']>;
};

export type UpdatePartyInput = {
  name?: Maybe<Scalars['String']>;
  workPhone?: Maybe<Scalars['String']>;
  workPhoneExtension?: Maybe<Scalars['String']>;
  cellPhone?: Maybe<Scalars['String']>;
  preferredMethod?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
};

export type UpdatePartyTypeInput = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UpdateTags = {
  add?: Maybe<Array<Scalars['Int']>>;
  remove?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateTeamInput = {
  name?: Maybe<Scalars['String']>;
  dept?: Maybe<Scalars['String']>;
};

export type UpdateUserByIdInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hasApprovedTeamAlerts?: Maybe<Scalars['Boolean']>;
  userTeams?: Maybe<Array<UserTeamId>>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hasApprovedTeamAlerts?: Maybe<Scalars['Boolean']>;
};

export type UpdateViewInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  filter?: Maybe<FilterWorkItemInput>;
  isOpen?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  viewTypeId?: Maybe<Scalars['Float']>;
  groupBy?: Maybe<Scalars['String']>;
};

export type UpdateWorkItemForeignKeys = {
  itemTypeId?: Maybe<Scalars['Float']>;
  parentWorkItemId?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Float']>;
  workItemPartyForeignKeys?: Maybe<Array<WorkItemPartyForeignKeys>>;
  officeId?: Maybe<Scalars['Float']>;
  assignees?: Maybe<UpdateAssignees>;
  tags?: Maybe<UpdateTags>;
};

export type UpdateWorkItemInput = {
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  urgencyScale?: Maybe<Scalars['String']>;
  effort?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['Float']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  isUrgent?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  requester?: Maybe<Scalars['String']>;
  specialInstruction?: Maybe<Scalars['String']>;
  requestOriginator?: Maybe<Scalars['String']>;
  receivedDate?: Maybe<Scalars['DateTime']>;
  isRequested?: Maybe<Scalars['Boolean']>;
  externalReferenceNumber?: Maybe<Scalars['String']>;
  assignedTeam?: Maybe<Scalars['String']>;
  assignedLead?: Maybe<Scalars['String']>;
  assignedApprover?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  userTeams?: Maybe<Array<UserTeam>>;
  organizations?: Maybe<Array<UserOrganization>>;
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  views?: Maybe<Array<View>>;
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  hasApprovedTeamAlerts: Scalars['Boolean'];
  userRoles?: Maybe<Array<UserRole>>;
  createdDate: Scalars['DateTime'];
};

export type UserOrganization = {
  __typename?: 'UserOrganization';
  userOrganizationId: Scalars['Int'];
  user: User;
  email: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  userRoleId: Scalars['Int'];
  user: User;
  userId: Scalars['String'];
  role: Role;
  roleId: Scalars['Int'];
};

export type UserTeam = {
  __typename?: 'UserTeam';
  userTeamId: Scalars['Int'];
  user: User;
  userId: Scalars['String'];
  team: Team;
  teamId: Scalars['Int'];
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
};

export type UserTeamId = {
  id: Scalars['String'];
};

export type View = {
  __typename?: 'View';
  viewId: Scalars['Int'];
  name: Scalars['String'];
  user: User;
  email: Scalars['String'];
  groupBy?: Maybe<Scalars['String']>;
  filter: Filter;
  filters: Scalars['String'];
  isOpen: Scalars['Boolean'];
  viewType: ViewType;
  viewTypeId: Scalars['Int'];
  isRemoved: Scalars['Boolean'];
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate: Scalars['DateTime'];
  modifiedBy: Scalars['ID'];
};

export type ViewType = {
  __typename?: 'ViewType';
  viewTypeId: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type WorkItem = {
  __typename?: 'WorkItem';
  workItemId: Scalars['Int'];
  parentWorkItemId?: Maybe<Scalars['Int']>;
  parent?: Maybe<WorkItem>;
  children?: Maybe<Array<WorkItem>>;
  name: Scalars['String'];
  urgencyScale?: Maybe<Scalars['String']>;
  requester?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  effort?: Maybe<Scalars['String']>;
  team: Team;
  teamId: Scalars['Int'];
  dueDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  isUrgent?: Maybe<Scalars['Boolean']>;
  status: Status;
  statusId: Scalars['Int'];
  isBlocked?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  specialInstruction?: Maybe<Scalars['String']>;
  requestOriginator?: Maybe<Scalars['String']>;
  receivedDate?: Maybe<Scalars['DateTime']>;
  isRequested: Scalars['Boolean'];
  externalReferenceNumber?: Maybe<Scalars['String']>;
  isRemoved: Scalars['Boolean'];
  itemType: ItemType;
  itemTypeId: Scalars['Int'];
  assignees?: Maybe<Array<WorkItemUser>>;
  history?: Maybe<Array<WorkItemHistory>>;
  attachments?: Maybe<Array<Attachment>>;
  workItemParties?: Maybe<Array<WorkItemParties>>;
  office?: Maybe<Office>;
  officeId?: Maybe<Scalars['Int']>;
  assignedLead?: Maybe<Scalars['String']>;
  assignedApprover?: Maybe<Scalars['String']>;
  assignedTeam?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate: Scalars['DateTime'];
  modifiedBy: Scalars['ID'];
  workItemTags?: Maybe<Array<WorkItemTag>>;
  rollupAttachmentsCount?: Maybe<Scalars['Int']>;
  rollupCommentsCount?: Maybe<Scalars['Int']>;
};

export type WorkItemAndChildren = {
  __typename?: 'WorkItemAndChildren';
  workItem: WorkItem;
  children?: Maybe<Array<WorkItem>>;
};

export type WorkItemHistory = {
  __typename?: 'WorkItemHistory';
  workItemHistoryId: Scalars['Int'];
  workItem?: Maybe<WorkItem>;
  workItemId: Scalars['Int'];
  field: Scalars['String'];
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  user?: Maybe<User>;
};

export type WorkItemMessage = {
  __typename?: 'WorkItemMessage';
  workItem?: Maybe<WorkItem>;
};

export type WorkItemParties = {
  __typename?: 'WorkItemParties';
  workItemPartyId: Scalars['Int'];
  workItem: WorkItem;
  workItemId: Scalars['Int'];
  party: Party;
  partyId: Scalars['Int'];
  partyType: PartyType;
  partyTypeId?: Maybe<Scalars['Int']>;
  isRemoved: Scalars['Boolean'];
  createdDate: Scalars['DateTime'];
  createdBy: Scalars['ID'];
  modifiedDate: Scalars['DateTime'];
  modifiedBy: Scalars['ID'];
};

export type WorkItemPartyForeignKeys = {
  workItemPartyId?: Maybe<Scalars['Float']>;
  partyId: Scalars['Float'];
  partyTypeId?: Maybe<Scalars['Float']>;
};

export type WorkItemTag = {
  __typename?: 'WorkItemTag';
  workItemTagId: Scalars['Int'];
  workItem: WorkItem;
  workItemId: Scalars['Int'];
  tag: Tag;
  tagId: Scalars['Int'];
};

export type WorkItemUser = {
  __typename?: 'WorkItemUser';
  workItemUserId: Scalars['Int'];
  workItem?: Maybe<WorkItem>;
  workItemId: Scalars['Int'];
  userId: Scalars['ID'];
  user?: Maybe<User>;
};

export type CreateAttachmentMutationVariables = Exact<{
  data: CreateAttachmentInput;
}>;


export type CreateAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createAttachment: (
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'attachmentId' | 'workItemId' | 'modifiedDate' | 'fileName' | 'path' | 'isGoogleDriveFile' | 'size'>
  ) }
);

export type CreateFeedbackMutationVariables = Exact<{
  data: CreateFeedbackInput;
}>;


export type CreateFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { createFeedback: (
    { __typename?: 'Feedback' }
    & Pick<Feedback, 'feedbackId' | 'notes' | 'name'>
  ) }
);

export type CreateTagsMutationVariables = Exact<{
  data: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateTagsMutation = (
  { __typename?: 'Mutation' }
  & { createTags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'tagId' | 'name'>
  )> }
);

export type AddUserToTeamMutationVariables = Exact<{
  email: Scalars['String'];
  teamId: Scalars['Float'];
}>;


export type AddUserToTeamMutation = (
  { __typename?: 'Mutation' }
  & { addUserToTeam: (
    { __typename?: 'UserTeam' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
    ), team: (
      { __typename?: 'Team' }
      & Pick<Team, 'name'>
    ) }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  email: Scalars['String'];
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'lastName' | 'hasApprovedTeamAlerts'>
  ) }
);

export type CreateViewMutationVariables = Exact<{
  data: CreateViewInput;
}>;


export type CreateViewMutation = (
  { __typename?: 'Mutation' }
  & { createView: (
    { __typename?: 'View' }
    & Pick<View, 'viewId' | 'isOpen' | 'name' | 'filters' | 'viewTypeId' | 'isRemoved' | 'groupBy'>
  ) }
);

export type UpdateViewMutationVariables = Exact<{
  viewId: Scalars['Float'];
  data: UpdateViewInput;
}>;


export type UpdateViewMutation = (
  { __typename?: 'Mutation' }
  & { updateView: (
    { __typename?: 'View' }
    & Pick<View, 'viewId' | 'isOpen' | 'name' | 'filters' | 'viewTypeId' | 'isRemoved' | 'groupBy'>
  ) }
);

export type UpdateWorkItemMutationVariables = Exact<{
  workItemId: Scalars['Float'];
  foreignKeys?: Maybe<UpdateWorkItemForeignKeys>;
  data: UpdateWorkItemInput;
  files?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  sizes?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
}>;


export type UpdateWorkItemMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkItem: (
    { __typename?: 'WorkItem' }
    & Pick<WorkItem, 'workItemId' | 'parentWorkItemId' | 'name' | 'priority' | 'effort' | 'dueDate' | 'startDate' | 'description' | 'urgencyScale' | 'requester' | 'statusId' | 'officeId' | 'specialInstruction' | 'requestOriginator' | 'receivedDate' | 'isRequested' | 'externalReferenceNumber' | 'assignedTeam' | 'assignedLead' | 'assignedApprover' | 'isRemoved'>
    & { attachments?: Maybe<Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile'>
    )>>, itemType: (
      { __typename?: 'ItemType' }
      & Pick<ItemType, 'itemTypeId' | 'name' | 'code'>
    ), team: (
      { __typename?: 'Team' }
      & Pick<Team, 'teamId' | 'name' | 'dept'>
    ), status: (
      { __typename?: 'Status' }
      & Pick<Status, 'statusId' | 'name' | 'icon'>
    ), children?: Maybe<Array<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'name' | 'workItemId' | 'parentWorkItemId' | 'dueDate' | 'createdDate' | 'statusId' | 'requester' | 'teamId' | 'isRemoved'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile'>
      )>>, assignees?: Maybe<Array<(
        { __typename?: 'WorkItemUser' }
        & Pick<WorkItemUser, 'userId'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'avatar'>
        )> }
      )>> }
    )>>, history?: Maybe<Array<(
      { __typename?: 'WorkItemHistory' }
      & Pick<WorkItemHistory, 'field' | 'oldValue' | 'newValue' | 'createdDate'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'lastName' | 'firstName'>
      )> }
    )>>, workItemParties?: Maybe<Array<(
      { __typename?: 'WorkItemParties' }
      & Pick<WorkItemParties, 'workItemPartyId' | 'isRemoved' | 'partyId' | 'partyTypeId'>
      & { partyType: (
        { __typename?: 'PartyType' }
        & Pick<PartyType, 'partyTypeId' | 'name' | 'code'>
      ), party: (
        { __typename?: 'Party' }
        & Pick<Party, 'partyId' | 'name' | 'workPhone' | 'workPhoneExtension' | 'cellPhone' | 'preferredMethod' | 'emailAddress'>
      ) }
    )>>, assignees?: Maybe<Array<(
      { __typename?: 'WorkItemUser' }
      & Pick<WorkItemUser, 'userId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'avatar'>
      )> }
    )>>, workItemTags?: Maybe<Array<(
      { __typename?: 'WorkItemTag' }
      & Pick<WorkItemTag, 'workItemTagId'>
      & { tag: (
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'tagId'>
      ) }
    )>> }
  ) }
);

export type CreateWorkItemMutationVariables = Exact<{
  data: CreateWorkItemInput;
  foreignKeys: CreateWorkItemForeignKeys;
  files?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  sizes?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
}>;


export type CreateWorkItemMutation = (
  { __typename?: 'Mutation' }
  & { createWorkItem: (
    { __typename?: 'WorkItem' }
    & Pick<WorkItem, 'workItemId' | 'name' | 'priority' | 'effort' | 'createdDate' | 'dueDate' | 'startDate' | 'description' | 'specialInstruction' | 'urgencyScale' | 'requester' | 'teamId' | 'statusId' | 'isRemoved' | 'isRequested'>
    & { attachments?: Maybe<Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'attachmentId' | 'path' | 'fileName'>
    )>>, itemType: (
      { __typename?: 'ItemType' }
      & Pick<ItemType, 'itemTypeId' | 'name' | 'code'>
    ), team: (
      { __typename?: 'Team' }
      & Pick<Team, 'teamId' | 'name' | 'dept'>
    ), status: (
      { __typename?: 'Status' }
      & Pick<Status, 'statusId' | 'name' | 'icon'>
    ), assignees?: Maybe<Array<(
      { __typename?: 'WorkItemUser' }
      & Pick<WorkItemUser, 'userId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'avatar'>
      )> }
    )>>, workItemTags?: Maybe<Array<(
      { __typename?: 'WorkItemTag' }
      & Pick<WorkItemTag, 'workItemTagId'>
      & { tag: (
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'tagId'>
      ) }
    )>> }
  ) }
);

export type DeleteWorkItemMutationVariables = Exact<{
  workItemId: Scalars['Float'];
}>;


export type DeleteWorkItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteWorkItem: (
    { __typename?: 'WorkItem' }
    & Pick<WorkItem, 'workItemId' | 'isRemoved'>
  ) }
);

export type DuplicateWorkItemMutationVariables = Exact<{
  workItemId: Scalars['Float'];
}>;


export type DuplicateWorkItemMutation = (
  { __typename?: 'Mutation' }
  & { duplicateWorkItem: (
    { __typename?: 'WorkItem' }
    & Pick<WorkItem, 'workItemId' | 'name' | 'priority' | 'effort' | 'createdDate' | 'dueDate' | 'startDate' | 'description' | 'specialInstruction' | 'urgencyScale' | 'requester' | 'teamId' | 'statusId' | 'isRemoved' | 'isRequested'>
    & { attachments?: Maybe<Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'attachmentId' | 'path' | 'fileName'>
    )>>, itemType: (
      { __typename?: 'ItemType' }
      & Pick<ItemType, 'itemTypeId' | 'name' | 'code'>
    ), team: (
      { __typename?: 'Team' }
      & Pick<Team, 'teamId' | 'name' | 'dept'>
    ), status: (
      { __typename?: 'Status' }
      & Pick<Status, 'statusId' | 'name' | 'icon'>
    ), assignees?: Maybe<Array<(
      { __typename?: 'WorkItemUser' }
      & Pick<WorkItemUser, 'userId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'avatar'>
      )> }
    )>> }
  ) }
);

export type DownloadAttachmentQueryVariables = Exact<{
  attachmentId: Scalars['Float'];
}>;


export type DownloadAttachmentQuery = (
  { __typename?: 'Query' }
  & { downloadAttachment: (
    { __typename?: 'DownloadResponse' }
    & Pick<DownloadResponse, 'url' | 'fileName'>
  ) }
);

export type DeleteAttachmentMutationVariables = Exact<{
  attachmentId: Scalars['Float'];
}>;


export type DeleteAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAttachment'>
);

export type GetAttachmentsQueryVariables = Exact<{
  workItemId: Scalars['Float'];
}>;


export type GetAttachmentsQuery = (
  { __typename?: 'Query' }
  & { attachments: Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'fileName' | 'size' | 'isGoogleDriveFile' | 'path'>
  )> }
);

export type GetItemTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetItemTypesQuery = (
  { __typename?: 'Query' }
  & { itemTypes: Array<(
    { __typename?: 'ItemType' }
    & Pick<ItemType, 'itemTypeId' | 'name' | 'code'>
  )> }
);

export type GetStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatusesQuery = (
  { __typename?: 'Query' }
  & { statuses: Array<(
    { __typename?: 'Status' }
    & Pick<Status, 'statusId' | 'name' | 'icon'>
  )> }
);

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'tagId' | 'name'>
  )> }
);

export type GetTeamsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  includeRemoved?: Maybe<Scalars['Boolean']>;
}>;


export type GetTeamsQuery = (
  { __typename?: 'Query' }
  & { teams: Array<(
    { __typename?: 'Team' }
    & Pick<Team, 'teamId' | 'name' | 'isRemoved' | 'organizationId'>
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    )> }
  )> }
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'hasApprovedTeamAlerts'>
    & { organizations?: Maybe<Array<(
      { __typename?: 'UserOrganization' }
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'name' | 'organizationId'>
      ) }
    )>>, userTeams?: Maybe<Array<(
      { __typename?: 'UserTeam' }
      & Pick<UserTeam, 'teamId'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'teamId' | 'name' | 'dept' | 'organizationId'>
        & { organization?: Maybe<(
          { __typename?: 'Organization' }
          & Pick<Organization, 'organizationId' | 'name'>
        )> }
      ) }
    )>>, userRoles?: Maybe<Array<(
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'userRoleId'>
      & { role: (
        { __typename?: 'Role' }
        & Pick<Role, 'roleId' | 'roleName'>
        & { roleCapabilities?: Maybe<Array<(
          { __typename?: 'RoleCapability' }
          & Pick<RoleCapability, 'roleCapabilityId'>
          & { capability: (
            { __typename?: 'Capability' }
            & Pick<Capability, 'capabilityId' | 'name' | 'function'>
          ) }
        )>> }
      ) }
    )>>, views?: Maybe<Array<(
      { __typename?: 'View' }
      & Pick<View, 'viewId' | 'isOpen' | 'name' | 'filters' | 'groupBy' | 'viewTypeId' | 'isRemoved'>
    )>> }
  ) }
);

export type GetUsersByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['Float'];
}>;


export type GetUsersByOrganizationQuery = (
  { __typename?: 'Query' }
  & { usersByOrganization: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'hasApprovedTeamAlerts' | 'createdDate'>
    & { organizations?: Maybe<Array<(
      { __typename?: 'UserOrganization' }
      & Pick<UserOrganization, 'organizationId'>
      & { organization: (
        { __typename?: 'Organization' }
        & Pick<Organization, 'name'>
      ) }
    )>>, userTeams?: Maybe<Array<(
      { __typename?: 'UserTeam' }
      & Pick<UserTeam, 'teamId'>
      & { team: (
        { __typename?: 'Team' }
        & Pick<Team, 'teamId' | 'name' | 'dept' | 'organizationId'>
      ) }
    )>>, userRoles?: Maybe<Array<(
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'userRoleId'>
      & { role: (
        { __typename?: 'Role' }
        & Pick<Role, 'roleId' | 'roleName'>
        & { roleCapabilities?: Maybe<Array<(
          { __typename?: 'RoleCapability' }
          & Pick<RoleCapability, 'roleCapabilityId'>
          & { capability: (
            { __typename?: 'Capability' }
            & Pick<Capability, 'capabilityId' | 'name' | 'function'>
          ) }
        )>> }
      ) }
    )>> }
  )> }
);

export type GetUsersByTeamQueryVariables = Exact<{
  teamId: Scalars['Float'];
}>;


export type GetUsersByTeamQuery = (
  { __typename?: 'Query' }
  & { usersByTeam: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'avatar'>
  )> }
);

export type GetWorkItemsCountQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  filter?: Maybe<FilterWorkItemInput>;
}>;


export type GetWorkItemsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'workItemsCount'>
);

export type GetWorkItemsQueryVariables = Exact<{
  organizationId: Scalars['Float'];
  skip: Scalars['Float'];
  take: Scalars['Float'];
  filter?: Maybe<FilterWorkItemInput>;
}>;


export type GetWorkItemsQuery = (
  { __typename?: 'Query' }
  & { workItems: Array<(
    { __typename?: 'WorkItem' }
    & Pick<WorkItem, 'workItemId' | 'parentWorkItemId' | 'name' | 'urgencyScale' | 'requester' | 'priority' | 'effort' | 'dueDate' | 'startDate' | 'description' | 'teamId' | 'createdDate' | 'rollupAttachmentsCount' | 'itemTypeId' | 'statusId' | 'specialInstruction' | 'requestOriginator' | 'receivedDate' | 'isRequested' | 'externalReferenceNumber' | 'assignedTeam' | 'assignedLead' | 'assignedApprover'>
    & { parent?: Maybe<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'workItemId' | 'name' | 'teamId'>
    )>, children?: Maybe<Array<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'name' | 'workItemId' | 'description' | 'specialInstruction' | 'parentWorkItemId' | 'dueDate' | 'createdDate' | 'statusId' | 'requester' | 'teamId' | 'isRemoved'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile' | 'createdBy'>
      )>>, assignees?: Maybe<Array<(
        { __typename?: 'WorkItemUser' }
        & Pick<WorkItemUser, 'userId'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'avatar'>
        )> }
      )>> }
    )>>, itemType: (
      { __typename?: 'ItemType' }
      & Pick<ItemType, 'itemTypeId' | 'name' | 'code'>
    ), team: (
      { __typename?: 'Team' }
      & Pick<Team, 'teamId' | 'name' | 'dept'>
    ), workItemTags?: Maybe<Array<(
      { __typename?: 'WorkItemTag' }
      & Pick<WorkItemTag, 'workItemTagId'>
      & { tag: (
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'tagId'>
      ) }
    )>>, attachments?: Maybe<Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile' | 'createdBy'>
    )>>, status: (
      { __typename?: 'Status' }
      & Pick<Status, 'statusId' | 'name'>
    ), assignees?: Maybe<Array<(
      { __typename?: 'WorkItemUser' }
      & Pick<WorkItemUser, 'userId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'avatar'>
      )> }
    )>>, history?: Maybe<Array<(
      { __typename?: 'WorkItemHistory' }
      & Pick<WorkItemHistory, 'workItemHistoryId'>
    )>> }
  )> }
);

export type GetWorkItemQueryVariables = Exact<{
  workItemId: Scalars['Float'];
}>;


export type GetWorkItemQuery = (
  { __typename?: 'Query' }
  & { workItem: (
    { __typename?: 'WorkItem' }
    & Pick<WorkItem, 'workItemId' | 'name' | 'priority' | 'effort' | 'dueDate' | 'startDate' | 'description' | 'specialInstruction' | 'createdDate' | 'urgencyScale' | 'requester' | 'itemTypeId' | 'teamId' | 'statusId' | 'receivedDate' | 'isRequested' | 'assignedTeam' | 'parentWorkItemId'>
    & { workItemTags?: Maybe<Array<(
      { __typename?: 'WorkItemTag' }
      & Pick<WorkItemTag, 'workItemTagId'>
      & { tag: (
        { __typename?: 'Tag' }
        & Pick<Tag, 'name' | 'tagId'>
      ) }
    )>>, attachments?: Maybe<Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile' | 'createdBy'>
    )>>, itemType: (
      { __typename?: 'ItemType' }
      & Pick<ItemType, 'name' | 'code'>
    ), team: (
      { __typename?: 'Team' }
      & Pick<Team, 'teamId' | 'name' | 'dept'>
    ), status: (
      { __typename?: 'Status' }
      & Pick<Status, 'statusId' | 'name'>
    ), assignees?: Maybe<Array<(
      { __typename?: 'WorkItemUser' }
      & Pick<WorkItemUser, 'userId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'avatar'>
      )> }
    )>>, parent?: Maybe<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'workItemId' | 'name' | 'teamId'>
    )>, children?: Maybe<Array<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'name' | 'workItemId' | 'description' | 'specialInstruction' | 'parentWorkItemId' | 'dueDate' | 'createdDate' | 'statusId' | 'requester' | 'teamId' | 'isRemoved'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile' | 'createdBy'>
      )>>, assignees?: Maybe<Array<(
        { __typename?: 'WorkItemUser' }
        & Pick<WorkItemUser, 'userId'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'avatar'>
        )> }
      )>>, history?: Maybe<Array<(
        { __typename?: 'WorkItemHistory' }
        & Pick<WorkItemHistory, 'field' | 'oldValue' | 'newValue' | 'createdDate'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'lastName' | 'firstName'>
        )> }
      )>>, workItemTags?: Maybe<Array<(
        { __typename?: 'WorkItemTag' }
        & Pick<WorkItemTag, 'workItemTagId'>
        & { tag: (
          { __typename?: 'Tag' }
          & Pick<Tag, 'name' | 'tagId'>
        ) }
      )>> }
    )>> }
  ) }
);

export type GetWorkItemAndChildrenQueryVariables = Exact<{
  workItemId: Scalars['Float'];
}>;


export type GetWorkItemAndChildrenQuery = (
  { __typename?: 'Query' }
  & { workItemAndChildren: (
    { __typename?: 'WorkItemAndChildren' }
    & { workItem: (
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'workItemId' | 'name' | 'priority' | 'effort' | 'dueDate' | 'startDate' | 'description' | 'urgencyScale' | 'requester' | 'statusId' | 'officeId' | 'specialInstruction' | 'requestOriginator' | 'receivedDate' | 'isRequested' | 'externalReferenceNumber' | 'assignedTeam' | 'assignedLead' | 'assignedApprover'>
      & { parent?: Maybe<(
        { __typename?: 'WorkItem' }
        & Pick<WorkItem, 'workItemId' | 'name' | 'teamId'>
      )>, history?: Maybe<Array<(
        { __typename?: 'WorkItemHistory' }
        & Pick<WorkItemHistory, 'field' | 'oldValue' | 'newValue' | 'createdDate'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'lastName' | 'firstName'>
        )> }
      )>>, office?: Maybe<(
        { __typename?: 'Office' }
        & Pick<Office, 'officeId' | 'name' | 'code'>
      )>, workItemParties?: Maybe<Array<(
        { __typename?: 'WorkItemParties' }
        & Pick<WorkItemParties, 'workItemPartyId' | 'isRemoved' | 'partyId' | 'partyTypeId'>
        & { partyType: (
          { __typename?: 'PartyType' }
          & Pick<PartyType, 'partyTypeId' | 'name' | 'code'>
        ), party: (
          { __typename?: 'Party' }
          & Pick<Party, 'partyId' | 'name' | 'workPhone' | 'workPhoneExtension' | 'cellPhone' | 'preferredMethod' | 'emailAddress'>
        ) }
      )>>, attachments?: Maybe<Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile' | 'createdBy'>
      )>>, itemType: (
        { __typename?: 'ItemType' }
        & Pick<ItemType, 'itemTypeId' | 'name' | 'code'>
      ), team: (
        { __typename?: 'Team' }
        & Pick<Team, 'teamId' | 'name' | 'dept'>
      ), status: (
        { __typename?: 'Status' }
        & Pick<Status, 'statusId' | 'name' | 'icon'>
      ), assignees?: Maybe<Array<(
        { __typename?: 'WorkItemUser' }
        & Pick<WorkItemUser, 'userId'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'avatar'>
        )> }
      )>> }
    ), children?: Maybe<Array<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'workItemId' | 'name' | 'priority' | 'effort' | 'dueDate' | 'startDate' | 'description' | 'isRemoved' | 'urgencyScale' | 'requester' | 'statusId' | 'officeId' | 'specialInstruction' | 'requestOriginator' | 'receivedDate' | 'isRequested' | 'externalReferenceNumber' | 'assignedTeam' | 'assignedLead' | 'assignedApprover'>
      & { history?: Maybe<Array<(
        { __typename?: 'WorkItemHistory' }
        & Pick<WorkItemHistory, 'field' | 'oldValue' | 'newValue' | 'createdDate'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'lastName' | 'firstName'>
        )> }
      )>>, office?: Maybe<(
        { __typename?: 'Office' }
        & Pick<Office, 'officeId' | 'name' | 'code'>
      )>, workItemParties?: Maybe<Array<(
        { __typename?: 'WorkItemParties' }
        & Pick<WorkItemParties, 'workItemPartyId' | 'isRemoved' | 'partyId' | 'partyTypeId'>
        & { partyType: (
          { __typename?: 'PartyType' }
          & Pick<PartyType, 'partyTypeId' | 'name' | 'code'>
        ), party: (
          { __typename?: 'Party' }
          & Pick<Party, 'partyId' | 'name' | 'workPhone' | 'workPhoneExtension' | 'cellPhone' | 'preferredMethod' | 'emailAddress'>
        ) }
      )>>, attachments?: Maybe<Array<(
        { __typename?: 'Attachment' }
        & Pick<Attachment, 'attachmentId' | 'modifiedDate' | 'path' | 'fileName' | 'size' | 'isGoogleDriveFile' | 'createdBy'>
      )>>, itemType: (
        { __typename?: 'ItemType' }
        & Pick<ItemType, 'itemTypeId' | 'name' | 'code'>
      ), team: (
        { __typename?: 'Team' }
        & Pick<Team, 'teamId' | 'name' | 'dept'>
      ), status: (
        { __typename?: 'Status' }
        & Pick<Status, 'statusId' | 'name' | 'icon'>
      ), assignees?: Maybe<Array<(
        { __typename?: 'WorkItemUser' }
        & Pick<WorkItemUser, 'userId'>
        & { user?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'lastName' | 'avatar'>
        )> }
      )>> }
    )>> }
  ) }
);

export type GetWorkItemDescendantCountQueryVariables = Exact<{
  workItemId: Scalars['Float'];
}>;


export type GetWorkItemDescendantCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'workItemDescendantCount'>
);

export type NewWorkItemSubscriptionVariables = Exact<{
  teamId: Scalars['Float'];
}>;


export type NewWorkItemSubscription = (
  { __typename?: 'Subscription' }
  & { newWorkItem: (
    { __typename?: 'WorkItemMessage' }
    & { workItem?: Maybe<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'name' | 'workItemId'>
    )> }
  ) }
);

export type UpdateWorkItemSubscriptionVariables = Exact<{
  teamId: Scalars['Float'];
}>;


export type UpdateWorkItemSubscription = (
  { __typename?: 'Subscription' }
  & { updateWorkItemSubscription: (
    { __typename?: 'WorkItemMessage' }
    & { workItem?: Maybe<(
      { __typename?: 'WorkItem' }
      & Pick<WorkItem, 'workItemId' | 'name'>
    )> }
  ) }
);


export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($data: CreateAttachmentInput!) {
  createAttachment(data: $data) {
    attachmentId
    workItemId
    modifiedDate
    fileName
    path
    isGoogleDriveFile
    size
  }
}
    `;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(CreateAttachmentDocument, options);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const CreateFeedbackDocument = gql`
    mutation CreateFeedback($data: CreateFeedbackInput!) {
  createFeedback(data: $data) {
    feedbackId
    notes
    name
  }
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const CreateTagsDocument = gql`
    mutation CreateTags($data: [String!]!) {
  createTags(data: $data) {
    tagId
    name
  }
}
    `;
export type CreateTagsMutationFn = Apollo.MutationFunction<CreateTagsMutation, CreateTagsMutationVariables>;

/**
 * __useCreateTagsMutation__
 *
 * To run a mutation, you first call `useCreateTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagsMutation, { data, loading, error }] = useCreateTagsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagsMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagsMutation, CreateTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagsMutation, CreateTagsMutationVariables>(CreateTagsDocument, options);
      }
export type CreateTagsMutationHookResult = ReturnType<typeof useCreateTagsMutation>;
export type CreateTagsMutationResult = Apollo.MutationResult<CreateTagsMutation>;
export type CreateTagsMutationOptions = Apollo.BaseMutationOptions<CreateTagsMutation, CreateTagsMutationVariables>;
export const AddUserToTeamDocument = gql`
    mutation AddUserToTeam($email: String!, $teamId: Float!) {
  addUserToTeam(email: $email, teamId: $teamId) {
    user {
      email
    }
    team {
      name
    }
  }
}
    `;
export type AddUserToTeamMutationFn = Apollo.MutationFunction<AddUserToTeamMutation, AddUserToTeamMutationVariables>;

/**
 * __useAddUserToTeamMutation__
 *
 * To run a mutation, you first call `useAddUserToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToTeamMutation, { data, loading, error }] = useAddUserToTeamMutation({
 *   variables: {
 *      email: // value for 'email'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAddUserToTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToTeamMutation, AddUserToTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToTeamMutation, AddUserToTeamMutationVariables>(AddUserToTeamDocument, options);
      }
export type AddUserToTeamMutationHookResult = ReturnType<typeof useAddUserToTeamMutation>;
export type AddUserToTeamMutationResult = Apollo.MutationResult<AddUserToTeamMutation>;
export type AddUserToTeamMutationOptions = Apollo.BaseMutationOptions<AddUserToTeamMutation, AddUserToTeamMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($email: String!, $data: UpdateUserInput!) {
  updateUser(email: $email, data: $data) {
    email
    firstName
    lastName
    hasApprovedTeamAlerts
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const CreateViewDocument = gql`
    mutation CreateView($data: CreateViewInput!) {
  createView(data: $data) {
    viewId
    isOpen
    name
    filters
    viewTypeId
    isRemoved
    groupBy
  }
}
    `;
export type CreateViewMutationFn = Apollo.MutationFunction<CreateViewMutation, CreateViewMutationVariables>;

/**
 * __useCreateViewMutation__
 *
 * To run a mutation, you first call `useCreateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewMutation, { data, loading, error }] = useCreateViewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateViewMutation(baseOptions?: Apollo.MutationHookOptions<CreateViewMutation, CreateViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateViewMutation, CreateViewMutationVariables>(CreateViewDocument, options);
      }
export type CreateViewMutationHookResult = ReturnType<typeof useCreateViewMutation>;
export type CreateViewMutationResult = Apollo.MutationResult<CreateViewMutation>;
export type CreateViewMutationOptions = Apollo.BaseMutationOptions<CreateViewMutation, CreateViewMutationVariables>;
export const UpdateViewDocument = gql`
    mutation UpdateView($viewId: Float!, $data: UpdateViewInput!) {
  updateView(viewId: $viewId, data: $data) {
    viewId
    isOpen
    name
    filters
    viewTypeId
    isRemoved
    groupBy
  }
}
    `;
export type UpdateViewMutationFn = Apollo.MutationFunction<UpdateViewMutation, UpdateViewMutationVariables>;

/**
 * __useUpdateViewMutation__
 *
 * To run a mutation, you first call `useUpdateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewMutation, { data, loading, error }] = useUpdateViewMutation({
 *   variables: {
 *      viewId: // value for 'viewId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateViewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateViewMutation, UpdateViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateViewMutation, UpdateViewMutationVariables>(UpdateViewDocument, options);
      }
export type UpdateViewMutationHookResult = ReturnType<typeof useUpdateViewMutation>;
export type UpdateViewMutationResult = Apollo.MutationResult<UpdateViewMutation>;
export type UpdateViewMutationOptions = Apollo.BaseMutationOptions<UpdateViewMutation, UpdateViewMutationVariables>;
export const UpdateWorkItemDocument = gql`
    mutation UpdateWorkItem($workItemId: Float!, $foreignKeys: UpdateWorkItemForeignKeys, $data: UpdateWorkItemInput!, $files: [Upload!], $sizes: [Float!]) {
  updateWorkItem(workItemId: $workItemId, foreignKeys: $foreignKeys, data: $data, files: $files, sizes: $sizes) {
    workItemId
    parentWorkItemId
    name
    priority
    effort
    dueDate
    startDate
    description
    urgencyScale
    requester
    attachments {
      attachmentId
      modifiedDate
      path
      fileName
      size
      isGoogleDriveFile
    }
    itemType {
      itemTypeId
      name
      code
    }
    team {
      teamId
      name
      dept
    }
    statusId
    status {
      statusId
      name
      icon
    }
    officeId
    specialInstruction
    requestOriginator
    receivedDate
    isRequested
    externalReferenceNumber
    assignedTeam
    assignedLead
    assignedApprover
    isRemoved
    children {
      name
      workItemId
      parentWorkItemId
      dueDate
      createdDate
      statusId
      requester
      teamId
      isRemoved
      attachments {
        attachmentId
        modifiedDate
        path
        fileName
        size
        isGoogleDriveFile
      }
      assignees {
        userId
        user {
          firstName
          lastName
          avatar
        }
      }
    }
    history {
      field
      oldValue
      newValue
      createdDate
      user {
        lastName
        firstName
      }
    }
    workItemParties {
      workItemPartyId
      isRemoved
      partyId
      partyTypeId
      partyType {
        partyTypeId
        name
        code
      }
      party {
        partyId
        name
        workPhone
        workPhoneExtension
        cellPhone
        preferredMethod
        emailAddress
      }
    }
    assignees {
      userId
      user {
        firstName
        lastName
        avatar
      }
    }
    workItemTags {
      tag {
        name
        tagId
      }
      workItemTagId
    }
  }
}
    `;
export type UpdateWorkItemMutationFn = Apollo.MutationFunction<UpdateWorkItemMutation, UpdateWorkItemMutationVariables>;

/**
 * __useUpdateWorkItemMutation__
 *
 * To run a mutation, you first call `useUpdateWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkItemMutation, { data, loading, error }] = useUpdateWorkItemMutation({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *      foreignKeys: // value for 'foreignKeys'
 *      data: // value for 'data'
 *      files: // value for 'files'
 *      sizes: // value for 'sizes'
 *   },
 * });
 */
export function useUpdateWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkItemMutation, UpdateWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkItemMutation, UpdateWorkItemMutationVariables>(UpdateWorkItemDocument, options);
      }
export type UpdateWorkItemMutationHookResult = ReturnType<typeof useUpdateWorkItemMutation>;
export type UpdateWorkItemMutationResult = Apollo.MutationResult<UpdateWorkItemMutation>;
export type UpdateWorkItemMutationOptions = Apollo.BaseMutationOptions<UpdateWorkItemMutation, UpdateWorkItemMutationVariables>;
export const CreateWorkItemDocument = gql`
    mutation CreateWorkItem($data: CreateWorkItemInput!, $foreignKeys: CreateWorkItemForeignKeys!, $files: [Upload!], $sizes: [Float!]) {
  createWorkItem(data: $data, foreignKeys: $foreignKeys, files: $files, sizes: $sizes) {
    workItemId
    name
    priority
    effort
    createdDate
    dueDate
    startDate
    description
    specialInstruction
    urgencyScale
    requester
    attachments {
      attachmentId
      path
      fileName
    }
    itemType {
      itemTypeId
      name
      code
    }
    teamId
    team {
      teamId
      name
      dept
    }
    statusId
    status {
      statusId
      name
      icon
    }
    isRemoved
    isRequested
    assignees {
      userId
      user {
        firstName
        lastName
        avatar
      }
    }
    workItemTags {
      tag {
        name
        tagId
      }
      workItemTagId
    }
  }
}
    `;
export type CreateWorkItemMutationFn = Apollo.MutationFunction<CreateWorkItemMutation, CreateWorkItemMutationVariables>;

/**
 * __useCreateWorkItemMutation__
 *
 * To run a mutation, you first call `useCreateWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkItemMutation, { data, loading, error }] = useCreateWorkItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *      foreignKeys: // value for 'foreignKeys'
 *      files: // value for 'files'
 *      sizes: // value for 'sizes'
 *   },
 * });
 */
export function useCreateWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkItemMutation, CreateWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkItemMutation, CreateWorkItemMutationVariables>(CreateWorkItemDocument, options);
      }
export type CreateWorkItemMutationHookResult = ReturnType<typeof useCreateWorkItemMutation>;
export type CreateWorkItemMutationResult = Apollo.MutationResult<CreateWorkItemMutation>;
export type CreateWorkItemMutationOptions = Apollo.BaseMutationOptions<CreateWorkItemMutation, CreateWorkItemMutationVariables>;
export const DeleteWorkItemDocument = gql`
    mutation DeleteWorkItem($workItemId: Float!) {
  deleteWorkItem(workItemId: $workItemId) {
    workItemId
    isRemoved
  }
}
    `;
export type DeleteWorkItemMutationFn = Apollo.MutationFunction<DeleteWorkItemMutation, DeleteWorkItemMutationVariables>;

/**
 * __useDeleteWorkItemMutation__
 *
 * To run a mutation, you first call `useDeleteWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkItemMutation, { data, loading, error }] = useDeleteWorkItemMutation({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useDeleteWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkItemMutation, DeleteWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkItemMutation, DeleteWorkItemMutationVariables>(DeleteWorkItemDocument, options);
      }
export type DeleteWorkItemMutationHookResult = ReturnType<typeof useDeleteWorkItemMutation>;
export type DeleteWorkItemMutationResult = Apollo.MutationResult<DeleteWorkItemMutation>;
export type DeleteWorkItemMutationOptions = Apollo.BaseMutationOptions<DeleteWorkItemMutation, DeleteWorkItemMutationVariables>;
export const DuplicateWorkItemDocument = gql`
    mutation duplicateWorkItem($workItemId: Float!) {
  duplicateWorkItem(data: {workItemId: $workItemId}) {
    workItemId
    name
    priority
    effort
    createdDate
    dueDate
    startDate
    description
    specialInstruction
    urgencyScale
    requester
    attachments {
      attachmentId
      path
      fileName
    }
    itemType {
      itemTypeId
      name
      code
    }
    teamId
    team {
      teamId
      name
      dept
    }
    statusId
    status {
      statusId
      name
      icon
    }
    isRemoved
    isRequested
    assignees {
      userId
      user {
        firstName
        lastName
        avatar
      }
    }
  }
}
    `;
export type DuplicateWorkItemMutationFn = Apollo.MutationFunction<DuplicateWorkItemMutation, DuplicateWorkItemMutationVariables>;

/**
 * __useDuplicateWorkItemMutation__
 *
 * To run a mutation, you first call `useDuplicateWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateWorkItemMutation, { data, loading, error }] = useDuplicateWorkItemMutation({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useDuplicateWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateWorkItemMutation, DuplicateWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateWorkItemMutation, DuplicateWorkItemMutationVariables>(DuplicateWorkItemDocument, options);
      }
export type DuplicateWorkItemMutationHookResult = ReturnType<typeof useDuplicateWorkItemMutation>;
export type DuplicateWorkItemMutationResult = Apollo.MutationResult<DuplicateWorkItemMutation>;
export type DuplicateWorkItemMutationOptions = Apollo.BaseMutationOptions<DuplicateWorkItemMutation, DuplicateWorkItemMutationVariables>;
export const DownloadAttachmentDocument = gql`
    query DownloadAttachment($attachmentId: Float!) {
  downloadAttachment(attachmentId: $attachmentId) {
    url
    fileName
  }
}
    `;

/**
 * __useDownloadAttachmentQuery__
 *
 * To run a query within a React component, call `useDownloadAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAttachmentQuery({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDownloadAttachmentQuery(baseOptions: Apollo.QueryHookOptions<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>(DownloadAttachmentDocument, options);
      }
export function useDownloadAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>(DownloadAttachmentDocument, options);
        }
export type DownloadAttachmentQueryHookResult = ReturnType<typeof useDownloadAttachmentQuery>;
export type DownloadAttachmentLazyQueryHookResult = ReturnType<typeof useDownloadAttachmentLazyQuery>;
export type DownloadAttachmentQueryResult = Apollo.QueryResult<DownloadAttachmentQuery, DownloadAttachmentQueryVariables>;
export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($attachmentId: Float!) {
  deleteAttachment(attachmentId: $attachmentId)
}
    `;
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, options);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = Apollo.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const GetAttachmentsDocument = gql`
    query GetAttachments($workItemId: Float!) {
  attachments(workItemId: $workItemId) {
    attachmentId
    modifiedDate
    fileName
    size
    isGoogleDriveFile
    path
  }
}
    `;

/**
 * __useGetAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsQuery({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useGetAttachmentsQuery(baseOptions: Apollo.QueryHookOptions<GetAttachmentsQuery, GetAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(GetAttachmentsDocument, options);
      }
export function useGetAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentsQuery, GetAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(GetAttachmentsDocument, options);
        }
export type GetAttachmentsQueryHookResult = ReturnType<typeof useGetAttachmentsQuery>;
export type GetAttachmentsLazyQueryHookResult = ReturnType<typeof useGetAttachmentsLazyQuery>;
export type GetAttachmentsQueryResult = Apollo.QueryResult<GetAttachmentsQuery, GetAttachmentsQueryVariables>;
export const GetItemTypesDocument = gql`
    query GetItemTypes {
  itemTypes {
    itemTypeId
    name
    code
  }
}
    `;

/**
 * __useGetItemTypesQuery__
 *
 * To run a query within a React component, call `useGetItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetItemTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetItemTypesQuery, GetItemTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemTypesQuery, GetItemTypesQueryVariables>(GetItemTypesDocument, options);
      }
export function useGetItemTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemTypesQuery, GetItemTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemTypesQuery, GetItemTypesQueryVariables>(GetItemTypesDocument, options);
        }
export type GetItemTypesQueryHookResult = ReturnType<typeof useGetItemTypesQuery>;
export type GetItemTypesLazyQueryHookResult = ReturnType<typeof useGetItemTypesLazyQuery>;
export type GetItemTypesQueryResult = Apollo.QueryResult<GetItemTypesQuery, GetItemTypesQueryVariables>;
export const GetStatusesDocument = gql`
    query GetStatuses {
  statuses {
    statusId
    name
    icon
  }
}
    `;

/**
 * __useGetStatusesQuery__
 *
 * To run a query within a React component, call `useGetStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusesQuery, GetStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusesQuery, GetStatusesQueryVariables>(GetStatusesDocument, options);
      }
export function useGetStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusesQuery, GetStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusesQuery, GetStatusesQueryVariables>(GetStatusesDocument, options);
        }
export type GetStatusesQueryHookResult = ReturnType<typeof useGetStatusesQuery>;
export type GetStatusesLazyQueryHookResult = ReturnType<typeof useGetStatusesLazyQuery>;
export type GetStatusesQueryResult = Apollo.QueryResult<GetStatusesQuery, GetStatusesQueryVariables>;
export const GetTagsDocument = gql`
    query GetTags {
  tags {
    tagId
    name
  }
}
    `;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetTeamsDocument = gql`
    query GetTeams($organizationId: Float!, $includeRemoved: Boolean) {
  teams(includeRemoved: $includeRemoved, organizationId: $organizationId) {
    teamId
    name
    isRemoved
    organizationId
    organization {
      name
    }
  }
}
    `;

/**
 * __useGetTeamsQuery__
 *
 * To run a query within a React component, call `useGetTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      includeRemoved: // value for 'includeRemoved'
 *   },
 * });
 */
export function useGetTeamsQuery(baseOptions: Apollo.QueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, options);
      }
export function useGetTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamsQuery, GetTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamsQuery, GetTeamsQueryVariables>(GetTeamsDocument, options);
        }
export type GetTeamsQueryHookResult = ReturnType<typeof useGetTeamsQuery>;
export type GetTeamsLazyQueryHookResult = ReturnType<typeof useGetTeamsLazyQuery>;
export type GetTeamsQueryResult = Apollo.QueryResult<GetTeamsQuery, GetTeamsQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  user {
    id
    email
    firstName
    lastName
    hasApprovedTeamAlerts
    organizations {
      organization {
        name
        organizationId
      }
    }
    userTeams {
      teamId
      team {
        teamId
        name
        dept
        organizationId
        organization {
          organizationId
          name
        }
      }
    }
    userRoles {
      userRoleId
      role {
        roleId
        roleName
        roleCapabilities {
          roleCapabilityId
          capability {
            capabilityId
            name
            function
          }
        }
      }
    }
    views {
      viewId
      isOpen
      name
      filters
      groupBy
      viewTypeId
      isRemoved
      groupBy
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByOrganizationDocument = gql`
    query GetUsersByOrganization($organizationId: Float!) {
  usersByOrganization(organizationId: $organizationId) {
    id
    email
    firstName
    lastName
    hasApprovedTeamAlerts
    createdDate
    organizations {
      organizationId
      organization {
        name
      }
    }
    userTeams {
      teamId
      team {
        teamId
        name
        dept
        organizationId
      }
    }
    userRoles {
      userRoleId
      role {
        roleId
        roleName
        roleCapabilities {
          roleCapabilityId
          capability {
            capabilityId
            name
            function
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUsersByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetUsersByOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
      }
export function useGetUsersByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
        }
export type GetUsersByOrganizationQueryHookResult = ReturnType<typeof useGetUsersByOrganizationQuery>;
export type GetUsersByOrganizationLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationLazyQuery>;
export type GetUsersByOrganizationQueryResult = Apollo.QueryResult<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>;
export const GetUsersByTeamDocument = gql`
    query GetUsersByTeam($teamId: Float!) {
  usersByTeam(teamId: $teamId) {
    id
    firstName
    lastName
    avatar
  }
}
    `;

/**
 * __useGetUsersByTeamQuery__
 *
 * To run a query within a React component, call `useGetUsersByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetUsersByTeamQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>(GetUsersByTeamDocument, options);
      }
export function useGetUsersByTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>(GetUsersByTeamDocument, options);
        }
export type GetUsersByTeamQueryHookResult = ReturnType<typeof useGetUsersByTeamQuery>;
export type GetUsersByTeamLazyQueryHookResult = ReturnType<typeof useGetUsersByTeamLazyQuery>;
export type GetUsersByTeamQueryResult = Apollo.QueryResult<GetUsersByTeamQuery, GetUsersByTeamQueryVariables>;
export const GetWorkItemsCountDocument = gql`
    query GetWorkItemsCount($organizationId: Float!, $filter: FilterWorkItemInput) {
  workItemsCount(organizationId: $organizationId, filter: $filter)
}
    `;

/**
 * __useGetWorkItemsCountQuery__
 *
 * To run a query within a React component, call `useGetWorkItemsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkItemsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkItemsCountQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetWorkItemsCountQuery(baseOptions: Apollo.QueryHookOptions<GetWorkItemsCountQuery, GetWorkItemsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkItemsCountQuery, GetWorkItemsCountQueryVariables>(GetWorkItemsCountDocument, options);
      }
export function useGetWorkItemsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkItemsCountQuery, GetWorkItemsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkItemsCountQuery, GetWorkItemsCountQueryVariables>(GetWorkItemsCountDocument, options);
        }
export type GetWorkItemsCountQueryHookResult = ReturnType<typeof useGetWorkItemsCountQuery>;
export type GetWorkItemsCountLazyQueryHookResult = ReturnType<typeof useGetWorkItemsCountLazyQuery>;
export type GetWorkItemsCountQueryResult = Apollo.QueryResult<GetWorkItemsCountQuery, GetWorkItemsCountQueryVariables>;
export const GetWorkItemsDocument = gql`
    query GetWorkItems($organizationId: Float!, $skip: Float!, $take: Float!, $filter: FilterWorkItemInput) {
  workItems(organizationId: $organizationId, skip: $skip, take: $take, filter: $filter) {
    workItemId
    parentWorkItemId
    parent {
      workItemId
      name
      teamId
    }
    children {
      name
      workItemId
      description
      specialInstruction
      parentWorkItemId
      dueDate
      createdDate
      statusId
      requester
      teamId
      statusId
      isRemoved
      attachments {
        attachmentId
        modifiedDate
        path
        fileName
        size
        isGoogleDriveFile
        createdBy
      }
      assignees {
        userId
        user {
          firstName
          lastName
          avatar
        }
      }
    }
    name
    urgencyScale
    requester
    priority
    effort
    dueDate
    startDate
    description
    teamId
    requester
    createdDate
    rollupAttachmentsCount
    parent {
      teamId
    }
    itemType {
      itemTypeId
      name
      code
    }
    teamId
    team {
      teamId
      name
      dept
    }
    workItemTags {
      tag {
        name
        tagId
      }
      workItemTagId
    }
    attachments {
      attachmentId
      modifiedDate
      path
      fileName
      size
      isGoogleDriveFile
      createdBy
    }
    itemTypeId
    itemType {
      name
      code
    }
    statusId
    status {
      statusId
      name
    }
    specialInstruction
    requestOriginator
    receivedDate
    isRequested
    externalReferenceNumber
    assignedTeam
    assignedLead
    assignedApprover
    assignees {
      userId
      user {
        firstName
        lastName
        avatar
      }
    }
    history {
      workItemHistoryId
    }
  }
}
    `;

/**
 * __useGetWorkItemsQuery__
 *
 * To run a query within a React component, call `useGetWorkItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkItemsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetWorkItemsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkItemsQuery, GetWorkItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkItemsQuery, GetWorkItemsQueryVariables>(GetWorkItemsDocument, options);
      }
export function useGetWorkItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkItemsQuery, GetWorkItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkItemsQuery, GetWorkItemsQueryVariables>(GetWorkItemsDocument, options);
        }
export type GetWorkItemsQueryHookResult = ReturnType<typeof useGetWorkItemsQuery>;
export type GetWorkItemsLazyQueryHookResult = ReturnType<typeof useGetWorkItemsLazyQuery>;
export type GetWorkItemsQueryResult = Apollo.QueryResult<GetWorkItemsQuery, GetWorkItemsQueryVariables>;
export const GetWorkItemDocument = gql`
    query GetWorkItem($workItemId: Float!) {
  workItem(workItemId: $workItemId) {
    workItemId
    name
    priority
    effort
    dueDate
    startDate
    description
    specialInstruction
    createdDate
    urgencyScale
    requester
    workItemTags {
      tag {
        name
        tagId
      }
      workItemTagId
    }
    attachments {
      attachmentId
      modifiedDate
      path
      fileName
      size
      isGoogleDriveFile
      createdBy
    }
    itemTypeId
    itemType {
      name
      code
    }
    teamId
    team {
      teamId
      name
      dept
    }
    statusId
    status {
      statusId
      name
    }
    receivedDate
    isRequested
    assignedTeam
    assignees {
      userId
      user {
        firstName
        lastName
        avatar
      }
    }
    parentWorkItemId
    parent {
      workItemId
      name
      teamId
    }
    children {
      name
      workItemId
      description
      specialInstruction
      parentWorkItemId
      dueDate
      createdDate
      statusId
      requester
      teamId
      statusId
      isRemoved
      attachments {
        attachmentId
        modifiedDate
        path
        fileName
        size
        isGoogleDriveFile
        createdBy
      }
      assignees {
        userId
        user {
          firstName
          lastName
          avatar
        }
      }
      history {
        field
        oldValue
        newValue
        createdDate
        user {
          lastName
          firstName
        }
      }
      workItemTags {
        tag {
          name
          tagId
        }
        workItemTagId
      }
    }
  }
}
    `;

/**
 * __useGetWorkItemQuery__
 *
 * To run a query within a React component, call `useGetWorkItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkItemQuery({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useGetWorkItemQuery(baseOptions: Apollo.QueryHookOptions<GetWorkItemQuery, GetWorkItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkItemQuery, GetWorkItemQueryVariables>(GetWorkItemDocument, options);
      }
export function useGetWorkItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkItemQuery, GetWorkItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkItemQuery, GetWorkItemQueryVariables>(GetWorkItemDocument, options);
        }
export type GetWorkItemQueryHookResult = ReturnType<typeof useGetWorkItemQuery>;
export type GetWorkItemLazyQueryHookResult = ReturnType<typeof useGetWorkItemLazyQuery>;
export type GetWorkItemQueryResult = Apollo.QueryResult<GetWorkItemQuery, GetWorkItemQueryVariables>;
export const GetWorkItemAndChildrenDocument = gql`
    query GetWorkItemAndChildren($workItemId: Float!) {
  workItemAndChildren(workItemId: $workItemId) {
    workItem {
      workItemId
      parent {
        workItemId
        name
        teamId
      }
      name
      priority
      effort
      dueDate
      startDate
      description
      history {
        field
        oldValue
        newValue
        createdDate
        user {
          lastName
          firstName
        }
      }
      urgencyScale
      requester
      office {
        officeId
        name
        code
      }
      workItemParties {
        workItemPartyId
        isRemoved
        partyId
        partyTypeId
        partyType {
          partyTypeId
          name
          code
        }
        party {
          partyId
          name
          workPhone
          workPhoneExtension
          cellPhone
          preferredMethod
          emailAddress
        }
      }
      attachments {
        attachmentId
        modifiedDate
        path
        fileName
        size
        isGoogleDriveFile
        createdBy
      }
      itemType {
        itemTypeId
        name
        code
      }
      team {
        teamId
        name
        dept
      }
      statusId
      status {
        statusId
        name
        icon
      }
      officeId
      specialInstruction
      requestOriginator
      receivedDate
      isRequested
      externalReferenceNumber
      assignedTeam
      assignedLead
      assignedApprover
      assignees {
        userId
        user {
          firstName
          lastName
          avatar
        }
      }
    }
    children {
      workItemId
      name
      priority
      effort
      dueDate
      startDate
      description
      isRemoved
      history {
        field
        oldValue
        newValue
        createdDate
        user {
          lastName
          firstName
        }
      }
      urgencyScale
      requester
      office {
        officeId
        name
        code
      }
      workItemParties {
        workItemPartyId
        isRemoved
        partyId
        partyTypeId
        partyType {
          partyTypeId
          name
          code
        }
        party {
          partyId
          name
          workPhone
          workPhoneExtension
          cellPhone
          preferredMethod
          emailAddress
        }
      }
      attachments {
        attachmentId
        modifiedDate
        path
        fileName
        size
        isGoogleDriveFile
        createdBy
      }
      itemType {
        itemTypeId
        name
        code
      }
      team {
        teamId
        name
        dept
      }
      statusId
      status {
        statusId
        name
        icon
      }
      officeId
      specialInstruction
      requestOriginator
      receivedDate
      isRequested
      externalReferenceNumber
      assignedTeam
      assignedLead
      assignedApprover
      assignees {
        userId
        user {
          firstName
          lastName
          avatar
        }
      }
    }
  }
}
    `;

/**
 * __useGetWorkItemAndChildrenQuery__
 *
 * To run a query within a React component, call `useGetWorkItemAndChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkItemAndChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkItemAndChildrenQuery({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useGetWorkItemAndChildrenQuery(baseOptions: Apollo.QueryHookOptions<GetWorkItemAndChildrenQuery, GetWorkItemAndChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkItemAndChildrenQuery, GetWorkItemAndChildrenQueryVariables>(GetWorkItemAndChildrenDocument, options);
      }
export function useGetWorkItemAndChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkItemAndChildrenQuery, GetWorkItemAndChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkItemAndChildrenQuery, GetWorkItemAndChildrenQueryVariables>(GetWorkItemAndChildrenDocument, options);
        }
export type GetWorkItemAndChildrenQueryHookResult = ReturnType<typeof useGetWorkItemAndChildrenQuery>;
export type GetWorkItemAndChildrenLazyQueryHookResult = ReturnType<typeof useGetWorkItemAndChildrenLazyQuery>;
export type GetWorkItemAndChildrenQueryResult = Apollo.QueryResult<GetWorkItemAndChildrenQuery, GetWorkItemAndChildrenQueryVariables>;
export const GetWorkItemDescendantCountDocument = gql`
    query GetWorkItemDescendantCount($workItemId: Float!) {
  workItemDescendantCount(workItemId: $workItemId)
}
    `;

/**
 * __useGetWorkItemDescendantCountQuery__
 *
 * To run a query within a React component, call `useGetWorkItemDescendantCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkItemDescendantCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkItemDescendantCountQuery({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useGetWorkItemDescendantCountQuery(baseOptions: Apollo.QueryHookOptions<GetWorkItemDescendantCountQuery, GetWorkItemDescendantCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkItemDescendantCountQuery, GetWorkItemDescendantCountQueryVariables>(GetWorkItemDescendantCountDocument, options);
      }
export function useGetWorkItemDescendantCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkItemDescendantCountQuery, GetWorkItemDescendantCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkItemDescendantCountQuery, GetWorkItemDescendantCountQueryVariables>(GetWorkItemDescendantCountDocument, options);
        }
export type GetWorkItemDescendantCountQueryHookResult = ReturnType<typeof useGetWorkItemDescendantCountQuery>;
export type GetWorkItemDescendantCountLazyQueryHookResult = ReturnType<typeof useGetWorkItemDescendantCountLazyQuery>;
export type GetWorkItemDescendantCountQueryResult = Apollo.QueryResult<GetWorkItemDescendantCountQuery, GetWorkItemDescendantCountQueryVariables>;
export const NewWorkItemDocument = gql`
    subscription NewWorkItem($teamId: Float!) {
  newWorkItem(teamId: $teamId) {
    workItem {
      name
      workItemId
    }
  }
}
    `;

/**
 * __useNewWorkItemSubscription__
 *
 * To run a query within a React component, call `useNewWorkItemSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewWorkItemSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewWorkItemSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useNewWorkItemSubscription(baseOptions: Apollo.SubscriptionHookOptions<NewWorkItemSubscription, NewWorkItemSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewWorkItemSubscription, NewWorkItemSubscriptionVariables>(NewWorkItemDocument, options);
      }
export type NewWorkItemSubscriptionHookResult = ReturnType<typeof useNewWorkItemSubscription>;
export type NewWorkItemSubscriptionResult = Apollo.SubscriptionResult<NewWorkItemSubscription>;
export const UpdateWorkItemSubscriptionDocument = gql`
    subscription UpdateWorkItemSubscription($teamId: Float!) {
  updateWorkItemSubscription(teamId: $teamId) {
    workItem {
      workItemId
      name
    }
  }
}
    `;

/**
 * __useUpdateWorkItemSubscription__
 *
 * To run a query within a React component, call `useUpdateWorkItemSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkItemSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateWorkItemSubscription({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useUpdateWorkItemSubscription(baseOptions: Apollo.SubscriptionHookOptions<UpdateWorkItemSubscription, UpdateWorkItemSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UpdateWorkItemSubscription, UpdateWorkItemSubscriptionVariables>(UpdateWorkItemSubscriptionDocument, options);
      }
export type UpdateWorkItemSubscriptionHookResult = ReturnType<typeof useUpdateWorkItemSubscription>;
export type UpdateWorkItemSubscriptionSubscriptionResult = Apollo.SubscriptionResult<UpdateWorkItemSubscription>;