import { useContext, useState } from 'react';

import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { defaultBorder } from 'assets/themes/base';
import AppContext from 'components/providers/App';
import AssigneeAvatar from '../assignee/AssigneeAvatar';

interface Props {
    value?: string;
    defaultText?: string;
    assigneeAvatar?: string;
    className?: string;
    variant?: 'chip' | 'text' | 'assigneeChip';
    typographyVariant?: 'body2' | 'h4';
    typographyComponent?: 'h2' | 'h3';
    icon?: JSX.Element;
    deleteIcon?: JSX.Element;
    onClick?: () => void;
    disabled?: boolean;
}

interface ChipProps {
    deleteIcon?: JSX.Element;
    showIcon: boolean;
    disabled: boolean;
    avatar?: JSX.Element;
    onClick?: () => void;
}

const getChipProps = ({ deleteIcon, showIcon, disabled, onClick, avatar = undefined }: ChipProps) => {
    const editIcon = showIcon && !deleteIcon ? <Edit fontSize="small" /> : undefined;
    return {
        deleteIcon,
        onDelete: deleteIcon && !disabled ? onClick : undefined,
        avatar: avatar || editIcon,
    };
};

export default ({
    value,
    defaultText,
    assigneeAvatar,
    icon,
    deleteIcon = undefined,
    onClick,
    className = '',
    variant = 'text',
    typographyVariant = 'body2',
    typographyComponent = 'h2',
    disabled = false,
}: Props): JSX.Element => {
    const { unavailable } = useContext(AppContext);
    const [showIcon, setShowIcon] = useState(false);

    let label = value && value.length > 0 ? value : defaultText || unavailable;

    if (label === unavailable && showIcon === true) {
        label = '';
    }

    const onMouseEnter = () => (disabled ? null : setShowIcon(true));
    const onMouseLeave = () => (disabled ? null : setShowIcon(false));

    // Need this inside the component so the disabled state is synced as we switch subtasks in the sidebar
    const useStyles = makeStyles((theme) => ({
        typography: (props: { disabled?: boolean }) => ({
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-line',
            cursor: props.disabled ? 'not-allowed' : undefined,
            '&:hover': {
                backgroundColor: props?.disabled ? undefined : theme.palette.background.paper,
            },
        }),
        chip: (props: { disabled?: boolean }) => ({
            border: props?.disabled ? `1px solid transparent` : defaultBorder(theme),
        }),
        iconContainer: {
            height: '2em',
            minWidth: '2em',
        },
    }));

    const classes = useStyles({ disabled });

    if (variant === 'chip') {
        return (
            <Chip
                variant="outlined"
                label={label}
                icon={icon}
                onClick={disabled ? undefined : onClick}
                className={`${classes.chip} ${classes.typography} ${className}`}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...getChipProps({
                    disabled,
                    showIcon,
                    onClick,
                    deleteIcon,
                })}
            />
        );
    }

    if (variant === 'assigneeChip') {
        return (
            <Chip
                variant="outlined"
                label={label}
                onClick={disabled ? undefined : onClick}
                className={`${classes.chip} ${classes.typography} ${className}`}
                {...getChipProps({
                    disabled,
                    avatar: <AssigneeAvatar assigneeName={label} assigneeAvatar={assigneeAvatar} />,
                    showIcon,
                    onClick,
                    deleteIcon,
                })}
            />
        );
    }

    return (
        <Grid
            container
            alignItems="center"
            alignContent="center"
            className={`${classes.typography} ${className}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            spacing={1}
            wrap="nowrap"
            onClick={disabled ? undefined : onClick}
        >
            <Grid item xs className={classes.iconContainer}>
                {showIcon && <Edit fontSize="small" />}
            </Grid>
            <Grid item xs={12}>
                <Typography color="textPrimary" variant={typographyVariant} component={typographyComponent}>
                    {label}
                </Typography>
            </Grid>
        </Grid>
    );
};
