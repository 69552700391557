import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateTimePicker from 'components/dateTimePicker/DateTimePicker';
import useHandleWorkItemChanges, { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import { ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { editPlannedItem } from 'store/actions/WorkItems';
import { WorkItem } from 'typings';
import { SelectSubComponentProps } from '../selectInterfaces';

const DeadlineSelectForm = ({ workItem, setShowForm, isSubtask = false }: SelectSubComponentProps): ReactElement => {
    const id = `due-date-select-${workItem.workItemId}`;
    const dispatch = useDispatch();
    const { control, errors, handleSubmit, getValues } = useForm();

    const onUpdateWorkItem: UpdateWorkItemHandler = (updatedWorkItem) => {
        dispatch(editPlannedItem(updatedWorkItem as WorkItem, updatedWorkItem.parentWorkItemId as number | undefined));
        setShowForm(false);
    };

    const { editingField, setEditingField, onSubmit, onError, handleSelectOnChange } = useHandleWorkItemChanges({
        workItem,
        onUpdateWorkItem,
        getValues,
    });

    useEffect(() => {
        if (editingField === null && setEditingField) {
            setEditingField('dueDate');
        }
    }, [editingField, setEditingField]);

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <label htmlFor={id}>
                <Controller
                    control={control}
                    render={({ onChange, value }) => (
                        <DateTimePicker
                            testId="edit-due-date"
                            id={id}
                            value={value}
                            onChange={(date: MaterialUiPickersDate) => {
                                onChange(date);
                                handleSelectOnChange();
                            }}
                            onClose={() => setShowForm(false)}
                            error={errors.dueDate}
                            open={editingField === 'dueDate'}
                        />
                    )}
                    name="dueDate"
                    defaultValue={workItem.dueDate || null}
                    testId="work-due-date"
                    rules={{ required: true, minLength: 1 }}
                />
            </label>
        </form>
    );
};

export default DeadlineSelectForm;
