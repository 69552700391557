import { UserState } from '../reducers/User';

export const SET_USER_STATE = 'SET_USER_STATE';
export const GET_USER_STATE = 'GET_USER_STATE';

interface User {
    type: string;
    payload: UserState;
}

export const setUserState = (payload: UserState): User => ({
    type: SET_USER_STATE,
    payload,
});
