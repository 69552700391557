import { User } from 'typings/generated';
import { GET_USER_STATE, SET_USER_STATE } from '../actions/User';

export interface UserState extends User {
    username?: string;
}

const initialState = {
    id: null,
    userTeams: null,
};

interface Request {
    type: string;
    payload: any;
}

export default (prevState: UserState, { type, payload }: Request): UserState => {
    const state = prevState ?? initialState;
    switch (type) {
        case GET_USER_STATE: {
            return { ...payload.data, ...state };
        }
        case SET_USER_STATE: {
            return { ...state, ...(payload as UserState) };
        }
        default:
            return state;
    }
};
