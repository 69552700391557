import { Button, Dialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';

import { Attachment as GeneratedAttachmentType } from 'typings/generated';
import { Attachment } from '../../typings/attachment';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold',
    },
    nameSection: {
        paddingTop: '16px',
        paddingBottom: '24px',
    },
    errorColor: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

interface Props {
    attachment: Attachment | Partial<GeneratedAttachmentType> | null;
    onConfirm: () => void;
    onCancel: () => void;
}

export default ({ attachment, onCancel, onConfirm }: Props): JSX.Element => {
    const classes = useStyles();
    return (
        <Dialog open={attachment !== null}>
            <DialogContent>
                <Typography variant="body1" className={classes.bold}>
                    You are about to delete this attachment:
                </Typography>
                <Typography variant="body1" className={classes.nameSection}>
                    {attachment?.fileName}
                </Typography>
                <Typography variant="body1" className={classes.bold}>
                    Are you sure you want to proceed?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="primary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" className={classes.errorColor} onClick={onConfirm}>
                    Delete This Attachment
                </Button>
            </DialogActions>
        </Dialog>
    );
};
