export default (rawDate: string | null | undefined): string | undefined => {
    let formattedDate;
    if (rawDate) {
        const months = {
            0: 'JAN',
            1: 'FEB',
            2: 'MAR',
            3: 'APR',
            4: 'MAY',
            5: 'JUN',
            6: 'JUL',
            7: 'AUG',
            8: 'SEP',
            9: 'OCT',
            10: 'NOV',
            11: 'DEC',
        };
        const formatDate = new Date(rawDate);
        const monthName = (months as any)[formatDate.getUTCMonth()];
        const twoDigits: any = (rawDigits: number) => {
            return rawDigits < 10 ? `0${rawDigits}` : rawDigits;
        };

        formattedDate = `${twoDigits(formatDate.getDate())} ${monthName} ${formatDate.getFullYear()} ${twoDigits(
            formatDate.getHours()
        )}${twoDigits(formatDate.getMinutes())}`;
    }
    return formattedDate;
};
