import { Action } from 'store';
import { Team } from '../../typings/generated';

interface ExpandAllAction {
    type: string;
    payload: { expandAllWorkItems: boolean };
}

export const SET_TEAMS = 'SET_TEAMS';
export const SET_SELECTED_ORG_TEAMS = 'SET_SELECTED_ORG_TEAMS';
export const SET_SELECTED_TEAM = 'SET_SELECTED_TEAM';
export const EXPAND_ALL_WORK_ITEMS = 'EXPAND_ALL_WORK_ITEMS';

export const setTeams = (teams: Team[]): Action => ({
    type: SET_TEAMS,
    payload: { teams },
});

export const setSelectedTeam = (team: Team): Action => ({
    type: SET_SELECTED_TEAM,
    payload: { team },
});

export const expandAllWorkItemsAction = (expandAll: boolean): ExpandAllAction => ({
    type: EXPAND_ALL_WORK_ITEMS,
    payload: { expandAllWorkItems: expandAll },
});
