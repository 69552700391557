import { ReactElement } from 'react';

import { makeStyles } from '@material-ui/styles';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    ariaLabelPrefix: string;
    label: string;
}

interface A11yReturnType {
    id: string;
    'aria-controls': string;
    'aria-label': string;
}

const useStyles = makeStyles(() => ({
    height: {
        height: '100%',
    },
}));
// allyProps must match ariaLabelPrefix from from its respective TabPanel
export const a11yProps = (index: number, tabPanelAriaLabelPrefix: string, label: string): A11yReturnType => {
    return {
        id: `${tabPanelAriaLabelPrefix}-tab-${index}`,
        'aria-controls': `${tabPanelAriaLabelPrefix}-tabpanel-${index}`,
        'aria-label': `Navigate to ${label}`,
    };
};

export default function TabPanel(props: TabPanelProps): ReactElement {
    const { children, value, index, ariaLabelPrefix, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${ariaLabelPrefix}-tabpanel-${index}`}
            aria-labelledby={`${ariaLabelPrefix}-tab-${index}`}
            className={classes.height}
            {...other}
        >
            {value === index ? children : null}
        </div>
    );
}
