import { ReactElement } from 'react';

import { KeyboardArrowDown } from '@material-ui/icons';

import { FormValueTypography } from 'components/formUtils';
import useIsATeamMember from 'hooks/useIsATeamMember';
import { PrioritySelectSubComponentProps } from '../selectInterfaces';

const PrioritySelectView = ({
    workItem,
    disabled,
    priorities,
    setShowForm,
    isSubtask = false,
}: PrioritySelectSubComponentProps): ReactElement => {
    let disableForm = disabled;
    const userIsATeamMember = useIsATeamMember({ belongsToTeamId: workItem?.team?.teamId });
    if (!userIsATeamMember) {
        disableForm = true;
    }

    return (
        <FormValueTypography
            deleteIcon={<KeyboardArrowDown />}
            disabled={disableForm}
            value={priorities.find((priority) => priority === workItem.urgencyScale)}
            variant="chip"
            onClick={() => setShowForm(true)}
        />
    );
};

export default PrioritySelectView;
