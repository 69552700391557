import { ReactElement } from 'react';

import {
    CircularProgress,
    CircularProgressProps as MuiCircularProgressProps,
    createStyles,
    Grid,
    LinearProgress,
    LinearProgressProps as MuiLinearProgressProps,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';

import useGetCompletedStatuses from 'hooks/useGetCompletedStatuses';
import { WorkItem } from '../../typings/generated';

type Props =
    | {
          shape: 'circular';
          CircularProgressProps?: Omit<MuiCircularProgressProps, 'value'>;
          LinearProgressProps?: never;
      }
    | {
          shape: 'linear';
          LinearProgressProps?: Omit<MuiLinearProgressProps, 'value'>;
          CircularProgressProps?: never;
      };

type ProgressBarProps = Props & {
    workItem: WorkItem;
    ariaLabel: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circularProgressContainer: {
            position: 'relative',
            display: 'flex',
            alignContent: 'center',
        },
        bottom: {
            color: theme.palette.grey[400],
        },
        top: {
            position: 'absolute',
            left: 0,
        },
    })
);

const ProgressBar = ({
    shape,
    workItem,
    CircularProgressProps,
    LinearProgressProps,
    ariaLabel,
}: ProgressBarProps): ReactElement => {
    const classes = useStyles({ currentItem: workItem });
    const completedStatuses = useGetCompletedStatuses();

    const childrenNotDeleted =
        workItem.children && workItem.children.length > 0
            ? [...workItem.children].filter((child) => !child.isRemoved)
            : [];

    const getNumberOfCompletedTasks = () => {
        const completedStatusIds = completedStatuses.map((status) => status.statusId);
        return childrenNotDeleted.filter((subtask) => completedStatusIds.includes(subtask.statusId)).length;
    };

    const getProgressPercentage = () => {
        const numberOfCompletedTasks = getNumberOfCompletedTasks();
        if (numberOfCompletedTasks && Array.isArray(childrenNotDeleted) && childrenNotDeleted.length > 0) {
            return (numberOfCompletedTasks / childrenNotDeleted.length) * 100;
        }
        return 0;
    };

    const fractionLabel = `${getNumberOfCompletedTasks()} / ${childrenNotDeleted?.length || 0}`;
    const progressPercentage = getProgressPercentage();

    return (
        <>
            {shape === 'circular' ? (
                <Grid container justify="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                        <div className={classes.circularProgressContainer}>
                            <CircularProgress {...CircularProgressProps} value={100} className={classes.bottom} />
                            <CircularProgress
                                {...CircularProgressProps}
                                className={classes.top}
                                color={CircularProgressProps?.color || 'secondary'}
                                value={progressPercentage}
                                aria-label={ariaLabel}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" component="div">
                            {fractionLabel}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography>{fractionLabel}</Typography>
                    </Grid>
                    <Grid item>
                        <LinearProgress
                            {...LinearProgressProps}
                            color={LinearProgressProps?.color || 'secondary'}
                            value={progressPercentage}
                            aria-label={ariaLabel}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ProgressBar;
