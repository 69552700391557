import { ReactElement, useState } from 'react';

import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmModal from 'components/modal/ConfirmModal';
import { updateView } from 'store/actions/View';
import { Store } from 'store/reducers';
import { FilterWorkItemInput, useUpdateViewMutation } from 'typings/generated';

const useStyles = makeStyles((theme: Theme) => ({
    confirmModal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: theme.spacing(58),
        },
    },
}));

const SaveView = (): ReactElement => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { views } = useSelector((state: Store) => state.View);
    const { view: selectedView } = useSelector((state: Store) => state.SelectedView);
    const view = views.find((v) => v.viewId === selectedView?.viewId);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const maxModalHeight = 300;
    const [executeUpdateView] = useUpdateViewMutation({
        onCompleted: (data) => dispatch(updateView(data.updateView, false)),
    });

    const openModal = () => {
        setModalIsOpen(true);
    };

    const onModalCancel = () => {
        setModalIsOpen(false);
    };

    const onConfirm = () => {
        executeUpdateView({
            variables: {
                viewId: view?.viewId as number,
                data: {
                    groupBy: view?.groupBy,
                    filter: JSON.parse(view?.filters || '{}') as FilterWorkItemInput,
                },
            },
        });
        setModalIsOpen(false);
    };

    return (
        <>
            <Button color="primary" variant="contained" onClick={openModal} disabled={!view || !view.isDirty}>
                Save View
            </Button>
            <ConfirmModal
                className={classes.confirmModal}
                isOpen={modalIsOpen}
                title="Save View"
                content={
                    <Grid container>
                        <Grid item xs>
                            <Typography>
                                Are you sure you want to overwrite the settings on the <strong>{view?.name}</strong>{' '}
                                view?
                            </Typography>
                        </Grid>
                    </Grid>
                }
                confirmColor="primary"
                confirmText="Confirm"
                showActions
                confirmVariant="contained"
                onConfirm={onConfirm}
                onCancel={onModalCancel}
                maxHeight={maxModalHeight}
            />
        </>
    );
};

export default SaveView;
