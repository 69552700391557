import update from 'immutability-helper';
import { has } from 'lodash-es';

import { getComparator, stableSort } from 'utils/sortBy';
import { View } from '../../typings/generated';
import { ADD_VIEW, SET_VIEWS, UPDATE_VIEW } from '../actions/View';
import { Action } from '../index';

export interface ExtendedView extends View {
    isDirty?: boolean;
}
export interface State {
    views: ExtendedView[];
}

const initialState: State = {
    views: [],
};

interface Payload {
    views: View[];
    view: Partial<View>;
    isDirty?: boolean;
}

interface ViewAction extends Omit<Action, 'payload'> {
    payload: Payload;
}

export default (prevState: State, action: ViewAction): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;
    switch (type) {
        case ADD_VIEW: {
            return { ...state, views: [...state.views, { ...payload.view, isDirty: false } as View] };
        }
        case SET_VIEWS: {
            return { ...state, views: stableSort(payload.views, getComparator('asc', 'viewId')) };
        }
        case UPDATE_VIEW: {
            const index = state.views.findIndex((view) => view.viewId === payload.view.viewId);
            return {
                ...state,
                views: update(state.views, {
                    [index]: {
                        $set: {
                            ...state.views[index],
                            ...payload.view,
                            isDirty: has(payload, 'isDirty') ? payload.isDirty : state.views[index].isDirty,
                        },
                    },
                }),
            };
        }
        default:
            return { ...state };
    }
};
