import { createMuiTheme } from '@material-ui/core/styles';

import base, { PrioritizeTheme } from './base';

const primary = '#B65405';
const secondary = '#67C88F';
const tertiary = '#C0C3C3';
const defaultBackground = '#fff';
const error = '#A8000E';

const colors: PrioritizeTheme = {
    primary: {
        main: primary,
        dark: '#AE4E01',
    },
    secondary: {
        main: secondary,
    },
    tertiary: {
        main: tertiary,
    },
    tasker: {
        main: '#404040',
    },
    appBar: {
        background: '#004d40',
        tabIndicator: defaultBackground,
    },
    card: {
        main: defaultBackground,
    },
    error: {
        main: error,
    },
    background: {
        default: defaultBackground,
        paper: '#F4F8F8',
    },
    divider: tertiary,
};

const light = createMuiTheme({
    ...base,
    palette: {
        type: 'light',
        primary: colors.primary,
        secondary: colors.secondary,
        tertiary: colors.tertiary,
        tasker: colors.tasker,
        appBar: colors.appBar,
        divider: colors.divider,
        card: colors.card,
        background: colors.background,
        error: colors.error,
    },
    overrides: {
        ...base.overrides,
        MuiFormHelperText: {
            root: {
                color: error,
            },
        },
    },
});

export default light;
