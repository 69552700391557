import { ReactElement, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, ClickAwayListener, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { GridColDef, GridRowParams, XGrid } from '@material-ui/x-grid';
import { sortedUniqBy, uniq } from 'lodash-es';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { CREATE_WORK_ITEM } from 'api/mutations/workItem';
import CircleCount from 'components/circleCount/CircleCount';
import { openSnackbar } from 'store/actions/Snackbar';
import { addItemToPlanned } from 'store/actions/WorkItems';
import { Store } from 'store/reducers';
import { Maybe, Scalars, UserTeam, WorkItemUser } from 'typings/generated';
import quickAddUtility, { groupFilters } from 'utils/quickAddUtility';
import { RowData } from './List';

const useStyles = makeStyles((theme) => ({
    groupWrapper: {
        width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    group: {
        overflowX: 'hidden',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: 'auto',
        '& .MuiGrid-root': {
            width: 'inherit',
        },
        '& .MuiDataGrid-viewport': {
            border: '1px solid #dadada',
            borderBottomLeftRadius: 0,
            borderRadius: 3,
        },
        '& .MuiDataGrid-columnsContainer': {
            color: '#3d4141',
        },
        '& .MuiDataGrid-row': {
            borderTop: '1px solid #dadada',
            borderBottom: '1px solid #dadada',
        },
        '& .MuiDataGrid-row:first-child': {
            borderTop: 'none',
        },
    },
    immutable: {
        backgroundColor: '#0000000a',
    },
    mutable: {
        backgroundColor: '#ffffff',
    },
    workGroupHeader: {
        marginLeft: theme.spacing(2),
    },
    dataGridContainer: {
        marginLeft: theme.spacing(2),
    },
    addWorkItem: {
        backgroundColor: '#ffffff',
        borderLeft: '1px solid #dadada',
        borderRight: '1px solid #dadada',
        borderBottom: '1px solid #dadada',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        height: '52px',
        '& .MuiButton-root': {
            justifyContent: 'flex-start',
            paddingLeft: theme.spacing(2),
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            height: '100%',
        },
        '& .MuiFormControl-root': {
            height: '100%',
        },
    },
    circleCount: {
        paddingLeft: theme.spacing(2),
    },
}));

export interface Props {
    rows: RowData[];
    columns: GridColDef[];
    groupBy: Maybe<string> | undefined;
}

const formatAssignee = (workItemUser: WorkItemUser[] | undefined): string => {
    if (
        Array.isArray(workItemUser) &&
        workItemUser.length > 0 &&
        workItemUser[0].user !== undefined &&
        workItemUser[0].user !== null
    ) {
        return `${workItemUser[0].user.firstName} ${workItemUser[0].user.lastName}`;
    }
    return 'Unassigned';
};

const formatDate = (dueDate: Scalars['DateTime']): string => {
    const dateFormat = 'DD MMM YYYY HHmm';
    let momentDate = moment(dueDate).format(`${dateFormat}`);

    if (momentDate === 'Invalid date') {
        momentDate = 'No Deadline';
        return momentDate;
    }
    return momentDate;
};

const formatPriority = (priority: string | null | undefined): string => {
    let newPriority: string;
    if (priority === null || priority === undefined || priority === '') {
        newPriority = 'No Priority';
    } else {
        newPriority = priority;
    }
    return newPriority;
};

const ListGroup = ({ rows, columns, groupBy }: Props): ReactElement => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { theme } = useSelector((state: Store) => state.MuiTheme);
    const { userTeams } = useSelector((state: Store) => state.User);
    const { selectedTeam, teams } = useSelector((state: Store) => state.Team);
    const { statuses } = useSelector((state: Store) => state.Status);
    const { tags } = useSelector((state: Store) => state.Tag);
    const [groupByValue, setGroupByValue] = useState<string | undefined>(undefined);
    const [inputValue, setInputValue] = useState<string | undefined>(undefined);
    const teamNames = userTeams.map(({ team }: UserTeam) => {
        return team.name;
    });
    const immutableRows = rows.filter((row) => !teamNames.includes(row.division)).map((row) => row.id);
    const [createWorkItem] = useMutation(CREATE_WORK_ITEM, {
        onCompleted: (data) => {
            dispatch(openSnackbar({ message: 'The work item was created!' }));
            dispatch(addItemToPlanned(data.createWorkItem));
            setInputValue(undefined);
        },
    });

    let groups: ReactElement[] | JSX.Element = (
        <Grid container direction="column" className={classes.group} data-testid="list-group-container">
            <Grid item className={classes.workGroupHeader}>
                <Typography>There are no work items to display.</Typography>
            </Grid>
        </Grid>
    );

    const handleAddWorkItemClickAway = () => {
        setGroupByValue(undefined);
    };

    const handleKeyPress = (e: any) => {
        if (e.code === 'Escape') {
            handleAddWorkItemClickAway();
            setInputValue(undefined);
        }
        if (e.code === 'Enter') {
            if (!inputValue) {
                dispatch(openSnackbar({ message: 'Work Item not created. Please enter a value.' }));
                return;
            }
            const utilityVars = quickAddUtility(
                inputValue,
                groupByValue,
                groupBy,
                statuses,
                selectedTeam?.teamId as number,
                userTeams
            );
            createWorkItem({
                variables: {
                    data: {
                        ...utilityVars.data,
                    },
                    foreignKeys: {
                        ...utilityVars.foreignKeys,
                    },
                },
            });
            setGroupByValue(undefined);
        }
    };

    const handleAddWorkItemClick = (data: string) => {
        return setGroupByValue(data);
    };

    const handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        return setInputValue(event.target.value);
    };

    const AddWorkItem = (data: any): JSX.Element => {
        const addItemButton = (
            <Grid container item xs={2} className={classes.addWorkItem}>
                <Grid item xs>
                    <Button id="add-item" onClick={() => handleAddWorkItemClick(data)} fullWidth>
                        + Add Item
                    </Button>
                </Grid>
            </Grid>
        );
        const editRow = (
            <Grid container item id="edit-container" xs={2} className={classes.addWorkItem}>
                <Grid item xs>
                    <ClickAwayListener onClickAway={handleAddWorkItemClickAway}>
                        <TextField
                            id="name"
                            variant="outlined"
                            color="primary"
                            value={inputValue}
                            onChange={handleInputValueChange}
                            onKeyDown={(e) => handleKeyPress(e)}
                            placeholder="Enter work name"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton id="icon-button" onClick={() => setInputValue(undefined)}>
                                            <ClearIcon id="clear-icon" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            /* axelinter:disable:no-autofocus */
                            autoFocus
                        />
                    </ClickAwayListener>
                </Grid>
            </Grid>
        );
        return groupByValue === data ? editRow : addItemButton;
    };

    switch (groupBy) {
        case groupFilters.DEADLINE: {
            const allDeadlines = rows.map((row) => row.dueDate).sort();
            const uniqueDeadlines = uniq(allDeadlines);
            if (uniqueDeadlines.length > 0) {
                if (uniqueDeadlines.includes(null) === true && uniqueDeadlines[0] !== null) {
                    uniqueDeadlines.splice(uniqueDeadlines.indexOf(null), 1);
                    uniqueDeadlines.unshift(null);
                }
                groups = uniqueDeadlines.map((deadline, index) => {
                    return (
                        <div
                            id="group-container"
                            key={`deadline  ${index}`}
                            data-testid="list-group-container"
                            className={classes.groupWrapper}
                        >
                            <Grid container direction="column" className={classes.group}>
                                <Grid
                                    container
                                    item
                                    xs
                                    wrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    className={classes.workGroupHeader}
                                >
                                    <h2>{formatDate(deadline)}</h2>
                                    <Grid item xs className={classes.circleCount}>
                                        <CircleCount
                                            color="primary"
                                            background={theme.palette.primary.main}
                                            count={rows.filter((row) => row.dueDate === deadline).length}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.dataGridContainer}>
                                    <XGrid
                                        autoHeight
                                        key={`deadline grid ${index}`}
                                        rows={rows.filter((row) => row.dueDate === deadline)}
                                        columns={columns}
                                        disableColumnFilter
                                        getRowClassName={(params: GridRowParams) => {
                                            const rowId = +params.id;
                                            return immutableRows.includes(rowId) ? classes.immutable : classes.mutable;
                                        }}
                                        components={{ Footer: () => AddWorkItem(deadline) }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                });
            }
            break;
        }
        case groupFilters.STATUS: {
            const allStatuses = rows.map((row) => row.status.name);
            const uniqueStatuses = uniq(allStatuses).sort();

            if (uniqueStatuses.length > 0) {
                groups = uniqueStatuses.map((status, index) => {
                    const statusValue = statuses.find((statusItem) => statusItem.name === status);

                    return (
                        <div
                            id="group-container"
                            className={classes.groupWrapper}
                            key={`status ${index}`}
                            data-testid="list-group-container"
                        >
                            <Grid container direction="column" className={classes.group}>
                                <Grid
                                    container
                                    item
                                    xs
                                    wrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    className={classes.workGroupHeader}
                                >
                                    <h2>{status}</h2>
                                    <Grid item xs className={classes.circleCount}>
                                        <CircleCount
                                            color="primary"
                                            background={theme.palette.primary.main}
                                            count={rows.filter((row) => row.status.name === status).length}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.dataGridContainer}>
                                    <XGrid
                                        autoHeight
                                        key={`status grid ${index}`}
                                        rows={rows.filter((row) => row.status.name === status)}
                                        columns={columns}
                                        disableColumnFilter
                                        getRowClassName={(params: GridRowParams) => {
                                            const rowId = +params.id;
                                            return immutableRows.includes(rowId) ? classes.immutable : classes.mutable;
                                        }}
                                        components={{ Footer: () => AddWorkItem(statusValue) }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                });
            }
            break;
        }
        case groupFilters.ASSIGNEE: {
            const allAssignees = rows.map((row) => row.assignees?.[0]).sort();
            const uniqueAssignees = sortedUniqBy(allAssignees, 'userId');
            if (uniqueAssignees.length > 0) {
                groups = uniqueAssignees.map((assignee, index) => {
                    const assigneeHeader = assignee?.user
                        ? `${assignee?.user?.firstName} ${assignee?.user?.lastName}`
                        : 'Unassigned';
                    return (
                        <div
                            id="group-container"
                            className={classes.groupWrapper}
                            key={`assignee ${index}`}
                            data-testid="list-group-container"
                        >
                            <Grid container direction="column" className={classes.group}>
                                <Grid
                                    container
                                    item
                                    xs
                                    direction="row"
                                    alignItems="center"
                                    wrap="nowrap"
                                    className={classes.workGroupHeader}
                                >
                                    <h2>{assigneeHeader}</h2>
                                    <Grid item xs className={classes.circleCount}>
                                        <CircleCount
                                            color="primary"
                                            background={theme.palette.primary.main}
                                            count={
                                                rows.filter(
                                                    (row) =>
                                                        formatAssignee(row.assignees as WorkItemUser[]) ===
                                                        assigneeHeader
                                                ).length
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.dataGridContainer}>
                                    <XGrid
                                        autoHeight
                                        key={`assignee grid ${index}`}
                                        rows={rows.filter(
                                            (row) => formatAssignee(row.assignees as WorkItemUser[]) === assigneeHeader
                                        )}
                                        columns={columns}
                                        disableColumnFilter
                                        getRowClassName={(params: GridRowParams) => {
                                            const rowId = +params.id;
                                            return immutableRows.includes(rowId) ? classes.immutable : classes.mutable;
                                        }}
                                        components={{ Footer: () => AddWorkItem(assignee) }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                });
            }
            break;
        }
        case groupFilters.TAGS: {
            const allWorkItemTags = rows.flatMap((row) => row.tags?.split(', '));
            const uniqueWorkItemTags = uniq(allWorkItemTags.filter((tag) => tag !== undefined)).sort();
            if (uniqueWorkItemTags.length > 0) {
                groups = uniqueWorkItemTags.map((workItemTag, index) => {
                    const tagValue = tags.find((tagItem) => tagItem.name === workItemTag);
                    return (
                        <div
                            id="group-container"
                            className={classes.groupWrapper}
                            key={`workItemTag ${index}`}
                            data-testid="list-group-container"
                        >
                            <Grid container direction="column" className={classes.group}>
                                <Grid
                                    container
                                    item
                                    xs
                                    wrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    className={classes.workGroupHeader}
                                >
                                    <h2>{workItemTag}</h2>
                                    <Grid item xs className={classes.circleCount}>
                                        <CircleCount
                                            color="primary"
                                            background={theme.palette.primary.main}
                                            count={
                                                rows.filter((row) =>
                                                    row.tags?.split(', ').includes(workItemTag as string)
                                                ).length
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.dataGridContainer}>
                                    <XGrid
                                        autoHeight
                                        key={`workItemTag grid ${index}`}
                                        rows={rows.filter((row) =>
                                            row.tags?.split(', ').includes(workItemTag as string)
                                        )}
                                        columns={columns}
                                        disableColumnFilter
                                        getRowClassName={(params: GridRowParams) => {
                                            const rowId = +params.id;
                                            return immutableRows.includes(rowId) ? classes.immutable : classes.mutable;
                                        }}
                                        components={{ Footer: () => AddWorkItem(tagValue) }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                });
            }
            break;
        }
        case groupFilters.PRIORITY: {
            const allPriorityLevels = rows.map((row) => formatPriority(row.urgencyScale));
            const urgencyScale = ['Critical', 'High', 'Normal', 'Low', 'No Priority'];
            const uniquePriorityLevels = uniq(allPriorityLevels).sort((a, b) => {
                return urgencyScale.indexOf(a) - urgencyScale.indexOf(b);
            });
            if (uniquePriorityLevels.length > 0) {
                groups = uniquePriorityLevels.map((priorityLevel, index) => {
                    const priorityHeader = formatPriority(priorityLevel);
                    return (
                        <div
                            id="group-container"
                            className={classes.groupWrapper}
                            key={`priorityLevel ${index}`}
                            data-testid="list-group-container"
                        >
                            <Grid container direction="column" className={classes.group}>
                                <Grid
                                    container
                                    item
                                    xs
                                    wrap="nowrap"
                                    direction="row"
                                    alignItems="center"
                                    className={classes.workGroupHeader}
                                >
                                    <h2>{priorityHeader}</h2>
                                    <Grid item xs className={classes.circleCount}>
                                        <CircleCount
                                            color="primary"
                                            background={theme.palette.primary.main}
                                            count={
                                                rows.filter((row) => formatPriority(row.urgencyScale) === priorityLevel)
                                                    .length
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.dataGridContainer}>
                                    <XGrid
                                        autoHeight
                                        key={`priorityLevel grid ${index}`}
                                        rows={rows.filter((row) => formatPriority(row.urgencyScale) === priorityLevel)}
                                        columns={columns}
                                        disableColumnFilter
                                        getRowClassName={(params: GridRowParams) => {
                                            const rowId = +params.id;
                                            return immutableRows.includes(rowId) ? classes.immutable : classes.mutable;
                                        }}
                                        components={{ Footer: () => AddWorkItem(priorityLevel) }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                });
            }
            break;
        }
        case groupFilters.DIVISION: {
            const allDivisions = rows.map((row) => row.division);
            const uniqueDivisions = uniq(allDivisions).sort();
            if (uniqueDivisions.length > 0) {
                groups = uniqueDivisions.map((division, index) => {
                    const divisionValue = teams.find((teamItem) => teamItem.name === division);
                    return (
                        <div
                            id="group-container"
                            className={classes.groupWrapper}
                            key={`division ${index}`}
                            data-testid="list-group-container"
                        >
                            <Grid container direction="column" className={classes.group}>
                                <Grid
                                    container
                                    item
                                    xs
                                    direction="row"
                                    alignItems="center"
                                    wrap="nowrap"
                                    className={classes.workGroupHeader}
                                >
                                    <h2>{division}</h2>
                                    <Grid item xs className={classes.circleCount}>
                                        <CircleCount
                                            color="primary"
                                            background={theme.palette.primary.main}
                                            count={rows.filter((row) => row.division === division).length}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.dataGridContainer}>
                                    <XGrid
                                        autoHeight
                                        key={`division grid ${index}`}
                                        rows={rows.filter((row) => row.division === division)}
                                        columns={columns}
                                        disableColumnFilter
                                        getRowClassName={(params: GridRowParams) => {
                                            const rowId = +params.id;
                                            return immutableRows.includes(rowId) ? classes.immutable : classes.mutable;
                                        }}
                                        components={{ Footer: () => AddWorkItem(divisionValue) }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    );
                });
            }
            break;
        }
        default: {
            const noGroup = (
                <div
                    id="group-container"
                    className={classes.groupWrapper}
                    key="no grouping"
                    data-testid="list-group-container"
                >
                    <Grid container direction="column" className={classes.group}>
                        <Grid
                            container
                            item
                            xs
                            direction="row"
                            alignItems="center"
                            wrap="nowrap"
                            className={classes.workGroupHeader}
                        >
                            <h2>All Work Items</h2>
                            <Grid item xs className={classes.circleCount}>
                                <CircleCount
                                    color="primary"
                                    background={theme.palette.primary.main}
                                    count={rows.length}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.dataGridContainer}>
                            <XGrid
                                autoHeight
                                key="no grouping grid"
                                rows={rows}
                                columns={columns}
                                disableColumnFilter
                                getRowClassName={(params: GridRowParams) => {
                                    const rowId = +params.id;
                                    return immutableRows.includes(rowId) ? classes.immutable : classes.mutable;
                                }}
                                components={{ Footer: () => AddWorkItem('') }}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
            groups = rows.length > 0 ? [noGroup] : groups;
        }
    }

    return <>{groups}</>;
};

export default ListGroup;
