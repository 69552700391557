import { ThemeProvider } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { Store } from 'store/reducers';

interface Props {
    children: JSX.Element;
}

const Theme = ({ children }: Props): JSX.Element | null => {
    const { theme } = useSelector((store: Store) => store.MuiTheme);
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
