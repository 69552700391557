import { ChangeEvent } from 'react';

import { FormHelperText, Grid, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core';
import { CalendarToday, KeyboardArrowDown } from '@material-ui/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { WorkItem as WorkItemFromTypings } from 'typings';

import { TagAutoComplete } from 'components/formUtils/autoCompleteFields';
import RichTextEditor from 'components/formUtils/RichTextEditor';
import SectionHeader from 'components/formUtils/SectionHeader';
import { routePaths } from 'components/providers/ReactRouter';
import ActionWorkDetails from 'components/workItemDetails/ActionWorkDetails';
import BulkSubtasks from 'components/workItemDetails/bulkSubtasks/BulkSubtasks';
import useIsATeamMember from 'hooks/useIsATeamMember';
import { SetWorkDetailsTab } from 'pages/WorkDetails';
import { Tag, useGetUsersByTeamQuery, WorkItemUser } from 'typings/generated';
import useHandleWorkItemChanges, { UpdateWorkItemHandler } from '../../../../hooks/useHandleWorkItemChanges';
import { Store } from '../../../../store/reducers';
import { WorkItem } from '../../../../typings/generated';
import getFormattedDateString from '../../../../utils/formatDateString';
import helperText from '../../../../utils/helperText';
import { sortByName } from '../../../../utils/sortBy';
import DateTimePicker from '../../../dateTimePicker/DateTimePicker';
import { FormValueTypography, renderValue, SaveAndCancel } from '../../../formUtils';
import FieldTitle from '../../../formUtils/FieldTitle';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        height: '100%',
        '& .MuiFormHelperText-root': {
            color: theme.palette.text.secondary,
        },
    },
    container: {
        padding: theme.spacing(2),
    },
    header: {
        paddingBottom: theme.spacing(2),
    },
    link: {
        textDecoration: 'underline',
    },
    alignTab: {
        marginLeft: '2em',
    },
    actionMenu: {
        alignSelf: 'flex-start',
    },
    nameField: {
        maxWidth: '90%',
    },
    deleteIcon: {
        color: theme.palette.text.primary,
    },
    deleteIconSmall: {
        color: theme.palette.text.primary,
        width: '1.25em',
        height: '1.25em',
        fontSize: '1em',
    },
}));
interface Props {
    workItem: WorkItem;
    onUpdateWorkItem: UpdateWorkItemHandler;
    fieldIsBeingEdited: boolean;
    enableEditingOnOtherFields?: () => void;
    disableEditingOnOtherFields?: () => void;
    useSubtaskView?: boolean;
    setWorkDetailsTab: SetWorkDetailsTab;
    handleSubTaskRoute?: () => void;
}
export const currentAssigneeName = (assigneesArray: WorkItemUser[] | undefined): string => {
    let assigneeName = 'Unassigned';
    if (Array.isArray(assigneesArray) && assigneesArray.length > 0) {
        // We will need a multi select when we support multiple assignees
        // For now just grab the first one.
        assigneeName = `${assigneesArray[0].user?.firstName} ${assigneesArray[0].user?.lastName}`;
    }
    return assigneeName;
};
export const currentAssigneeAvatar = (assigneesArray: WorkItemUser[] | undefined): string | undefined => {
    let assigneeAvatar;
    if (Array.isArray(assigneesArray) && assigneesArray.length > 0) {
        assigneeAvatar = `${assigneesArray[0].user?.avatar}`;
    }
    return assigneeAvatar;
};
export default ({
    workItem,
    onUpdateWorkItem,
    fieldIsBeingEdited,
    handleSubTaskRoute,
    enableEditingOnOtherFields = () => null,
    disableEditingOnOtherFields = () => null,
    useSubtaskView = false,
    setWorkDetailsTab,
}: Props): JSX.Element => {
    const { control, errors, handleSubmit, reset, getValues, formState } = useFormContext();
    const { statuses } = useSelector((state: Store) => state.Status);
    const { tags } = useSelector((state: Store) => state.Tag);
    const classes = useStyles();
    const {
        editingField,
        setEditingField,
        onSubmit,
        onCancel,
        onError,
        handleSelectOnChange,
        handleUpdateTags,
    } = useHandleWorkItemChanges({
        workItem,
        reset,
        onUpdateWorkItem,
        getValues,
        anotherFieldIsBeingEdited: fieldIsBeingEdited,
        enableEditingOnOtherFields,
        disableEditingOnOtherFields,
    });
    const { isDirty } = formState;
    const { workItemId, name, description, dueDate, statusId, specialInstruction, assignees, workItemTags } = workItem;
    const currentTags = workItemTags?.map((workItemTag) => workItemTag.tag.tagId);
    const statusesAlphabetized = sortByName(statuses);
    const isATeamMember = useIsATeamMember({ belongsToTeamId: workItem?.teamId });
    const disableEditing = !isATeamMember;

    const { data: currentTeamMembers } = useGetUsersByTeamQuery({
        skip: !workItem || !workItem?.teamId || !workItem?.teamId === undefined,
        variables: {
            teamId: workItem?.teamId,
        },
    });

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit, onError)}>
            <Grid container spacing={4} className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={12} container spacing={1} alignItems="center">
                        {useSubtaskView && (
                            <Grid item xs={12}>
                                <Link to={`/${routePaths.WORK_ITEM}/${workItem.workItemId}`}>
                                    <Typography variant="body1" className={classes.link}>
                                        View Full Details
                                    </Typography>
                                </Link>
                            </Grid>
                        )}
                        <Grid item container={useSubtaskView && editingField !== 'name'} xs={12}>
                            <label htmlFor="name">
                                <Controller
                                    control={control}
                                    render={({ onChange, value }) =>
                                        renderValue(
                                            editingField === 'name',
                                            <SaveAndCancel saveDisabled={!isDirty} onCancel={onCancel}>
                                                <TextField
                                                    id="name"
                                                    variant="outlined"
                                                    color="primary"
                                                    value={value}
                                                    onChange={onChange}
                                                    fullWidth
                                                    error={errors.name || value?.length > 200}
                                                    helperText={helperText(
                                                        value?.length > 200,
                                                        'Exceeds limit of 200 characters.'
                                                    )}
                                                    inputProps={{ 'data-testid': 'edit-name' }}
                                                />
                                            </SaveAndCancel>,
                                            <FormValueTypography
                                                disabled={disableEditing}
                                                value={value}
                                                typographyVariant="h4"
                                                typographyComponent="h2"
                                                onClick={() => setEditingField('name')}
                                            />
                                        )
                                    }
                                    name="name"
                                    inputProps={{ 'data-testid': 'work-name' }}
                                    defaultValue={name || ''}
                                    rules={{ required: true, minLength: 1, maxLength: 200 }}
                                />
                            </label>
                            {useSubtaskView && editingField !== 'name' && (
                                <Grid>
                                    <ActionWorkDetails
                                        selectedId={workItemId}
                                        workItem={workItem as WorkItemFromTypings}
                                        isSubTask={useSubtaskView}
                                        disabled={disableEditing}
                                        handleSubTaskRoute={handleSubTaskRoute}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className={classes.alignTab} spacing={1} alignItems="center">
                        <Grid item lg={2} xl={1}>
                            <FieldTitle title="Deadline" required />
                        </Grid>
                        <Grid item lg={10} xl={11}>
                            <label htmlFor="dueDate">
                                <Controller
                                    control={control}
                                    render={({ onChange, value }) =>
                                        renderValue(
                                            editingField === 'dueDate',
                                            <DateTimePicker
                                                testId="edit-due-date"
                                                id="dueDate"
                                                value={value}
                                                onClose={onCancel}
                                                okLabel="Save"
                                                onChange={onChange}
                                                onAccept={handleSelectOnChange}
                                                error={errors.dueDate}
                                            />,
                                            <FormValueTypography
                                                deleteIcon={<CalendarToday className={classes.deleteIconSmall} />}
                                                disabled={disableEditing}
                                                value={getFormattedDateString(value)}
                                                variant="chip"
                                                onClick={() => setEditingField('dueDate')}
                                            />
                                        )
                                    }
                                    name="dueDate"
                                    defaultValue={dueDate || ''}
                                    testId="work-due-date"
                                    rules={{ required: true, minLength: 1 }}
                                />
                            </label>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className={classes.alignTab} spacing={1} alignItems="center">
                        <Grid item lg={2} xl={1}>
                            <FieldTitle title="Status" />
                        </Grid>
                        <Grid item lg={10} xl={11}>
                            <label htmlFor="status">
                                <Controller
                                    control={control}
                                    render={({ onChange, value }) =>
                                        renderValue(
                                            editingField === 'statusId',
                                            <Select
                                                id="status"
                                                variant="outlined"
                                                color="primary"
                                                error={errors.statusId}
                                                open={editingField === 'statusId'}
                                                onClose={() => setEditingField(null)}
                                                value={value}
                                                onChange={(event: ChangeEvent<unknown>) => {
                                                    onChange(event);
                                                    handleSelectOnChange();
                                                }}
                                                fullWidth
                                                inputProps={{ 'data-testid': 'edit-statusId' }}
                                            >
                                                {statusesAlphabetized
                                                    .filter((status) => status.name !== 'Pending')
                                                    .map((status) => (
                                                        <MenuItem key={status.statusId} value={status.statusId}>
                                                            {status.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>,
                                            <FormValueTypography
                                                deleteIcon={<KeyboardArrowDown className={classes.deleteIcon} />}
                                                disabled={disableEditing}
                                                value={statuses.find((s) => s.statusId === value)?.name}
                                                variant="chip"
                                                onClick={() => setEditingField('statusId')}
                                            />
                                        )
                                    }
                                    name="statusId"
                                    defaultValue={statusId}
                                    rules={{ required: false }}
                                />
                            </label>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className={classes.alignTab} spacing={1} alignItems="center">
                        <Grid item lg={2} xl={1}>
                            <FieldTitle title="Assignee" />
                        </Grid>
                        <Grid item lg={10} xl={11}>
                            <label htmlFor="assignees">
                                <Controller
                                    control={control}
                                    render={({ onChange, value }) =>
                                        renderValue(
                                            editingField === 'assignees',
                                            <Select
                                                id="assignees"
                                                variant="outlined"
                                                color="primary"
                                                error={errors.assignees}
                                                open={editingField === 'assignees'}
                                                onClose={() => setEditingField(null)}
                                                value={value}
                                                onChange={(event: ChangeEvent<unknown>) => {
                                                    onChange(event);
                                                    handleSelectOnChange();
                                                }}
                                                fullWidth
                                                inputProps={{ 'data-testid': 'edit-assignees' }}
                                            >
                                                <MenuItem key={-1} value="">
                                                    Unassigned
                                                </MenuItem>
                                                {currentTeamMembers?.usersByTeam.map((currentTeamMember) => {
                                                    if (
                                                        `${currentTeamMember.firstName} ${currentTeamMember.lastName}` !==
                                                        'Super Admin'
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={currentTeamMember.id}
                                                                value={currentTeamMember.id}
                                                            >
                                                                {currentTeamMember.firstName}{' '}
                                                                {currentTeamMember.lastName}
                                                            </MenuItem>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Select>,
                                            <FormValueTypography
                                                deleteIcon={<KeyboardArrowDown className={classes.deleteIcon} />}
                                                disabled={disableEditing}
                                                value={currentAssigneeName(value)}
                                                variant="assigneeChip"
                                                onClick={() => setEditingField('assignees')}
                                                assigneeAvatar={currentAssigneeAvatar(value)}
                                            />
                                        )
                                    }
                                    name="assignees"
                                    defaultValue={assignees?.[0]?.userId || ''}
                                    rules={{ required: false }}
                                />
                            </label>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container className={classes.alignTab} spacing={1} alignItems="center">
                        <Grid item lg={2} xl={1}>
                            <FieldTitle title="Tags" />
                        </Grid>
                        <Grid item lg={10} xl={11}>
                            <label htmlFor="tags">
                                <Controller
                                    control={control}
                                    render={({ onChange, value }) =>
                                        renderValue(
                                            editingField === 'workItemTags',
                                            <SaveAndCancel
                                                saveDisabled={!isDirty}
                                                onCancel={onCancel}
                                                onSaveClick={handleUpdateTags}
                                                saveButtonType="button"
                                            >
                                                <TagAutoComplete onChange={onChange} value={value} id="tags" />
                                            </SaveAndCancel>,
                                            <>
                                                {value.length > 0 ? (
                                                    value.map((tag: Tag) => (
                                                        <FormValueTypography
                                                            deleteIcon={
                                                                <KeyboardArrowDown className={classes.deleteIcon} />
                                                            }
                                                            disabled={disableEditing}
                                                            value={tag.name}
                                                            variant="chip"
                                                            onClick={() => setEditingField('workItemTags')}
                                                        />
                                                    ))
                                                ) : (
                                                    <FormValueTypography
                                                        deleteIcon={
                                                            <KeyboardArrowDown className={classes.deleteIcon} />
                                                        }
                                                        disabled={disableEditing}
                                                        value="Enter a tag"
                                                        variant="chip"
                                                        onClick={() => setEditingField('workItemTags')}
                                                    />
                                                )}
                                            </>
                                        )
                                    }
                                    name="tags"
                                    defaultValue={tags.filter((tag) => currentTags?.includes(tag.tagId)) as never[]}
                                    rules={{ required: false }}
                                />
                            </label>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} className={classes.alignTab} justify="flex-end" alignItems="flex-end">
                        <BulkSubtasks
                            workItem={workItem}
                            onClose={() => null}
                            hidden={useSubtaskView}
                            setWorkDetailsTab={setWorkDetailsTab}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12} className={`${classes.alignTab} ${classes.header}`}>
                        <SectionHeader title="Attached Links" />
                        <FormHelperText>Paste in links that you want attached to this work item</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor="specialInstruction">
                            <Controller
                                control={control}
                                render={({ onChange, value }) => {
                                    let newAttachmentsValue = 'No Attached Link(s) defined';
                                    if (value && disableEditing) {
                                        const parsedValue = JSON.parse(value);
                                        newAttachmentsValue =
                                            parsedValue.blocks[0].text.length > 0 ? value : newAttachmentsValue;
                                    }
                                    return !disableEditing
                                        ? renderValue(
                                              editingField === 'specialInstruction',
                                              <SaveAndCancel
                                                  saveDisabled={!isDirty || disableEditing}
                                                  onCancel={onCancel}
                                              >
                                                  <RichTextEditor
                                                      id="specialInstruction"
                                                      defaultValue={value}
                                                      onChange={onChange}
                                                      enableAutoFocus
                                                      controls={['link']}
                                                  />
                                              </SaveAndCancel>,
                                              <RichTextEditor
                                                  id="specialInstruction"
                                                  disabled
                                                  onClick={() => setEditingField('specialInstruction')}
                                                  defaultValue={value}
                                              />
                                          )
                                        : renderValue(
                                              editingField === 'specialInstruction',
                                              <RichTextEditor
                                                  id="specialInstruction"
                                                  disabled
                                                  onClick={() => setEditingField('specialInstruction')}
                                                  defaultValue={newAttachmentsValue}
                                              />,
                                              <RichTextEditor
                                                  id="specialInstruction"
                                                  disabled
                                                  onClick={() => setEditingField('specialInstruction')}
                                                  defaultValue={newAttachmentsValue}
                                              />
                                          );
                                }}
                                name="specialInstruction"
                                defaultValue={specialInstruction || ''}
                                inputProps={{ 'data-testid': 'work-specialInstruction' }}
                                rules={{ required: false }}
                            />
                        </label>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item className={`${classes.alignTab} ${classes.header}`} xs={12}>
                        <SectionHeader title="Description" />
                    </Grid>
                    <Grid item xs={12}>
                        <label htmlFor="description">
                            <Controller
                                control={control}
                                render={({ onChange, value }) => {
                                    let newDescriptionValue = 'No Description defined';
                                    if (value && disableEditing) {
                                        const parsedValue = JSON.parse(value);
                                        newDescriptionValue =
                                            parsedValue.blocks[0].text.length > 0 ? value : newDescriptionValue;
                                    }
                                    return !disableEditing
                                        ? renderValue(
                                              editingField === 'description',
                                              <SaveAndCancel
                                                  saveDisabled={!isDirty || disableEditing}
                                                  onCancel={onCancel}
                                              >
                                                  <RichTextEditor
                                                      id="description"
                                                      defaultValue={value}
                                                      onChange={onChange}
                                                      enableAutoFocus
                                                  />
                                              </SaveAndCancel>,
                                              <RichTextEditor
                                                  id="description"
                                                  disabled
                                                  onClick={() => setEditingField('description')}
                                                  defaultValue={value}
                                              />
                                          )
                                        : renderValue(
                                              editingField === 'description',
                                              <RichTextEditor
                                                  id="description"
                                                  disabled
                                                  onClick={() => setEditingField('description')}
                                                  defaultValue={newDescriptionValue}
                                              />,
                                              <RichTextEditor
                                                  id="description"
                                                  disabled
                                                  onClick={() => setEditingField('description')}
                                                  defaultValue={newDescriptionValue}
                                              />
                                          );
                                }}
                                name="description"
                                defaultValue={description || ''}
                                inputProps={{ 'data-testid': 'work-description' }}
                                rules={{ required: false }}
                            />
                        </label>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
