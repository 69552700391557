import { useCallback } from 'react';

import { Grid, makeStyles, Paper, RootRef, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';

export interface LocalFile extends File {
    url: string;
    isGoogleDriveFile?: boolean;
}

export interface Props {
    onDropFiles: (files: File[]) => void;
}

const useStyles = makeStyles((theme) => ({
    dropZone: {
        backgroundColor: theme.palette.background.paper,
        border: `2px dashed ${theme.palette.divider}`,
        cursor: 'pointer',
        minHeight: '160px',
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    iconContainer: {
        marginBottom: '16px',
    },
    iconColor: {
        opacity: '50%',
    },
    blue: {
        color: theme.palette.primary.main,
    },
    bold: {
        fontWeight: 'bold',
    },
}));

export default ({ onDropFiles }: Props): JSX.Element => {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            onDropFiles(acceptedFiles);
        },
        [onDropFiles]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.jpg, .jpeg, .png, .docx, .xls, .xlsx, .csv, .pdf, .ppt, .pptx',
        onDrop,
    });
    const { ref, ...rootProps } = getRootProps();

    const classes = useStyles();

    return (
        <Grid container spacing={1} direction="row">
            <Grid item xs={12}>
                <RootRef rootRef={ref}>
                    <Paper elevation={0} {...rootProps} className={classes.dropZone}>
                        <input {...getInputProps()} />
                        <Grid container direction="column" justify="center" alignItems="center">
                            <Grid
                                item
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                                className={classes.iconContainer}
                            >
                                <Grid item xs>
                                    <CloudUploadIcon color="inherit" className={classes.iconColor} fontSize="large" />
                                </Grid>
                            </Grid>

                            <Grid item xs>
                                <Typography data-testid="drop-zone" variant="body1" color="textPrimary">
                                    Drag and drop file(s) here
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" color="textPrimary" className={classes.bold}>
                                    OR
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1" color="textPrimary" className={classes.blue}>
                                    Browse Files
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </RootRef>
            </Grid>
        </Grid>
    );
};
