import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Grid,
    IconButton,
    Theme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

export interface Props extends Omit<DialogProps, 'open'> {
    isOpen?: boolean;
    title?: string;
    content: string | JSX.Element;
    cancelText?: string;
    confirmText?: string;
    confirmDisabled?: boolean;
    confirmVariant?: 'text' | 'outlined' | 'contained';
    confirmColor?: 'primary' | 'secondary';
    onCancel?: () => void;
    onConfirm?: () => void;
    maxHeight?: number;
    showActions?: boolean;
    onClear?: () => void;
    showClear?: boolean;
    clearText?: string;
    showTopCornerCancel?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    actionButtonsWithClear: {
        display: 'flex' /* displays flex-items (children) inline */,

        '& button': {
            '&:first-child': {
                marginRight: 'auto',
            },
        },
    },
    actionButtons: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    title: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),

        '& .MuiTypography-root': {
            fontWeight: 600,
        },
    },
    content: (props: { maxHeight: number | undefined }) => ({
        maxHeight: props?.maxHeight || undefined,
    }),
}));

export default ({
    title,
    content,
    onCancel,
    onConfirm,
    onClear,
    isOpen = false,
    cancelText = 'Cancel',
    confirmText = 'Accept',
    confirmDisabled = false,
    confirmColor = 'primary',
    confirmVariant = 'outlined',
    clearText = 'Clear',
    showTopCornerCancel = false,
    showClear = false,
    showActions = true,
    maxHeight = undefined,
    maxWidth = 'md',
    fullWidth = false,
    ...dialogProps
}: Props): JSX.Element => {
    const classes = useStyles({ maxHeight });
    return (
        <Dialog {...dialogProps} open={isOpen} scroll="paper" maxWidth={maxWidth} fullWidth={fullWidth || !!maxHeight}>
            {showTopCornerCancel && (
                <Grid container item xs={12} justify="flex-end">
                    <IconButton aria-label="close" data-testid="close-button" size="small" onClick={onCancel}>
                        <Close />
                    </IconButton>
                </Grid>
            )}
            {title ? <DialogTitle className={classes.title}>{title}</DialogTitle> : null}
            <DialogContent className={classes.content}>
                {typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}
            </DialogContent>
            <DialogActions className={showClear ? classes.actionButtonsWithClear : classes.actionButtons}>
                {showClear && (
                    <>
                        <Button color="primary" onClick={onClear}>
                            {clearText}
                        </Button>
                    </>
                )}
                {showActions && (
                    <>
                        <Button variant="text" color="primary" onClick={onCancel}>
                            {cancelText}
                        </Button>
                        <Button
                            variant={confirmVariant}
                            color={confirmColor}
                            onClick={onConfirm}
                            disabled={confirmDisabled}
                        >
                            {confirmText}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};
