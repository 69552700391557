import { ChangeEvent, ReactElement, ReactNode, useState } from 'react';

import { FetchResult } from '@apollo/client';
import {
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import update from 'immutability-helper';
import { isNumber } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import DateTimePicker from 'components/dateTimePicker/DateTimePicker';
import useIsATeamMember from 'hooks/useIsATeamMember';
import { SetWorkDetailsTab } from 'pages/WorkDetails';
import { addChildren } from 'store/actions/SelectedItem';
import { Store } from 'store/reducers';
import { CreateWorkItemMutation, useCreateWorkItemMutation, WorkItem } from 'typings/generated';
import { sortByName } from 'utils/sortBy';
import getFormattedDateString from '../../../utils/formatDateString';
import getStatusId from '../../createWorkItem/getStatusId';
import ConfirmModal from '../../modal/ConfirmModal';
import SubtasksTable from './SubtasksTable';

interface BulkSubtasksProps {
    onClose: () => void;
    hidden?: boolean;
    setWorkDetailsTab: SetWorkDetailsTab;
    workItem: WorkItem;
}

type SubtaskCreationStatus = 'loading' | 'completed' | 'not started' | 'errored';

const maxHeight = 550;
const minHeight = 520;

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    dismissButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 60,
    },
}));

const BulkSubtasks = ({
    workItem,
    onClose,
    hidden = false,
    setWorkDetailsTab,
}: BulkSubtasksProps): JSX.Element | null => {
    const location = useLocation();
    const [open, setOpen] = useState(!!new URLSearchParams(location.search).get('openSubtaskDialog'));
    const classes = useStyles();
    const dispatch = useDispatch();
    const { teams } = useSelector((state: Store) => state.Team);
    const { statuses } = useSelector((state: Store) => state.Status);
    const [subTasks, setSubtasks] = useState<Array<Partial<WorkItem>>>([]);
    const [createWorkItem] = useCreateWorkItemMutation();
    const [creatingSubtasks, setCreatingSubtasks] = useState<SubtaskCreationStatus>('not started');
    const [selectAll, setSelectAll] = useState(false);
    const [defaultBulkSubtaskDeadline, setDefaultBulkSubtaskDeadline] = useState<string>('');
    const isATeamMember = useIsATeamMember({ belongsToTeamId: workItem.teamId });

    const teamsAlphabetized = sortByName(teams);

    const onOpen = () => setOpen(true);

    const onCancel = () => {
        setOpen(false);
        onClose();
    };

    const onDismiss = () => {
        onCancel();
        setSubtasks([]);
        setSelectAll(false);
        setTimeout(() => {
            /* 
            Need a small delay so you don't see the UI flash
            to the "not started" default view when you are closing the dialog.
            */
            setCreatingSubtasks('not started');
        }, 750);
    };

    const navigateToSubtasksTab = () => {
        // Navigate to Subtasks tab
        setWorkDetailsTab(1);
        onDismiss();
    };

    const onConfirm = async () => {
        setCreatingSubtasks('loading');
        const results: FetchResult<CreateWorkItemMutation>[] = await Promise.all(
            subTasks.map((subTask) => {
                const isRequested = subTask.teamId !== workItem.teamId;
                return createWorkItem({
                    variables: {
                        data: {
                            name: subTask.name as string,
                            isRequested,
                            dueDate: subTask.dueDate || undefined,
                            description: workItem.description,
                            specialInstruction: workItem.specialInstruction,
                        },
                        foreignKeys: {
                            teamId: subTask.teamId as number,
                            itemTypeId: workItem.itemTypeId,
                            statusId: getStatusId(statuses, isRequested, {}),
                            parentWorkItemId: workItem.workItemId,
                        },
                    },
                });
            })
        );
        const createdWorkItems = results.map(({ data }) => data?.createWorkItem);
        dispatch(addChildren(createdWorkItems.filter((item) => !!item) as WorkItem[]));
        setCreatingSubtasks('completed');
        setSubtasks([]);
        setDefaultBulkSubtaskDeadline('');
    };

    const getPreviousDueDate = (teamId: number): string | undefined => {
        const previousSubtask = subTasks.find((subTask) => subTask.teamId === teamId);

        return previousSubtask?.dueDate || undefined;
    };

    const onTeamSelect = (event: ChangeEvent<{ value: unknown }>) => {
        // teams.length should be one less than values.length because we added an option for Select All
        const values = event.target.value as Array<number | string>;
        const selectAllOptionIsChecked = values.includes('selectAll');
        const deselectSelectAll =
            selectAllOptionIsChecked && selectAll && values.filter((value) => isNumber(value)).length < teams.length;
        const selectedTeamIds =
            selectAllOptionIsChecked && deselectSelectAll === false
                ? teams.map((team) => team.teamId)
                : (values as number[]);

        if (!deselectSelectAll && selectAllOptionIsChecked === false && selectAll) {
            setSubtasks([]);
            setSelectAll(false);
        } else {
            if (values.filter((value) => isNumber(value)).length === teams.length) {
                setSelectAll(true);
            } else {
                setSelectAll(selectAllOptionIsChecked && !deselectSelectAll);
            }

            setSubtasks(
                selectedTeamIds
                    .filter((teamId) => isNumber(teamId))
                    .map((teamId) => {
                        const selectedTeam = teams.find((team) => team.teamId === teamId)!;
                        return {
                            name: `${selectedTeam.name} - ${workItem.name}`,
                            team: selectedTeam as WorkItem['team'],
                            teamId,
                            dueDate: getPreviousDueDate(teamId) || defaultBulkSubtaskDeadline || null,
                        };
                    })
            );
        }
    };

    const onClear = () => {
        setSubtasks([]);
        if (selectAll) {
            setSelectAll(false);
        }
        setDefaultBulkSubtaskDeadline('');
    };

    const onRemoveRow = (teamId: number) => {
        setSubtasks((prevState) => prevState.filter((subTask) => subTask.teamId !== teamId));
    };

    const onDefaultDateChange = (newValue: MaterialUiPickersDate) => {
        const newDate = newValue?.format() || '';
        setDefaultBulkSubtaskDeadline(newDate);
        setSubtasks((prevState) =>
            prevState.map((subTask) => {
                return {
                    ...subTask,
                    dueDate: getPreviousDueDate(subTask.teamId as number) || newDate,
                };
            })
        );
    };

    const onDateChange = (newValue: MaterialUiPickersDate, teamId: number) => {
        const rowIndex = subTasks.findIndex((subTask) => subTask.teamId === teamId);

        setSubtasks((prevState) =>
            update(prevState, {
                [rowIndex]: {
                    $merge: {
                        dueDate: newValue?.format(),
                    },
                },
            })
        );
    };

    const renderContent = (): ReactElement => {
        switch (creatingSubtasks) {
            case 'loading': {
                return (
                    <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight }}>
                        <Grid item>
                            <CircularProgress color="secondary" />
                        </Grid>
                    </Grid>
                );
            }
            case 'completed': {
                return (
                    <Grid container direction="column" justify="center" style={{ minHeight }}>
                        <Grid container item direction="column" spacing={2}>
                            <Grid item>
                                <Typography align="center" variant="h5" component="h2">
                                    Subtasks created.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="center" variant="body1">
                                    You can view your Subtasks in the{' '}
                                    <Typography
                                        variant="body1"
                                        className={classes.link}
                                        onClick={navigateToSubtasksTab}
                                        component="span"
                                    >
                                        Subtasks Tab
                                    </Typography>
                                    .
                                </Typography>
                            </Grid>
                            <Grid item className={classes.dismissButtonContainer}>
                                <Button color="primary" variant="contained" onClick={onDismiss}>
                                    Dismiss
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }
            case 'not started':
            default: {
                return (
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography>
                                Quickly create various Subtasks by assigning multiple teams and deadlines.
                            </Typography>
                            <Typography>You can further edit details in the subtask tab.</Typography>
                        </Grid>
                        <Grid item container xs={12} spacing={2} alignItems="center">
                            <Grid item xs={9}>
                                <TextField
                                    select
                                    placeholder="Select on or many Teams"
                                    label="Select Teams &nbsp; &nbsp;"
                                    id="bulk-subtask-team-select"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    SelectProps={{
                                        multiple: true,
                                        value: selectAll
                                            ? [...subTasks.map((subtask) => subtask.teamId), 'selectAll']
                                            : subTasks.map((subtask) => subtask.teamId),
                                        onChange: onTeamSelect,
                                        placeholder: 'Select...',
                                        renderValue: (): ReactNode => {
                                            return subTasks.map((subtask) => subtask?.team?.name).join(', ');
                                        },
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key="selectAll" value="selectAll">
                                        <Grid container spacing={1} direction="row" alignItems="center">
                                            <Grid item>
                                                <Checkbox color="primary" checked={selectAll} />
                                            </Grid>
                                            <Grid item xs>
                                                Select All
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                    {teamsAlphabetized.map((team) => (
                                        <MenuItem key={team.teamId} value={team.teamId}>
                                            <Grid container spacing={1} direction="row" alignItems="center">
                                                <Grid item>
                                                    <Checkbox
                                                        color="primary"
                                                        checked={subTasks.some(
                                                            (subTask) => subTask.teamId === team.teamId
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    {team.name}
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <DateTimePicker
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="dd mmm dddd 0000"
                                    label="Set a Default Deadline &nbsp; &nbsp; &nbsp;"
                                    id="bulk_subtask_default_deadline"
                                    testId="bulk_subtask_default_deadline"
                                    value={getFormattedDateString(defaultBulkSubtaskDeadline) || null}
                                    onChange={onDefaultDateChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <SubtasksTable subtasks={subTasks} onRemoveRow={onRemoveRow} onDateChange={onDateChange} />
                        </Grid>
                    </Grid>
                );
            }
        }
    };

    const showActions = creatingSubtasks !== 'completed' && creatingSubtasks !== 'loading';

    if (hidden) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Button color="primary" startIcon={<Add />} onClick={onOpen} disabled={!isATeamMember}>
                Bulk Subtask Creation
            </Button>
            <ConfirmModal
                maxHeight={maxHeight}
                isOpen={open}
                title={showActions ? 'Bulk Subtask Creation' : ''}
                content={renderContent()}
                confirmColor="primary"
                confirmText="Save"
                showActions={showActions}
                confirmVariant="contained"
                onConfirm={onConfirm}
                onCancel={onCancel}
                onClear={onClear}
                showClear={showActions}
                showTopCornerCancel={showActions}
            />
        </Grid>
    );
};

export default BulkSubtasks;
