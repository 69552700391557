import { createMuiTheme, lighten, Theme } from '@material-ui/core/styles';
import {
    PaletteColorOptions,
    PaletteOptions as MuiPaletteOptions,
    TypeBackground,
} from '@material-ui/core/styles/createPalette';
import { TMUIRichTextEditorProps } from 'mui-rte';

import type { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import type { ToggleButtonClassKey } from '@material-ui/lab/ToggleButton';
import type { ToggleButtonGroupClassKey } from '@material-ui/lab/ToggleButtonGroup';

const buttonBaseHeight = '40px';
const buttonHeight = '35px;';
interface TypeAppBar {
    background: string;
    tabIndicator: string;
}

declare module '@material-ui/core/styles/overrides' {
    export interface ComponentNameToClassKey {
        MuiToggleButton: ToggleButtonClassKey;
        MuiToggleButtonGroup: ToggleButtonGroupClassKey;
        MuiAutocomplete: AutocompleteClassKey;
        MUIRichTextEditor: TMUIRichTextEditorProps;
    }
}

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        tasker: Palette['primary'];
        tertiary: Palette['primary'];
        appBar: TypeAppBar;
        card: Palette['primary'];
    }
    interface PaletteOptions {
        tasker: PaletteOptions['primary'];
        tertiary: PaletteOptions['primary'];
        appBar: TypeAppBar;
        card: PaletteOptions['primary'];
    }
}

export const getColorPrimaryContrast = (theme: Theme): string => {
    return theme.palette.getContrastText(theme.palette.primary.main);
};

export const getContrastText = (theme: Theme, invert = false): string => {
    let background;
    if (invert) {
        background = theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black;
    } else {
        background = theme.palette.type === 'dark' ? theme.palette.common.black : theme.palette.common.white;
    }

    return theme.palette.getContrastText(background);
};

export const defaultBorder = (
    theme: Theme,
    borderWidth: number | undefined = undefined,
    borderColor: string | undefined = undefined
): string => {
    const width = borderWidth || 1;
    const color = borderColor || theme.palette.divider;
    return `${width}px solid ${color}`;
};

export const customBorder = (color: string): string => {
    return `1px solid ${color}`;
};

export interface PrioritizeTheme extends MuiPaletteOptions {
    primary: PaletteColorOptions;
    secondary: PaletteColorOptions;
    tertiary: PaletteColorOptions;
    tasker: PaletteColorOptions;
    appBar: TypeAppBar;
    card: PaletteColorOptions;
    error: PaletteColorOptions;
    divider?: string;
    background: Partial<TypeBackground>;
}

const primary = '#B65405';
const secondary = '#004d40';
const defaultBackground = '#FFF';
const error = '#A8000E';
const border = '#C0C3C3';
const tertiary = '#232626';

const editorPadding = 20;

const colors: PrioritizeTheme = {
    primary: {
        main: primary,
        dark: '#AE4E01',
    },
    secondary: {
        main: secondary,
    },
    tertiary: {
        main: '#232626',
    },
    tasker: {
        main: secondary,
    },
    appBar: {
        background: secondary,
        tabIndicator: defaultBackground,
    },
    card: {
        main: defaultBackground,
    },
    error: {
        light: '#ED5A5A',
        main: error,
        dark: '#D32F2F',
        contrastText: defaultBackground,
    },
    background: {
        default: defaultBackground,
        paper: '#FFF5E6',
    },
    divider: '#DADEDE',
};

const base = createMuiTheme({
    palette: {
        type: 'light',
        primary: colors.primary,
        secondary: colors.secondary,
        tertiary: colors.tertiary,
        tasker: colors.tasker,
        appBar: colors.appBar,
        card: colors.card,
        background: colors.background,
        error: colors.error,
        divider: colors.divider,
        common: {
            black: '#232626',
            white: '#FFFFFF',
        },
        info: {
            light: '#7CC8F1',
            main: '#34AEEF',
            dark: '#1289C9',
            contrastText: '#FFFFFF',
        },
        success: {
            light: '#44AA9F',
            main: '#008577',
            dark: '#006F63',
            contrastText: '#3D4141',
        },
        warning: {
            light: '#FFB74D',
            main: '#FF9800',
            dark: '#F57C00',
            contrastText: '#3D4141',
        },
        text: {
            primary: '#3D4141',
            secondary: '#838585',
            disabled: '#A5A8A8',
            hint: '#A5A8A8',
        },
        grey: {
            50: '#F8FCFC',
            100: '#F4F8F8',
            200: '#E2E6E6',
            300: '#ECEFF1',
            400: '#C0C3C3',
            500: '#A1A5A5',
            600: '#787B7B',
            700: '#646767',
            800: '#454848',
            900: '#232626',
            A100: '#D5D5D5',
            A200: '#AAAAAA',
            A400: '#575757',
            A700: '#303030',
        },
        action: {
            active: '#838585',
            hover: '#EBEFEF',
            selected: '#E3E8E8',
            disabled: '#BEC1C1',
            disabledBackground: '#DADEDE',
            focus: '#DADEDE',
        },
    },
    typography: {
        button: {
            textTransform: 'capitalize',
        },
    },
    props: {
        MuiSelect: {
            MenuProps: {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            },
        },
    },
    overrides: {
        // Need MuiInputBase for Selects
        MuiInputBase: {
            // Text Area
            multiline: {
                height: 'auto',
                fontSize: '14px',
            },
            // All Inputs
            root: {
                height: buttonBaseHeight,
                fontSize: '14px',
            },
        },
        MuiButtonBase: {
            root: {
                '&$disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'auto',
                    '&:active': {
                        pointerEvents: 'none',
                    },
                },
            },
        },
        MuiButton: {
            root: {
                height: buttonHeight,
            },
        },
        MuiAppBar: {
            root: {
                zIndex: 100,
                padding: 0,
                height: '48px',
            },
        },
        MuiChip: {
            root: {
                maxWidth: 300,
            },
        },
        MuiFormHelperText: {
            root: {
                color: '#A8000E',
            },
        },
        MuiDialog: {
            paper: {
                padding: '1em',
            },
        },
        MuiFormLabel: {
            root: {
                color: 'inherit',
            },
        },
        MuiTab: {
            textColorPrimary: {
                color: '#646767',
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: `1px solid ${colors.divider}`,
            },
        },
        MuiToggleButton: {
            root: {
                borderColor: border,
                padding: '8px',
                color: tertiary,
                minWidth: '120px',
                '&$selected': {
                    border: customBorder(border),
                    backgroundColor: colors.divider,
                    color: tertiary,
                },
            },
        },
        MuiToggleButtonGroup: {
            groupedHorizontal: {
                '&:not(:first-child)': {
                    borderLeft: customBorder(border),
                },
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                '&.MuiOutlinedInput-root': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiLinearProgress: {
            root: {
                height: 10,
                borderRadius: 5,
            },
            colorSecondary: {
                backgroundColor: '#C0C3C3',
            },
        },
        MUIRichTextEditor: {
            // Examples: https://github.com/niuware/mui-rte/blob/master/examples/theme/index.tsx
            root: {
                width: '100%',
            },
            editor: {
                height: '250px',
                maxHeight: '400px',
                overflow: 'auto',
                padding: editorPadding,
            },
            toolbar: {
                padding: 8,
                paddingTop: 0,
            },
            placeHolder: {
                padding: editorPadding,
                width: 'inherit',
            },
        },
    },
});

export default base;
export { buttonBaseHeight };
