import { ReactElement } from 'react';

import { Chip, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { WorkItem } from '../../typings/generated';

const useStyles = makeStyles((theme) => ({
    tagChip: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
}));

export interface Props {
    workItem: WorkItem;
}

const TagChip = ({ workItem }: Props): ReactElement => {
    const classes = useStyles();

    if (workItem.workItemTags && workItem.workItemTags.length > 0) {
        const firstTag = workItem.workItemTags[0].tag.name;
        const moreTagNumber = workItem.workItemTags.length - 1;
        const isMoreTags = moreTagNumber > 0;
        const tagString = workItem.workItemTags.map((workItemTag) => workItemTag.tag.name).join(', ');

        return (
            <Tooltip title={tagString} placement="top-start" arrow aria-label={tagString}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Chip variant="outlined" className={classes.tagChip} label={firstTag} />
                    </Grid>
                    {isMoreTags && (
                        <Grid item>
                            <Chip variant="outlined" className={classes.tagChip} label={`+ ${moreTagNumber}`} />
                        </Grid>
                    )}
                </Grid>
            </Tooltip>
        );
    }
    return <Grid />;
};

export default TagChip;
