import { ReactElement } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Grid,
    makeStyles,
    Typography,
    useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sortBy } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { defaultBorder } from 'assets/themes/base';
import CircleCount from 'components/circleCount/CircleCount';
import ProgressBar from 'components/progressBar/ProgressBar';
import { routePaths } from 'components/providers/ReactRouter';
import { Store } from 'store/reducers';
import { Status, WorkItem } from 'typings/generated';
import CreateSubtaskButton from '../CreateSubtaskButton';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0px 16px',
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: '1em',
    },
    bodyText: {
        fontSize: '0.8em',
    },
    progressBar: {
        color: theme.palette.secondary.main,
    },
    accordionRoot: {
        boxShadow: 'none',
        padding: '0',
        backgroundColor: 'inherit',
    },
    accordionContainer: {
        borderTop: defaultBorder(theme),
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subtaskNameText: {
        fontSize: '1em',
        paddingLeft: '12px',
        overflowWrap: 'anywhere',
        whiteSpace: 'pre-line',
    },
    createSubtaskButton: {
        marginLeft: '16px',
    },
    createSubtaskButtonText: {
        fontSize: '0.875em',
        fontWeight: 'bold',
    },
    subtaskContainer: {
        marginLeft: '24px',
        borderRadius: '8px',
        '&:hover': {
            background: theme.palette.background.default,
            cursor: 'pointer',
        },
    },
    selectedSubtaskContainer: {
        marginLeft: '24px',
        borderRadius: '8px',
        background: theme.palette.background.default,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.black,
    },
}));
interface Props {
    workItem: WorkItem;
    selectedSubtask: WorkItem | null | undefined;
    onClickCreateSubtask: () => void;
    setSelectedSubtask: (subtaskId: number) => void;
}

export default ({ workItem, selectedSubtask, setSelectedSubtask, onClickCreateSubtask }: Props): ReactElement => {
    const statuses = useSelector((state: Store) => state.Status.statuses);
    const teams = useSelector((state: Store) => state.Team.teams);
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    function handleSubtaskClick(clickedSubtaskId: number | undefined) {
        const parentId = workItem?.workItemId;
        if (parentId && clickedSubtaskId) {
            history.push(`/${routePaths.WORK_ITEM}/${parentId}/${clickedSubtaskId}`);
            setSelectedSubtask(clickedSubtaskId);
        }
    }

    return (
        <Grid spacing={4} container direction="column" className={classes.container}>
            <Grid item container direction="column" spacing={1}>
                <Grid item>
                    <Typography className={classes.headerText}>Project Status </Typography>
                </Grid>
                <Grid item>
                    <ProgressBar
                        shape="linear"
                        workItem={workItem}
                        ariaLabel="Subtask Completion Status"
                        LinearProgressProps={{ variant: 'determinate', color: 'secondary' }}
                    />
                </Grid>
            </Grid>
            <Grid item container direction="column">
                {statuses.map((status: Status) => (
                    <Grid
                        key={status.statusId}
                        item
                        xs={12}
                        container
                        direction="column"
                        className={classes.accordionContainer}
                    >
                        <Accordion classes={{ root: classes.accordionRoot }} square defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                data-testid={`status-accordion-${status.statusId}`}
                            >
                                <Grid item container direction="row" justify="center" alignItems="center" wrap="nowrap">
                                    <Grid item container alignItems="center">
                                        <Grid item xs={2}>
                                            <CircleCount
                                                color={theme.palette.common.black}
                                                count={
                                                    workItem.children
                                                        ?.filter(
                                                            (subtask: WorkItem) => subtask.statusId === status.statusId
                                                        )
                                                        .filter((subtask: WorkItem) => !subtask.isRemoved).length
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography className={classes.headerText}>{status.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item container direction="column" spacing={2}>
                                    {sortBy(workItem.children, ['workItemId'])
                                        ?.filter((subtask) => subtask.statusId === status.statusId)
                                        .filter((subtask) => !subtask.isRemoved)
                                        .map((subtask: WorkItem | Partial<WorkItem>) => (
                                            <Grid
                                                key={subtask.workItemId}
                                                item
                                                container
                                                className={
                                                    selectedSubtask?.workItemId === subtask.workItemId
                                                        ? classes.selectedSubtaskContainer
                                                        : classes.subtaskContainer
                                                }
                                                data-testid={`subtask-${subtask.workItemId}`}
                                                onClick={() => {
                                                    handleSubtaskClick(subtask.workItemId);
                                                }}
                                            >
                                                <Grid container item xs={1} alignItems="flex-start">
                                                    <Avatar className={classes.avatar}>
                                                        {teams.find((team) => team.teamId === subtask.teamId)
                                                            ?.name[0] || ''}
                                                    </Avatar>
                                                </Grid>
                                                <Grid container item xs={11} alignItems="center">
                                                    <Typography className={classes.subtaskNameText}>
                                                        {subtask.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ))}
            </Grid>
            <Grid item>
                <CreateSubtaskButton onClickCreateSubtask={onClickCreateSubtask} teamId={workItem.teamId} />
            </Grid>
        </Grid>
    );
};
