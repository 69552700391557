import { useCallback, useState } from 'react';

import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { GridColDef, GridValueGetterParams } from '@material-ui/x-grid';
import { useDispatch, useSelector } from 'react-redux';

import { defaultBorder } from 'assets/themes/base';
import AddWork from 'components/createWorkItem/AddWork';
import FilterBuilder from 'components/filterBuilder/FilterBuilder';
import ProgressBar from 'components/progressBar/ProgressBar';
import AssigneeSelect from 'components/selects/assignee';
import DeadlineSelect from 'components/selects/deadline';
import PrioritySelect from 'components/selects/priority';
import StatusSelect from 'components/selects/status';
import { updateView } from 'store/actions/View';
import { setPlanned } from 'store/actions/WorkItems';
import {
    FilterWorkItemInput,
    useGetWorkItemsCountQuery,
    useGetWorkItemsQuery,
    View,
    WorkItem,
} from 'typings/generated';
import { Store } from '../../store/reducers';
import ListGroup from './ListGroup';
import TagChip from './TagChip';
import TaskTitle from './TaskTitle';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-root .MuiDataGrid-window': {
            border: '1px solid #d7d8d8',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px #d7d8d8',
        },
    },
    listContainer: {
        height: '100%',
    },
    listGroupContainer: {
        paddingBottom: theme.spacing(25),
        justifyContent: 'left',
        overflowX: 'auto',
        height: `calc(100vh - ${theme.spacing(35)}px)`,
    },
    column: {
        border: defaultBorder(theme),
    },
    visualContainer: {
        border: 'none',
        borderLeft: defaultBorder(theme),
        overflowX: 'scroll',
    },
    expandButton: {
        background: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        borderRadius: '0px',
        width: '32px',
        height: '49px',
        '&:hover': {
            background: theme.palette.primary.main,
        },
        border: `1px solid ${theme.palette.primary.main}`,
    },
    iconSmall: {
        fontSize: 'inherit',
    },
    workListHeader: {
        boxShadow: '0 4px 6px -6px #222',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        maxHeight: theme.spacing(18),
    },
    selectBox: {
        minWidth: theme.spacing(20),
        paddingRight: theme.spacing(1),
    },
    viewHeader: {
        fontWeight: 600,
        paddingBottom: theme.spacing(2),
    },
    groupByInputLabel: {
        transform: 'translate(34px, 13px) scale(1)',
        fontSize: '0.875rem',
    },
    button: {
        padding: theme.spacing(1),
    },
    totalsMessage: {
        color: theme.palette.text.secondary,
    },
}));
interface Props {
    view: View;
}

export interface RowData extends WorkItem {
    id: number;
    tags: string | undefined;
    division: string;
}
export const dateFormat = 'DD MMM YYYY HHmm';

export const columns: GridColDef[] = [
    {
        field: 'workName',
        headerName: 'Work Name',
        flex: 2,
        renderCell: (params: GridValueGetterParams): JSX.Element => {
            const { row } = params;
            const workItem = (row as unknown) as WorkItem;
            return (
                <TaskTitle
                    workItem={{ ...workItem, itemType: { name: '', itemTypeId: 0, code: '' } }}
                    isSubtask={false}
                />
            );
        },
    },
    {
        field: 'deadline',
        headerName: 'Deadline',
        type: 'date',
        flex: 1,
        renderCell: (params: GridValueGetterParams): JSX.Element => {
            const { row } = params;
            const workItem = (row as unknown) as RowData;
            return <DeadlineSelect workItem={{ ...workItem, itemType: { name: '', itemTypeId: 0, code: '' } }} />;
        },
    },
    {
        field: 'assignee',
        headerName: 'Assignee',
        flex: 1,
        renderCell: (params: GridValueGetterParams): JSX.Element => {
            const { row } = params;
            const workItem = (row as unknown) as RowData;
            return (
                <AssigneeSelect
                    workItem={{ ...workItem, itemType: { name: '', itemTypeId: 0, code: '' } }}
                    teamId={workItem.teamId}
                />
            );
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params: GridValueGetterParams): JSX.Element => {
            const { row } = params;
            const workItem = (row as unknown) as RowData;
            return <StatusSelect workItem={{ ...workItem, itemType: { name: '', itemTypeId: 0, code: '' } }} />;
        },
    },
    {
        field: 'priority',
        headerName: 'Priority',
        flex: 1,
        renderCell: (params: GridValueGetterParams): JSX.Element => {
            const { row } = params;
            const workItem = (row as unknown) as RowData;
            return <PrioritySelect workItem={{ ...workItem, itemType: { name: '', itemTypeId: 0, code: '' } }} />;
        },
    },
    {
        field: 'tags',
        headerName: 'Tags',
        flex: 1,
        renderCell: (params: GridValueGetterParams): JSX.Element => {
            const { row } = params;
            const workItem = (row as unknown) as WorkItem;
            return <TagChip workItem={{ ...workItem, itemType: { name: '', itemTypeId: 0, code: '' } }} />;
        },
    },
    {
        field: 'subtasksComplete',
        headerName: 'Subtasks Complete',
        flex: 1,
        renderCell: (params: GridValueGetterParams): JSX.Element => {
            const { row } = params;
            const workItem = (row as unknown) as WorkItem;
            const childrenNotDeleted =
                workItem.children && workItem.children.length > 0
                    ? [...workItem.children].filter((child) => !child.isRemoved)
                    : [];

            return childrenNotDeleted && childrenNotDeleted.length > 0 ? (
                <Grid item xs>
                    <ProgressBar
                        shape="circular"
                        workItem={workItem}
                        CircularProgressProps={{
                            variant: 'determinate',
                            color: 'secondary',
                        }}
                        ariaLabel="Subtask Completion Status"
                    />
                </Grid>
            ) : (
                <Grid item xs />
            );
        },
    },
];

export default ({ view }: Props): JSX.Element => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { planned } = useSelector((state: Store) => state.WorkItems);
    const { selectedOrganization } = useSelector((state: Store) => state.Organization);
    const [workItemsCount, setWorkItemsCount] = useState<number>();
    const { groupBy } = view;
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newGroupBy = event.target.value as string;
        dispatch(updateView({ ...view, groupBy: newGroupBy }, true));
    };

    useGetWorkItemsCountQuery({
        fetchPolicy: 'cache-and-network',
        pollInterval: 15000,
        variables: {
            organizationId: selectedOrganization?.organizationId as number,
        },
        onCompleted: (data) => {
            setWorkItemsCount(data.workItemsCount);
        },
    });

    useGetWorkItemsQuery({
        fetchPolicy: 'cache-and-network',
        pollInterval: 15000,
        variables: {
            organizationId: selectedOrganization?.organizationId as number,
            filter: JSON.parse(view.filters) as FilterWorkItemInput,
            skip: 0,
            take: 1000000,
        },
        onCompleted: (data) => {
            dispatch(setPlanned(data.workItems as WorkItem[]));
        },
    });

    const getSingleRow = useCallback((workItem: WorkItem): RowData => {
        const { workItemId, workItemTags, team } = workItem;
        return {
            ...workItem,
            id: workItemId,
            tags: workItemTags?.map((tag) => tag.tag.name).join(', ') || undefined,
            division: team.name,
        };
    }, []);

    const onUpdateFilter = (updatedFilter: FilterWorkItemInput) => {
        dispatch(updateView({ ...view, filter: updatedFilter, filters: JSON.stringify(updatedFilter) } as View, true));
    };

    const rows = planned.map((workItem) => getSingleRow(workItem as WorkItem));

    return (
        <Grid
            className={classes.listContainer}
            container
            direction="column"
            justify="flex-start"
            wrap="nowrap"
            data-testid="list-container"
        >
            <Grid container item xs={12} className={classes.workListHeader}>
                <Grid container item direction="row" xs={12} alignContent="center">
                    <Typography className={classes.viewHeader} variant="h5" component="h2">
                        {view.name}
                    </Typography>
                </Grid>
                <Grid container item xs={12} justify="space-between">
                    <Grid container item xs={6} alignItems="center">
                        <Grid item xs="auto">
                            <FormControl variant="outlined" className={classes.selectBox}>
                                <InputLabel id="select-group-by-label" className={classes.groupByInputLabel}>
                                    Group By
                                </InputLabel>
                                <Select
                                    labelId="select-group-by-label"
                                    id="select-group-by"
                                    value={groupBy}
                                    onChange={handleChange}
                                    label="Group By"
                                >
                                    <MenuItem key="all" value="all">
                                        All Work Items
                                    </MenuItem>
                                    <MenuItem key="deadline" value="deadline">
                                        Deadline
                                    </MenuItem>
                                    <MenuItem key="status" value="status">
                                        Status
                                    </MenuItem>
                                    <MenuItem key="assignee" value="assignee">
                                        Assignee
                                    </MenuItem>
                                    <MenuItem key="priority" value="priority">
                                        Priority
                                    </MenuItem>
                                    <MenuItem key="tags" value="tags">
                                        Tags
                                    </MenuItem>
                                    <MenuItem key="division" value="division">
                                        Division
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs="auto">
                            <FilterBuilder
                                currentFilter={JSON.parse(view.filters) as FilterWorkItemInput}
                                onClearAll={() => null}
                                onUpdate={onUpdateFilter}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item justify="flex-end" xs={6} className={classes.button}>
                        <AddWork />
                    </Grid>
                    <Grid item xs={12} className={classes.totalsMessage}>
                        <Typography variant="body2">
                            Showing {planned.length} of {workItemsCount} work items based on selected filters
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} justify="flex-start" direction="row" className={classes.listGroupContainer}>
                <ListGroup rows={rows} columns={columns} groupBy={groupBy} />
            </Grid>
        </Grid>
    );
};
