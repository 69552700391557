import { ReactElement } from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, ServerError, ServerParseError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { useKeycloak } from '@react-keycloak/web';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError } from 'graphql';
import { useDispatch } from 'react-redux';

import { Organization } from 'typings/organization';
import { getStorageItem } from 'utils/TypeSafeStorage';
import { openSnackbar } from '../../store/actions/Snackbar';

interface Props {
    children: ReactElement;
}

export function graphQLErrorHandler(graphQLErrors: GraphQLError[]): void {
    graphQLErrors.forEach(({ message, locations, path }) => {
        const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;

        console.error(errorMessage); // eslint-disable-line
    });
}

export function networkErrorHandler(networkError: Error | ServerError | ServerParseError): void {
    console.error(`[Network error]: ${networkError}`); // eslint-disable-line
}

export const Providers = ({ children }: Props): ReactElement => {
    const [keycloak] = useKeycloak();
    const dispatch = useDispatch();

    const { NODE_ENV, REACT_APP_PRIORITIZATION_WEB_SOCKETS_URL } = process.env;

    const token = keycloak?.token || null;

    const setHeader = new ApolloLink((operation, forward) => {
        const selectedOrganization = getStorageItem<Organization>('selected-organization');
        operation.setContext(({ headers }: { headers: { [x: string]: string } }) =>
            selectedOrganization
                ? {
                      headers: {
                          organization: selectedOrganization?.organizationId,
                          ...headers,
                      },
                  }
                : { headers }
        );
        return forward(operation);
    });

    const httpLink = ApolloLink.from([
        setHeader,
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) graphQLErrorHandler([...graphQLErrors]);

            if (networkError) networkErrorHandler(networkError);

            dispatch(openSnackbar({ message: 'Something went wrong...' }));
        }),
        // note: this is a termination link, needs to be last:
        createUploadLink({
            uri: '/api',
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        }),
    ]);

    const wsLink = new WebSocketLink({
        uri:
            NODE_ENV === 'production'
                ? `wss://${window.location.host}/api`
                : REACT_APP_PRIORITIZATION_WEB_SOCKETS_URL || '',
        options: {
            reconnect: true,
            lazy: true,
            connectionParams() {
                return {
                    authorization: token ? `Bearer ${token}` : '',
                };
            },
        },
    });

    const getVersionNumber = new ApolloLink((operation, forward) => {
        return forward(operation).map((result) => {
            const { headers } = operation.getContext().response;
            const backendVersion = headers.get('x-version-backend');
            const backendEnvironment = headers.get('x-environment-backend');
            localStorage.setItem('x-version-backend', backendVersion);
            localStorage.setItem('x-environment-backend', backendEnvironment);
            return result;
        });
    });

    const link = ApolloLink.split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        ApolloLink.from([getVersionNumber, httpLink])
    );

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });

    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default Providers;
