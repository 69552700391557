export const sortByName = (inputArray: Record<string, any>[]): Record<string, any>[] => {
    if (typeof inputArray !== 'undefined') {
        const alphabetizedInputArray = [...inputArray];
        alphabetizedInputArray.sort((a, b) => a.name.localeCompare(b.name));
        return alphabetizedInputArray;
    }
    return inputArray;
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): Array<T> {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const sortByWorkBucketName = (inputArray: Record<string, any>[]): Record<string, any>[] => {
    if (typeof inputArray !== 'undefined') {
        const alphabetizedInputArray = [...inputArray];
        alphabetizedInputArray.sort((a, b) => a.workBucket.name.localeCompare(b.workBucket.name));
        return alphabetizedInputArray;
    }
    return inputArray;
};

export const sortStringArray = (inputArray: string[]): string[] => {
    if (typeof inputArray !== 'undefined') {
        const alphabetizedInputArray = [...inputArray];
        alphabetizedInputArray.sort((a, b) => a.localeCompare(b));
        return alphabetizedInputArray;
    }
    return inputArray;
};
