import { ReactElement } from 'react';

import { Checkbox, Chip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';

import { Store } from 'store/reducers';
import { Status } from '../../../typings/generated';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface StatusAutoCompleteProps {
    disableCreation?: boolean;
    onChange: (newValue: Array<string | Partial<Status>>) => void;
    value: Status[];
    id: string;
}

const StatusAutoComplete = ({
    disableCreation,
    onChange,
    id,
    value: statusValue,
}: StatusAutoCompleteProps): ReactElement => {
    const { statuses } = useSelector((state: Store) => state.Status);

    return (
        <Autocomplete
            multiple
            id={id}
            options={statuses.filter((status) => status.name !== 'Unplanned')}
            getOptionLabel={(status) => status.name}
            value={statusValue || undefined}
            onChange={(_event, newValue) => onChange(newValue)}
            freeSolo={!disableCreation}
            renderTags={(value: Status[], getStatusProps) =>
                value.map((option: Status, index: number) => (
                    <Chip variant="outlined" label={option.name} size="small" {...getStatusProps({ index })} />
                ))
            }
            renderOption={(option, { selected }) => (
                <>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        color="primary"
                        checked={selected}
                    />
                    {option.name}
                </>
            )}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Enter a status..." />}
        />
    );
};

export default StatusAutoComplete;
