import App from 'App';
import ReactDOM from 'react-dom';

import Providers from 'components/providers/Providers';
import { saveState, wipeState } from 'store/middlewares/LocalStorage';

const app = (
    <Providers>
        <App />
    </Providers>
);

ReactDOM.render(app, document.getElementById('root'));

if (process.env.NODE_ENV === 'development') {
    // @ts-ignore cache or save the redux Store to local storage
    global.cache = () => {
        try {
            // @ts-ignore
            saveState(store.getState());
            // eslint-disable-next-line no-console
            console.log('State Saved to local storage');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    // @ts-ignore wipe the local storage state
    global.wipe = () => {
        try {
            wipeState();
            // eslint-disable-next-line no-console
            console.log('state removed from local storage');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };
}
