import { ReactElement, useEffect, useState } from 'react';

import { CssBaseline, Grid, makeStyles, Menu, MenuItem, Paper, TextField, Theme, Typography } from '@material-ui/core';
import { trim } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { TabList, TabPanel, Tabs } from 'react-tabs';

import FieldTitle from 'components/formUtils/FieldTitle';
import List from 'components/list/List';
import Onboarding from 'components/onboarding/Onboading';
import { ButtonGroup, CustomTabItem, NewTabItem } from 'components/tabs/ViewTabItems';
import { CLOSE_VIEW, CREATE_VIEW, DELETE_VIEW, RENAME_VIEW } from 'components/tabs/ViewTabUtils';
import 'react-tabs/style/react-tabs.css';
import { setSelectedView } from 'store/actions/SelectedView';
import { addView, updateView } from 'store/actions/View';
import { Store } from 'store/reducers';
import { useCreateViewMutation, useUpdateViewMutation, View } from 'typings/generated';
import { groupFilters } from 'utils/quickAddUtility';
import ConfirmModal from '../components/modal/ConfirmModal';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '100%',
        width: '100%',
        '& .react-tabs': {
            height: '100%',
            width: '100%',
        },
        '& .react-tabs__tab-panel': {
            height: '100%',
            width: '100%',
        },
        '& .react-tabs__tab--selected': {
            backgroundColor: '#f8f8f8',
            borderRadius: '0px',
            border: 'none',
        },
        '& .react-tabs__tab': {
            borderRight: '1px solid #ebebeb',
            minWidth: theme.spacing(10),
            paddingTop: theme.spacing(1),
            height: '100%',
        },
        '& .react-tabs__tab:last-child': {
            border: 'none',
        },
    },
    buttonGroupContainer: {
        alignSelf: 'center',
        paddingRight: theme.spacing(3),
    },
    confirmModal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: theme.spacing(58),
        },
    },
    characterLimit: {
        textAlign: 'end',
    },
    currentViewNameText: {
        fontWeight: 600,
    },
    tabList: {
        backgroundColor: '#ffffff',
        borderBottom: 'none',
        paddingLeft: '0px',
        margin: '0px',
        display: 'flex',
        width: '100%',
    },
}));

function Work(): ReactElement {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { views } = useSelector((state: Store) => state.View);
    const { email } = useSelector((state: Store) => state.User);
    const { view: selectedView } = useSelector((state: Store) => state.SelectedView);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [title, setTitle] = useState('Rename View');
    const [confirmText, setConfirmText] = useState('Confirm');
    const [viewInput, setViewInput] = useState<View['name'] | ''>();
    const [currentOption, setCurrentOption] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [newViewAnchorEl, setNewViewAnchorEl] = useState<null | HTMLElement>(null);
    const [openViews, setOpenViews] = useState(views.filter((view: View) => view.isOpen && !view.isRemoved));
    const openViewIndex = openViews.findIndex((openView) => openView.viewId === selectedView?.viewId);
    const [tabIndex, setTabIndex] = useState(openViewIndex === -1 ? 0 : openViewIndex);
    const closedViews = views.filter((view: View) => !view.isOpen && !view.isRemoved);
    const maxModalHeight = 300;
    const [executeUpdateView] = useUpdateViewMutation();
    const [executeCreateView] = useCreateViewMutation({
        onCompleted: (data) => {
            dispatch(addView(data.createView as View));
            dispatch(setSelectedView(data.createView as View));
            setTabIndex(openViews.length);
        },
    });

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        return setViewInput(event.target.value);
    };

    const openModal = (modalTitle: string, modalConfirmText?: string) => {
        setModalIsOpen(true);
        setTitle(modalTitle);
        setConfirmText(modalConfirmText || modalTitle);
    };

    const onModalCancel = () => {
        setModalIsOpen(false);
        setViewInput('');
    };

    const onModalConfirm = () => {
        switch (currentOption) {
            case CREATE_VIEW:
                if (viewInput) {
                    executeCreateView({
                        variables: {
                            data: {
                                name: viewInput,
                                email,
                                viewTypeId: 1,
                                filter: {},
                                isOpen: true,
                                groupBy: groupFilters.DEADLINE,
                            },
                        },
                    });
                }
                break;
            case RENAME_VIEW:
                if (selectedView && viewInput) {
                    dispatch(updateView({ ...selectedView, name: viewInput }));
                    executeUpdateView({
                        variables: {
                            data: {
                                name: viewInput,
                            },
                            viewId: selectedView.viewId,
                        },
                    });
                }
                break;
            case DELETE_VIEW:
                if (selectedView) {
                    const newSelectedView = openViews[tabIndex - 1] ? openViews[tabIndex - 1] : openViews[tabIndex];
                    dispatch(updateView({ ...selectedView, isRemoved: true }));
                    dispatch(setSelectedView(newSelectedView));
                    setTabIndex(openViews.findIndex((openView) => openView.viewId === newSelectedView?.viewId));
                    executeUpdateView({
                        variables: {
                            data: {
                                isRemoved: true,
                            },
                            viewId: selectedView.viewId,
                        },
                    });
                }
                break;
            default:
                break;
        }
        setModalIsOpen(false);
        setViewInput('');
        setAnchorEl(null);
        setNewViewAnchorEl(null);
    };

    const renderModalContent = () => {
        let modalContent: JSX.Element;
        switch (title) {
            case CREATE_VIEW:
                modalContent = (
                    <Grid container>
                        <Grid item xs={12}>
                            <FieldTitle title="*View Name" />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                required
                                variant="outlined"
                                value={viewInput}
                                onChange={handleTextFieldChange}
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                            />
                        </Grid>
                        <Grid className={classes.characterLimit} item xs={12}>
                            <FieldTitle title={`${viewInput?.length || 0}/30 Characters`} />
                        </Grid>
                    </Grid>
                );
                break;
            case RENAME_VIEW:
                modalContent = (
                    <Grid container spacing={1}>
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <Typography>Status</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.currentViewNameText}>{selectedView?.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldTitle title="*Updated Name" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                value={viewInput}
                                onChange={handleTextFieldChange}
                                fullWidth
                                inputProps={{ maxLength: 30 }}
                            />
                        </Grid>
                        <Grid className={classes.characterLimit} item xs={12}>
                            <FieldTitle title={`${viewInput?.length || 0}/30 Characters`} />
                        </Grid>
                    </Grid>
                );
                break;
            default:
                modalContent = (
                    <Grid container>
                        <Grid item xs>
                            <Typography>
                                Are you sure you want to delete the <strong>{selectedView?.name}</strong> view? You will
                                not be able access this view again.
                            </Typography>
                        </Grid>
                    </Grid>
                );
                break;
        }
        return modalContent;
    };

    const handleMenuSelection = (option?: string, view?: View) => {
        switch (option) {
            case CREATE_VIEW:
                openModal(CREATE_VIEW, 'Add New View');
                setCurrentOption(CREATE_VIEW);
                break;
            case RENAME_VIEW:
                if (selectedView) {
                    openModal(RENAME_VIEW);
                    setCurrentOption(RENAME_VIEW);
                }
                break;
            case DELETE_VIEW:
                if (selectedView) {
                    openModal(DELETE_VIEW);
                    setCurrentOption(DELETE_VIEW);
                }
                break;
            case CLOSE_VIEW:
                if (selectedView && selectedView.isOpen) {
                    const newSelectedView = openViews[tabIndex - 1] ? openViews[tabIndex - 1] : openViews[tabIndex];
                    dispatch(updateView({ ...selectedView, isOpen: false }));
                    executeUpdateView({
                        variables: {
                            data: {
                                isOpen: false,
                            },
                            viewId: selectedView.viewId,
                        },
                    });
                    dispatch(setSelectedView(newSelectedView));
                    setTabIndex(openViews.findIndex((openView) => openView.viewId === newSelectedView?.viewId));
                }
                break;
            default:
                if (view?.viewId && !view.isOpen) {
                    dispatch(updateView({ ...view, isOpen: true }));
                    executeUpdateView({
                        variables: {
                            data: {
                                isOpen: true,
                            },
                            viewId: view.viewId,
                        },
                    });
                    setTabIndex(openViews.length);
                }
                break;
        }
        setAnchorEl(null);
        setNewViewAnchorEl(null);
    };

    useEffect(() => {
        const newViews = views.filter((view: View) => view.isOpen && !view.isRemoved);
        setOpenViews(newViews);
        dispatch(setSelectedView(newViews[tabIndex]));
    }, [dispatch, tabIndex, views]);

    return (
        <Paper className={classes.root} elevation={0} square>
            <CssBaseline />
            <Tabs
                selectedIndex={tabIndex}
                onSelect={(index: number, lastIndex: number) => {
                    const currentView = openViews[index];
                    const previousView = openViews[lastIndex];
                    if (currentView === previousView) {
                        return false;
                    }
                    if (!currentView) {
                        return false;
                    }
                    return setTabIndex(index);
                }}
            >
                <TabList className={classes.tabList}>
                    <Grid container wrap="nowrap">
                        <Grid item xs={9}>
                            {openViews.map((view: View) => (
                                <CustomTabItem setAnchorEl={setAnchorEl} key={view.viewId} view={view} />
                            ))}
                            <NewTabItem
                                setAnchorEl={setNewViewAnchorEl}
                                anchorEl={newViewAnchorEl}
                                closedViews={closedViews}
                                handleMenuSelection={handleMenuSelection}
                            />
                        </Grid>
                        <Grid className={classes.buttonGroupContainer} item xs={3}>
                            <ButtonGroup setTabIndex={setTabIndex} openViews={openViews} />
                        </Grid>
                    </Grid>
                </TabList>

                {openViews.map((view: View) => (
                    <TabPanel key={view.viewId}>
                        <List view={view} />
                    </TabPanel>
                ))}
            </Tabs>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleMenuSelection()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ marginTop: 50 }}
            >
                <MenuItem
                    onClick={() => {
                        return handleMenuSelection(RENAME_VIEW);
                    }}
                >
                    Rename View
                </MenuItem>
                <MenuItem onClick={() => handleMenuSelection(CLOSE_VIEW)}>{CLOSE_VIEW}</MenuItem>
                <MenuItem onClick={() => handleMenuSelection(DELETE_VIEW)}>{DELETE_VIEW}</MenuItem>
            </Menu>
            <ConfirmModal
                className={classes.confirmModal}
                isOpen={modalIsOpen}
                title={title}
                content={renderModalContent()}
                confirmColor="primary"
                confirmText={confirmText}
                confirmDisabled={currentOption !== DELETE_VIEW && trim(viewInput).length === 0}
                showActions
                confirmVariant="contained"
                onConfirm={onModalConfirm}
                onCancel={onModalCancel}
                maxHeight={maxModalHeight}
            />
            <Onboarding />
        </Paper>
    );
}

export default Work;
