import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';

import { Breadcrumbs, CircularProgress, Grid, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { WorkItem } from 'typings';

import PageLayout from 'components/layouts/PageLayout';
import { routePaths } from 'components/providers/ReactRouter';
import ActionWorkDetails from 'components/workItemDetails/ActionWorkDetails';
import Overview from 'components/workItemDetails/overview/Overview';
import Subtasks from 'components/workItemDetails/subtasks/Subtasks';
import { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import useIsATeamMember from 'hooks/useIsATeamMember';
import {
    removeChild,
    setSelectedWorkItem,
    updateChildWorkItem,
    updateParentWorkItem,
} from 'store/actions/SelectedItem';
import { setTags } from 'store/actions/Tag';
import { Store } from 'store/reducers';
import {
    Tag,
    UpdateWorkItemMutation,
    useGetTagsQuery,
    useGetWorkItemLazyQuery,
    WorkItem as GeneratedWorkItem,
} from 'typings/generated';

export type SetWorkDetailsTab = Dispatch<SetStateAction<number>>;

interface HandleRenderProps {
    tab: number;
    workItem: GeneratedWorkItem;
    updateLocalWorkItem: UpdateWorkItemHandler;
    updateLocalWorkItemSubtasks: UpdateWorkItemHandler;
    subtaskId?: string;
    setTab: SetWorkDetailsTab;
    filterSubItem: (itemId: number | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    backButton: {
        '& .MuiSvgIcon-root': {
            verticalAlign: 'sub',
        },
    },
}));

const handleRender = ({
    tab,
    workItem,
    updateLocalWorkItem,
    subtaskId,
    updateLocalWorkItemSubtasks,
    setTab,
    filterSubItem,
}: HandleRenderProps): ReactElement | null => {
    switch (tab) {
        case 0: {
            return <Overview workItem={workItem} onUpdateWorkItem={updateLocalWorkItem} setWorkDetailsTab={setTab} />;
        }
        case 1: {
            return (
                <Subtasks
                    workItem={workItem}
                    subtaskId={subtaskId ? +subtaskId : undefined}
                    onUpdateWorkItem={updateLocalWorkItemSubtasks}
                    setWorkDetailsTab={setTab}
                    filterSubItem={filterSubItem}
                />
            );
        }
        default: {
            return null;
        }
    }
};

export default (): ReactElement => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [tab, setTab] = useState(0);
    const { id: workItemId, subtaskId } = useParams<{ id?: string; subtaskId?: string }>();
    const history = useHistory();
    useGetTagsQuery({ fetchPolicy: 'cache-and-network', onCompleted: (data) => dispatch(setTags(data.tags as Tag[])) });

    // We have the option to destructure isDirty here if needed.
    const { workItem } = useSelector((store: Store) => store.SelectedItem);

    const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
        if (newValue === 0) {
            history.push(`/${routePaths.WORK_ITEM}/${workItemId}`);
        }
        setTab(newValue);
    };

    const [getWorkItem, { loading }] = useGetWorkItemLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
            dispatch(setSelectedWorkItem(response?.workItem as GeneratedWorkItem));
            setTab(subtaskId ? 1 : 0);
        },
        onError: (error) => {
            const regEx = /not found/gi;
            if (regEx.test(error.message)) {
                history.push(`/${routePaths.NOT_FOUND}`);
            }
        },
    });
    const updateLocalWorkItem: UpdateWorkItemHandler = (updatedWorkItem: UpdateWorkItemMutation['updateWorkItem']) => {
        dispatch(updateParentWorkItem(updatedWorkItem as GeneratedWorkItem));
    };

    const filterSubItem = (itemId: number | undefined) => {
        if (itemId) {
            dispatch(removeChild(itemId));
        }
    };

    const isATeamMember = useIsATeamMember({ belongsToTeamId: workItem?.team?.teamId as number });

    const disableEditing = !isATeamMember;

    const actions = () => {
        if (tab === 0 && workItem) {
            return (
                <ActionWorkDetails
                    selectedId={workItem.workItemId}
                    workItem={workItem as WorkItem}
                    disabled={disableEditing}
                />
            );
        }
        return null;
    };

    const updateLocalWorkItemSubtasks: UpdateWorkItemHandler = (
        updatedWorkItem: UpdateWorkItemMutation['updateWorkItem']
    ) => {
        dispatch(updateChildWorkItem(updatedWorkItem.workItemId, updatedWorkItem as GeneratedWorkItem));
    };

    useEffect(() => {
        if (workItemId) {
            // NaN is of type number in JS :/ so check if it's greater than 0.
            if (+workItemId > 0) {
                getWorkItem({ variables: { workItemId: +workItemId } });
            } else {
                history.push(`/${routePaths.NOT_FOUND}`);
            }
        }
    }, [workItemId, getWorkItem, history]);

    const LinkRouter = (props: any) => <Link {...props} component={RouterLink} />;
    const navigation = (
        <Breadcrumbs aria-label="breadcrumb">
            <LinkRouter color="inherit" to={`/${routePaths.WORK_ITEM}/${workItem?.parent?.workItemId}`}>
                {workItem?.parent?.name}
            </LinkRouter>
            <LinkRouter
                color="inherit"
                to={`/${routePaths.WORK_ITEM}/${workItem?.parent?.workItemId}/${workItem?.workItemId}`}
            >
                {workItem?.name}
            </LinkRouter>
        </Breadcrumbs>
    );
    const backButton = (
        <Breadcrumbs className={classes.backButton} aria-label="breadcrumb" separator={false}>
            <LinkRouter color="inherit" to={`/${routePaths.WORK_ITEMS}`} style={{ textDecoration: 'none' }}>
                <Grid container>
                    <Grid item>
                        <ArrowBackIosIcon fontSize="small" />
                    </Grid>
                    <Grid item>
                        <Typography>Back</Typography>
                    </Grid>
                </Grid>
            </LinkRouter>
        </Breadcrumbs>
    );

    return (
        <PageLayout
            subHeaderProps={{
                show: true,
                tabs: ['Overview', 'Subtasks'],
                currentTab: tab,
                onTabChange: handleChange,
                leftContent: workItem?.parent?.workItemId ? navigation : backButton,
                rightContent: (
                    <Grid container item justify="flex-end">
                        {actions()}
                    </Grid>
                ),
            }}
        >
            {(): ReactElement | null => {
                if (!workItem || loading) {
                    return (
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            style={{ height: '100%' }}
                        >
                            <Grid item>
                                <CircularProgress color="secondary" size="6em" />
                            </Grid>
                        </Grid>
                    );
                }
                return handleRender({
                    tab,
                    workItem,
                    updateLocalWorkItem,
                    subtaskId,
                    updateLocalWorkItemSubtasks,
                    setTab,
                    filterSubItem,
                });
            }}
        </PageLayout>
    );
};
