import { Action } from 'store';

import { WorkItem as GeneratedWorkItemType } from '../../typings/generated';
import { WorkItem } from '../../typings/workItem';

export const SET_PLANNED = 'SET_PLANNED';
export const ADD_ITEM_TO_PLANNED = 'ADD_ITEM_TO_PLANNED';
export const ADD_ITEM_TO_COMPLETED = 'ADD_ITEM_TO_COMPLETED';
export const REMOVE_ITEM_FROM_PLANNED = 'REMOVE_ITEM_FROM_PLANNED';
export const SET_UNPLANNED = 'SET_UNPLANNED';
export const ADD_ITEM_TO_UNPLANNED = 'ADD_ITEM_TO_UNPLANNED';
export const REMOVE_ITEM_FROM_UNPLANNED = 'REMOVE_ITEM_FROM_UNPLANNED';
export const EDIT_PLANNED_ITEM = 'EDIT_PLANNED_ITEM';
export const ADD_WORK_FROM_REQUESTED_WITH_DRAG = 'ADD_WORK_FROM_REQUESTED_WITH_DRAG';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';

export const setPlanned = (items: Array<WorkItem | GeneratedWorkItemType>): Action => ({
    type: SET_PLANNED,
    payload: { items: items as WorkItem[] },
});

export const setUnplanned = (items: WorkItem[]): Action => ({
    type: SET_UNPLANNED,
    payload: { items },
});

export const addItemToPlanned = (item: WorkItem): Action => ({
    type: ADD_ITEM_TO_PLANNED,
    payload: { item },
});

export const addItemToUnplanned = (item: WorkItem): Action => ({
    type: ADD_ITEM_TO_UNPLANNED,
    payload: { item },
});

export const addItemToCompleted = (item: WorkItem): Action => ({
    type: ADD_ITEM_TO_COMPLETED,
    payload: { item },
});

export const removeItemFromPlanned = (workItemId: WorkItem['workItemId']): Action => ({
    type: REMOVE_ITEM_FROM_PLANNED,
    payload: { id: workItemId },
});

export const removeItemFromUnplanned = (workItemId: WorkItem['workItemId']): Action => ({
    type: REMOVE_ITEM_FROM_UNPLANNED,
    payload: { id: workItemId },
});

export const editPlannedItem = (item: WorkItem | GeneratedWorkItemType, parentWorkItemId?: number): Action => ({
    type: EDIT_PLANNED_ITEM,
    payload: { item, parentWorkItemId },
});

export const deleteItem = (workItemId: WorkItem['workItemId']): Action => ({
    type: REMOVE_ITEM_FROM_PLANNED,
    payload: { id: workItemId },
});

export const setSearchTerm = (searchTerm: string): Action => ({
    type: SET_SEARCH_TERM,
    payload: { searchTerm },
});
