import { FunctionComponent, ReactNode } from 'react';

import { CssBaseline, Grid, GridSpacing, makeStyles, Paper } from '@material-ui/core';
import { SizeMe } from 'react-sizeme';

import SubHeader, { Props as SubHeaderProps, SUB_HEADER_HEIGHT } from './subHeader/SubHeader';

type ChildrenProps = {
    height: number;
    width: number;
};
interface Props {
    subHeaderProps: SubHeaderProps;
    spacing?: GridSpacing;
    children: ({ height, width }: ChildrenProps) => ReactNode;
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        padding: 0,
        height: '100%',
    },
    content: {
        flexGrow: 1,
        height: '100%',
    },
}));

const PageLayout: FunctionComponent<Props> = ({ children, subHeaderProps, spacing = 0 }) => {
    const classes = useStyles();

    const headerHeight: number = subHeaderProps.show ? SUB_HEADER_HEIGHT : 0;

    return (
        <SizeMe monitorHeight refreshRate={32} noPlaceholder refreshMode="debounce">
            {({ size }) => {
                const maxHeight: number = size?.height ? size.height - headerHeight : 0;

                return (
                    <Paper elevation={0} square className={classes.root}>
                        <CssBaseline />
                        <Grid container direction="row" justify="center" alignItems="stretch" spacing={spacing}>
                            {subHeaderProps.show ? (
                                <Grid item xs={12}>
                                    <SubHeader {...subHeaderProps} />
                                </Grid>
                            ) : null}

                            <Grid item xs={12} className={classes.content} style={{ maxHeight }}>
                                {children({ height: maxHeight, width: size?.width || 0 })}
                            </Grid>
                        </Grid>
                    </Paper>
                );
            }}
        </SizeMe>
    );
};

export default PageLayout;
