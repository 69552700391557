import { ReactElement, useState } from 'react';

import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { openSnackbar } from 'store/actions/Snackbar';
import { Store } from 'store/reducers';
import { useAddUserToTeamMutation } from 'typings/generated';
import { sortByName } from 'utils/sortBy';

interface StylesProps {
    length: number;
}
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },
    titleContainer: {
        paddingBottom: '1.5em',
    },
    title: {
        fontSize: '1.1em',
        fontWeight: 'bolder',
    },
    formBox: {
        padding: '.5em .5em 2.5em',
        backgroundColor: theme.palette.background.default,
        maxWidth: theme.spacing(55),
    },
    formContent: {
        margin: '0em 2em',
    },
    fieldNameText: {
        fontSize: '1em',
    },
    infoIcon: {
        marginLeft: '4px',
    },
    button: {
        marginTop: '.75em',
    },
}));

type FormItem = {
    email: string;
    teamId: number;
} & {
    [x: string]: string;
};

export default function AssignUser(): ReactElement {
    const dispatch = useDispatch();
    const defaultValues = {
        email: '',
        teamId: '',
    };
    const { handleSubmit, reset, control, errors } = useForm({
        defaultValues: (defaultValues as unknown) as Partial<FormItem>,
    });
    const classes = useStyles();
    const { teams } = useSelector((state: Store) => state.Team);
    const [firstUser, setFirstUser] = useState(true);

    const teamsAlphabetized = sortByName(teams);
    const [executeAddUserToTeam] = useAddUserToTeamMutation({
        onCompleted: (response) => {
            dispatch(
                openSnackbar({
                    message: `You have successfully assigned ${response.addUserToTeam.user.email} to ${response.addUserToTeam.team.name} `,
                })
            );
            reset({ email: '' });
            setFirstUser(false);
        },
        onError({ graphQLErrors }) {
            graphQLErrors.forEach(({ message }) => dispatch(openSnackbar({ message })));
        },
    });

    const onSave = async (formData: FormItem) => {
        executeAddUserToTeam({
            variables: { email: formData.email, teamId: formData.teamId },
        });
    };

    return (
        <Grid container xs={12} className={classes.container} justify="center">
            <Paper>
                <Grid item xs={12} className={classes.formBox}>
                    <Grid container item xs={12} justify="flex-end">
                        <IconButton href="/" aria-label="close" data-testid="close-button" size="small">
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} className={classes.formContent}>
                        <Grid item xs={12} className={classes.titleContainer}>
                            <Typography className={classes.title}>Assign a Prioritize account</Typography>
                            <Typography variant="caption" component="span" color="textSecondary">
                                User must log in once before they can be added to a team.
                            </Typography>
                        </Grid>
                        <form onSubmit={handleSubmit(onSave)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} container direction="row">
                                    <Grid item xs={12}>
                                        <Grid container item xs={12} alignContent="center">
                                            <Typography className={classes.fieldNameText} component="span">
                                                AFWERX Email
                                            </Typography>
                                            <Tooltip title="Please use their AFWERX Google email" placement="right">
                                                <InfoOutlined className={classes.infoIcon} />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption" component="span" color="textSecondary">
                                                * required
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <label htmlFor="email">
                                                <Controller
                                                    control={control}
                                                    render={({ onChange, value }) => (
                                                        <TextField
                                                            inputProps={{
                                                                'data-testid': 'email',
                                                            }}
                                                            id="email"
                                                            name="email"
                                                            variant="outlined"
                                                            color="primary"
                                                            value={value || ''}
                                                            onChange={onChange}
                                                            placeholder="Email"
                                                            size="small"
                                                            fullWidth
                                                            error={!!errors.email}
                                                        />
                                                    )}
                                                    name="email"
                                                    rules={{ required: true, minLength: 1 }}
                                                />
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container direction="row">
                                    <Grid item xs={12}>
                                        <Typography className={classes.fieldNameText} component="span" gutterBottom>
                                            Assigned Team
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" component="span" color="textSecondary">
                                            * required
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label htmlFor="team">
                                            <Controller
                                                control={control}
                                                render={({ onChange, value }) => (
                                                    <Select
                                                        id="team"
                                                        name="team"
                                                        variant="outlined"
                                                        color="primary"
                                                        value={value}
                                                        onChange={onChange}
                                                        data-testid="team"
                                                        MenuProps={{
                                                            style: {
                                                                zIndex: 9999,
                                                            },
                                                        }}
                                                        fullWidth
                                                        error={!!errors.teamId}
                                                    >
                                                        {teamsAlphabetized.map((team) => (
                                                            <MenuItem
                                                                className={classes.fieldNameText}
                                                                key={team.teamId}
                                                                value={team.teamId}
                                                            >
                                                                {team.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                                name="teamId"
                                                defaultValue={defaultValues.teamId}
                                                rules={{ required: true }}
                                            />
                                        </label>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container direction="row" className={classes.button}>
                                    {firstUser ? (
                                        <Button color="primary" variant="contained" type="submit" size="small">
                                            Assign User
                                        </Button>
                                    ) : (
                                        <Button color="primary" variant="contained" type="submit" size="small">
                                            Assign Another User
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
}
