import { ReactElement, useState } from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { WorkItem } from 'typings';

import Attachments from 'components/attachments/Attachments';
import { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import { updateChildWorkItem } from 'store/actions/SelectedItem';
import {
    Attachment,
    CreateAttachmentMutation,
    Maybe,
    UpdateWorkItemMutation,
    WorkItem as GeneratedWorkItem,
} from 'typings/generated';

interface Props {
    attachments: Maybe<Attachment[]> | undefined;
    workItem: WorkItem;
    onUpdateWorkItem: UpdateWorkItemHandler;
}

const useStyles = makeStyles(() => ({
    container: {
        padding: '0px 16px',
    },
}));

export default ({ attachments, workItem, onUpdateWorkItem }: Props): ReactElement => {
    const dispatch = useDispatch();
    const [addAttachmentDialogIsOpen, setAddAttachmentDialogIsOpen] = useState(false);
    const classes = useStyles();

    const onOpenAddAttachment = () => setAddAttachmentDialogIsOpen(true);
    const onClose = (updatedWorkItem: WorkItem) => {
        setAddAttachmentDialogIsOpen(false);
        onUpdateWorkItem(updatedWorkItem as UpdateWorkItemMutation['updateWorkItem']);
    };
    const onAddGoogleFile = (newAttachments: CreateAttachmentMutation['createAttachment'][]) => {
        onUpdateWorkItem({
            ...workItem,
            attachments: Array.isArray(workItem.attachments)
                ? (([...workItem.attachments, ...newAttachments] as unknown) as Attachment[])
                : ((newAttachments as unknown) as Attachment[]),
        } as UpdateWorkItemMutation['updateWorkItem']);
    };

    const onAttachmentDeleted = (deletedAttachmentId: number) => {
        const isAttachmentFromParent = workItem?.attachments?.some(
            (attachment) => attachment.attachmentId === deletedAttachmentId
        );

        const generateMutationPayload = (workItemToUpdate: GeneratedWorkItem) => {
            return {
                ...workItemToUpdate,
                attachments:
                    ((workItemToUpdate?.attachments?.filter(
                        (attachment: Attachment) => attachment.attachmentId !== deletedAttachmentId
                    ) as unknown) as Attachment[]) || [],
            };
        };

        let updatedWorkItem: GeneratedWorkItem;

        if (isAttachmentFromParent) {
            updatedWorkItem = generateMutationPayload(workItem as GeneratedWorkItem);
            onUpdateWorkItem(updatedWorkItem);
        } else if (workItem.children) {
            workItem.children.forEach((child) => {
                if (child.attachments?.some((attachment) => attachment.attachmentId === deletedAttachmentId)) {
                    updatedWorkItem = generateMutationPayload(child as GeneratedWorkItem);
                    dispatch(updateChildWorkItem(updatedWorkItem?.workItemId, updatedWorkItem));
                }
            });
        }
    };

    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
                <Attachments
                    workItem={workItem}
                    attachments={attachments}
                    onOpen={onOpenAddAttachment}
                    isOpen={addAttachmentDialogIsOpen}
                    onClose={onClose}
                    onAttachmentDeleted={onAttachmentDeleted}
                    onAddGoogleFile={onAddGoogleFile}
                />
            </Grid>
        </Grid>
    );
};
