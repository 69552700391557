import { ReactElement, useState } from 'react';

import { useSelector } from 'react-redux';

import { Store } from '../../../store/reducers';
import { useGetUsersByTeamQuery } from '../../../typings/generated';
import { AssigneeSelectProps } from '../selectInterfaces';
import AssigneeSelectForm from './AssigneeSelectForm';
import AssigneeSelectView from './AssigneeSelectView';

const AssigneeSelect = (props: AssigneeSelectProps): ReactElement => {
    const { teamId } = props;
    const [showForm, setShowForm] = useState(false);

    const { data: currentTeamMembers } = useGetUsersByTeamQuery({
        skip: !teamId,
        variables: {
            teamId,
        },
        fetchPolicy: 'cache-first',
    });

    const currentAssigneeName = (userId: string | undefined = '') => {
        let assigneeName = 'Unassigned';

        const assignee = currentTeamMembers?.usersByTeam?.find((member) => member.id === userId);

        if (assignee) {
            assigneeName = `${assignee?.firstName} ${assignee?.lastName}`;
        }

        return assigneeName;
    };

    const currentAssigneeAvatar = (userId: string | undefined) => {
        let assigneeAvatar;

        const assignee = currentTeamMembers?.usersByTeam?.find((member) => member.id === userId);

        if (assignee?.avatar) {
            assigneeAvatar = `${assignee?.avatar}`;
        }

        return assigneeAvatar;
    };

    return showForm ? (
        <AssigneeSelectForm
            setShowForm={setShowForm}
            currentAssigneeName={currentAssigneeName}
            currentAssigneeAvatar={currentAssigneeAvatar}
            currentTeamMembers={currentTeamMembers}
            {...props}
        />
    ) : (
        <AssigneeSelectView
            setShowForm={setShowForm}
            currentAssigneeName={currentAssigneeName}
            currentAssigneeAvatar={currentAssigneeAvatar}
            currentTeamMembers={currentTeamMembers}
            {...props}
        />
    );
};

export default AssigneeSelect;
