import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTags } from 'store/actions/Tag';
import { setItemTypes } from '../store/actions/ItemType';
import { setStatuses } from '../store/actions/Status';
import { useGetItemTypesLazyQuery, useGetStatusesLazyQuery, useGetTagsLazyQuery } from '../typings/generated';

type FetchAll = () => void;

type UseFetchTypeTables = [FetchAll, boolean];

export default (): UseFetchTypeTables => {
    const dispatch = useDispatch();
    const [called, setCalled] = useState(false);
    const [getItemTypes] = useGetItemTypesLazyQuery({
        onCompleted: (data) => dispatch(setItemTypes(data.itemTypes)),
    });
    const [getStatuses] = useGetStatusesLazyQuery({
        onCompleted: (data) => dispatch(setStatuses(data.statuses)),
    });
    const [getTags] = useGetTagsLazyQuery({ onCompleted: (data) => dispatch(setTags(data.tags)) });
    const fetchAll = useCallback(() => {
        if (!called) {
            // Update the Redux Store
            getItemTypes();
            getStatuses();
            getTags();
            setCalled(true);
        }
    }, [getItemTypes, getStatuses, getTags, setCalled, called]);

    return [fetchAll, called];
};
