import { Checkbox, Chip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { Tag } from '../../../typings/generated';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TagAutoCompleteProps {
    disableCreation?: boolean;
    onChange: (newValue: Array<string | Partial<Tag>>) => void;
    value: Tag[];
    id: string;
}
const TagAutoComplete = ({ disableCreation, onChange, id, value: tagValue }: TagAutoCompleteProps): ReactElement => {
    const { tags } = useSelector((state: Store) => state.Tag);

    return (
        <Autocomplete
            multiple
            id={id}
            value={tagValue}
            onChange={(_event, newValue) => onChange(newValue)}
            options={tags}
            freeSolo={!disableCreation}
            getOptionLabel={(tag) => tag.name || ((tag as unknown) as string)}
            renderOption={(option, { selected }) => (
                <>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        color="primary"
                        checked={selected}
                    />
                    {option.name}
                </>
            )}
            renderTags={(value: Tag[], getTagProps) =>
                value.map((option: Tag, index: number) => (
                    <Chip variant="outlined" label={option.name || option} size="small" {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Enter a tag..." />}
        />
    );
};

export default TagAutoComplete;
