import { Button, IconButton, Snackbar, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';

import { closeSnackbar } from '../../store/actions/Snackbar';
import { Store } from '../../store/reducers';

const useStyles = makeStyles((theme: Theme) => ({
    snackBar: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    action: {
        color: theme.palette.primary.main,
    },
}));

export default (): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { message, anchorOrigin, open, actionName, onActionClick } = useSelector((state: Store) => state.Snackbar);

    const handleClose = () => dispatch(closeSnackbar());

    const onClick = () => {
        if (onActionClick) {
            onActionClick();
        }
        handleClose();
    };

    let actionButton = null;
    if (actionName && onActionClick) {
        actionButton = (
            <Button variant="text" className={classes.action} onClick={onClick}>
                {actionName}
            </Button>
        );
    }

    const cancelButton = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <Close fontSize="small" data-testid="close-icon" />
        </IconButton>
    );

    const actions = actionButton ? [actionButton, cancelButton] : cancelButton;

    return (
        <Snackbar
            style={{ zIndex: 4000 }}
            ContentProps={{
                className: classes.snackBar,
            }}
            open={open}
            message={message}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'right' }}
            action={actions}
        />
    );
};
