import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { Status } from 'typings';
import { sortByName } from 'utils/sortBy';
import { SelectProps } from '../selectInterfaces';
import StatusSelectForm from './StatusSelectForm';
import StatusSelectView from './StatusSelectView';

const StatusSelect = (props: SelectProps): ReactElement => {
    const [showForm, setShowForm] = useState(false);

    const { statuses } = useSelector((state: Store) => state.Status);
    const statusesAlphabetized = sortByName(statuses) as Status[];

    return showForm ? (
        <StatusSelectForm setShowForm={setShowForm} statuses={statusesAlphabetized} {...props} />
    ) : (
        <StatusSelectView setShowForm={setShowForm} statuses={statusesAlphabetized} {...props} />
    );
};

export default StatusSelect;
