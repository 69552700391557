import { ComponentType, ReactNode } from 'react';

import { useKeycloak } from '@react-keycloak/web';

import { Route } from 'react-router-dom';

const PrivateRoute: ComponentType<any> = ({ component: Component, ...rest }): JSX.Element => {
    const [keycloak, initialized] = useKeycloak();
    const isLoggedIn = (keycloak && keycloak.authenticated) || false;

    const displayRoute = (props: any): ReactNode => {
        if (initialized && !isLoggedIn) {
            return (
                <div className="not-logged-in">
                    {initialized && keycloak && keycloak.login({ redirectUri: window.location.href })}
                </div>
            );
        }

        const { capabilities } = rest;
        if (!capabilities) {
            return <Component {...props} />;
        }

        return null;
    };

    return <Route {...rest} render={(props): ReactNode => displayRoute(props)} />;
};

export default PrivateRoute;
