import { Dispatch, ReactEventHandler, SetStateAction } from 'react';

import { Button, Grid, IconButton, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Tab } from 'react-tabs';

import SaveView from 'components/saveView/SaveView';
import SaveViewAs from 'components/saveView/SaveViewAs';
import { ExtendedView } from 'store/reducers/View';
import { View } from 'typings/generated';
import { CREATE_VIEW, OPEN_VIEW } from './ViewTabUtils';

const useStyles = makeStyles((theme: Theme) => ({
    customTabItem: {
        paddingLeft: theme.spacing(1),

        '& .MuiSvgIcon-root': {
            fill: '#000000',
        },
        '& .MuiTypography-body1': {
            fontWeight: 600,
        },
    },
    newTabItem: {
        '& :hover': {
            backgroundColor: '#f1e6db',
        },
    },
    addIconContainer: {
        marginTop: theme.spacing(1),
    },
}));

interface CustomTabItemProps {
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
    anchorEl?: HTMLElement | null;
    view?: View;
    handleOpen?: ReactEventHandler;
    handleMenuSelection?: any;
    closedViews?: View[];
}

interface ButtonGroupProps {
    setTabIndex: Dispatch<SetStateAction<number>>;
    openViews: ExtendedView[];
}

export const CustomTabItem = ({ view, handleOpen, setAnchorEl, ...otherProps }: CustomTabItemProps): JSX.Element => {
    const classes = useStyles();
    const openCustomViewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Tab {...otherProps} data-testid={`test-${view?.name}`}>
            <Grid className={classes.customTabItem} container alignItems="center" wrap="nowrap" spacing={1}>
                <Grid item xs={11}>
                    <Typography>{view?.name}</Typography>
                </Grid>
                <Grid item xs>
                    <IconButton onClick={openCustomViewMenu} size="small">
                        <MoreVertIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Tab>
    );
};

CustomTabItem.tabsRole = 'Tab'; // Required field to use your custom Tab

export const NewTabItem = ({
    handleOpen,
    anchorEl,
    setAnchorEl,
    handleMenuSelection,
    closedViews,
    ...otherProps
}: CustomTabItemProps): JSX.Element => {
    const classes = useStyles();
    const openNewViewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Tab {...otherProps} disabled data-testid={`test-${OPEN_VIEW}`}>
            <Button className={classes.newTabItem} onClick={openNewViewMenu}>
                <Grid container alignItems="center" spacing={1}>
                    <Grid className={classes.addIconContainer} item xs={3}>
                        <AddCircleIcon color="primary" />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography color="primary">{OPEN_VIEW}</Typography>
                    </Grid>
                </Grid>
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuSelection}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ marginTop: 50 }}
            >
                <MenuItem onClick={() => handleMenuSelection(CREATE_VIEW)}>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <AddCircleIcon />
                        </Grid>
                        <Grid item xs>
                            {CREATE_VIEW}
                        </Grid>
                    </Grid>
                </MenuItem>
                {closedViews &&
                    closedViews.map((view) => {
                        return (
                            <MenuItem key={view.viewId} onClick={() => handleMenuSelection(OPEN_VIEW, view)}>
                                {view.name}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </Tab>
    );
};

export const ButtonGroup = ({ setTabIndex, openViews }: ButtonGroupProps): JSX.Element => {
    return (
        <Grid container justify="flex-end" spacing={3} alignContent="center" component="li">
            <Grid item xs="auto">
                <SaveView />
            </Grid>
            <Grid item xs="auto">
                <SaveViewAs setTabIndex={setTabIndex} openViews={openViews} />
            </Grid>
        </Grid>
    );
};
