import { Action } from 'store';
import { Snackbar } from '../../typings/snackbar';

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const openSnackbar = (snackbar: Snackbar): Action => ({
    type: OPEN_SNACKBAR,
    payload: { snackbar },
});

export const closeSnackbar = (): Action => ({
    type: CLOSE_SNACKBAR,
    payload: {},
});
