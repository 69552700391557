import {
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { TagAutoComplete } from 'components/formUtils/autoCompleteFields';
import FieldTitle from 'components/formUtils/FieldTitle';
import RichTextEditor from 'components/formUtils/RichTextEditor';
import helperText from 'utils/helperText';
import { sortByName, sortStringArray } from 'utils/sortBy';
import { Store } from '../../store/reducers';
import DateTimePicker from '../dateTimePicker/DateTimePicker';
import { renderValue } from '../formUtils';
import './TextField.scss';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingBottom: '10px',
        '& .MuiFormHelperText-root': {
            color: theme.palette.text.secondary,
        },
    },
    placeholder: {
        color: theme.palette.text.hint,
        fontSize: '14px',
    },
    title: {
        marginBottom: '16px',
    },
    bold: {
        fontWeight: 'bold',
    },
    titleText: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    fieldNameText: {
        fontSize: '14px',
    },
    attachmentsBody: {
        margin: '16px 0',
    },
    infoIcon: {
        marginLeft: '4px',
    },
}));

export interface Props {
    requested?: boolean;
}

export default ({ requested = false }: Props): JSX.Element => {
    const { control, errors } = useFormContext();
    const classes = useStyles();
    // Get statuses but exclude 'Unplanned'
    const { statuses, teams } = useSelector((state: Store) => ({
        statuses: state.Status.statuses.filter((status) => status.name !== 'Unplanned' && status.name !== 'Pending'),
        teams: state.Team.teams,
    }));
    const priorityLevels = ['Low', 'Normal', 'High', 'Critical'];
    const statusesAlphabetized = sortByName(statuses);
    const teamsAlphabetized = sortByName(teams);
    const priorityLevelsAlphabetized = sortStringArray(priorityLevels);

    return (
        <>
            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Work Name" editing required />
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor="name">
                        <Controller
                            control={control}
                            render={({ onChange, value }) => (
                                <TextField
                                    inputProps={{
                                        'data-testid': 'work-name',
                                    }}
                                    id="name"
                                    variant="outlined"
                                    color="primary"
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Enter work name"
                                    size="small"
                                    fullWidth
                                    error={errors?.name || value.length > 200}
                                    helperText={helperText(value.length > 200, 'Exceeds limit of 200 characters.')}
                                />
                            )}
                            name="name"
                            rules={{ required: true, minLength: 1 }}
                        />
                    </label>
                </Grid>
            </Grid>

            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Division" editing />
                    <FormHelperText>
                        If no division is selected, then this work item will be assigned to your division
                    </FormHelperText>
                </Grid>
                <Grid item xs={5}>
                    <Controller
                        control={control}
                        render={({ onChange, value }) => (
                            <Select
                                id="teamId"
                                name="teamId"
                                variant="outlined"
                                color="primary"
                                value={value}
                                onChange={onChange}
                                inputProps={{ 'data-testid': 'edit-teamId' }}
                                displayEmpty
                                fullWidth
                                renderValue={(render: any) => {
                                    const valueObject = teamsAlphabetized.find(({ teamId }) => teamId === render);
                                    return renderValue(
                                        value,
                                        <span>{valueObject?.name}</span>,
                                        <Typography className={classes.placeholder}>Select division</Typography>
                                    );
                                }}
                            >
                                {teamsAlphabetized.map((t) => (
                                    <MenuItem key={t.teamId} value={t.teamId}>
                                        {t.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        name="teamId"
                        inputProps={{ 'data-testid': 'teamId' }}
                        rules={{ required: false, minLength: 1 }}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Status" editing />
                </Grid>
                <Grid item xs={5}>
                    <label htmlFor="status">
                        <Controller
                            control={control}
                            render={({ onChange, value }) => (
                                <Select
                                    id="status"
                                    name="status"
                                    variant="outlined"
                                    color="primary"
                                    value={value}
                                    onChange={onChange}
                                    data-testid="status"
                                    MenuProps={{
                                        style: {
                                            zIndex: 9999,
                                        },
                                    }}
                                    fullWidth
                                >
                                    {statusesAlphabetized
                                        .filter((status) => status.name !== 'Pending')
                                        .map((status) => (
                                            <MenuItem
                                                className={classes.fieldNameText}
                                                key={status.statusId}
                                                value={status.statusId}
                                            >
                                                {status.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            )}
                            name="statusId"
                            rules={{ required: false }}
                        />
                    </label>
                </Grid>
            </Grid>

            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Deadline" editing />
                </Grid>
                <Grid item xs={5}>
                    <label htmlFor="dueDate">
                        <Controller
                            control={control}
                            render={({ onChange, value }) => (
                                <DateTimePicker
                                    id="dueDate"
                                    value={value}
                                    onChange={onChange}
                                    autoOk
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="Date Selector">
                                                    <TodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            name="dueDate"
                            rules={{ required: false }}
                        />
                    </label>
                </Grid>
            </Grid>

            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Priority" editing />
                </Grid>
                <Grid item xs={4}>
                    <label htmlFor="priority">
                        <Controller
                            control={control}
                            render={({ onChange, value }) => (
                                <Select
                                    id="priority"
                                    name="priority"
                                    variant="outlined"
                                    color="primary"
                                    value={value || ''}
                                    onChange={onChange}
                                    displayEmpty
                                    renderValue={(render: any) =>
                                        renderValue(
                                            value,
                                            <span>{render}</span>,
                                            <Typography className={classes.placeholder}>Select priority</Typography>
                                        )
                                    }
                                    fullWidth
                                >
                                    {priorityLevelsAlphabetized.map((priority) => (
                                        <MenuItem
                                            className={classes.fieldNameText}
                                            key={priority}
                                            value={priority || ''}
                                        >
                                            {priority}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            name="priority"
                            rules={{ required: false }}
                        />
                    </label>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Attached Links" editing />
                    <FormHelperText>Paste in links that you want attached to this work item</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor="specialInstruction">
                        <Controller
                            control={control}
                            render={({ onChange }) => (
                                <RichTextEditor
                                    id="specialInstruction"
                                    onChange={onChange}
                                    controls={['link']}
                                    label="Enter attached link(s)"
                                />
                            )}
                            name="specialInstruction"
                            rules={{ required: false }}
                        />
                    </label>
                </Grid>
            </Grid>
            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Description" editing />
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor="description">
                        <Controller
                            control={control}
                            render={({ onChange }) => <RichTextEditor id="description" onChange={onChange} />}
                            name="description"
                            rules={{ required: false }}
                        />
                    </label>
                </Grid>
            </Grid>

            <Grid item xs={12} container direction="row" className={classes.content} spacing={1}>
                <Grid item xs={12}>
                    <FieldTitle title="Tags" editing />
                    <FormHelperText>
                        Search and select existing tags OR to add a new tag, type the tag name and hit ENTER
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <label htmlFor="tags">
                        <Controller
                            control={control}
                            render={({ onChange, value }) => (
                                <TagAutoComplete id="tags" onChange={onChange} value={value} />
                            )}
                            name="tags"
                            rules={{ required: false }}
                        />
                    </label>
                </Grid>
            </Grid>
        </>
    );
};
