import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line no-use-before-define
import {
    Grid,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    Theme,
    Toolbar,
} from '@material-ui/core';
import { ClearOutlined, Delete, Edit, SearchOutlined } from '@material-ui/icons';
import { debounce } from 'lodash-es';
import { useSelector } from 'react-redux';

import { Store } from 'store/reducers';
import { useGetUsersByOrganizationQuery, UserTeam } from 'typings/generated';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        justifyItems: 'center',
    },
    tableContainer: {
        maxHeight: '80vh',
    },
    table: {
        minWidth: 750,
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    tableToolbar: {
        paddingLeft: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
    endAdornment: {
        cursor: 'pointer',
    },
    startAdornment: {
        paddingRight: '8px',
    },
    inputProps: {
        height: '36px',
        fontSize: '14px',
    },
}));

interface Props {
    height?: number;
}
interface Data {
    firstName: string;
    lastName: string;
    email: string;
    teams: string;
    id: string;
}

function createData(firstName: string, lastName: string, email: string, teams: string, id: string): Data {
    return { firstName, lastName, email, teams, id };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    id: keyof Data;
    label: string;
}

const headCells = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email' },
    { id: 'teams', label: 'Team(s)' },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id as keyof Data)}
                            className={classes.noWrap}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' && 'sorted descending'}
                                    {order !== 'desc' && 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function TeamManagement({ height }: Props): ReactElement {
    const classes = useStyles();
    const { selectedOrganization } = useSelector((state: Store) => state.Organization);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('lastName');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rows, setRows] = useState<Data[]>([]);
    const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [localValue, setLocalValue] = useState<string>(searchTerm);

    const delayedChange = debounce((newValue: string) => setSearchTerm(newValue), 300);
    const handleSearchInputChange = (event: ChangeEvent<{ value: string }>) => {
        setLocalValue(event.target.value);
        delayedChange(event.target.value);
    };

    const handleClear = () => {
        setLocalValue('');
        setSearchTerm('');
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDelete = () => {
        setAnchorEl(null);
    };
    const handleEdit = () => {
        setAnchorEl(null);
        setActionMenuOpen(false);
    };

    function getTeams(userTeams: UserTeam[]) {
        return userTeams
            .map((userTeam) => userTeam.team.name)
            .join(', ')
            .trim();
    }

    const { data } = useGetUsersByOrganizationQuery({
        skip:
            !selectedOrganization ||
            !selectedOrganization?.organizationId ||
            !selectedOrganization?.organizationId === undefined,
        variables: {
            organizationId: selectedOrganization?.organizationId as number,
        },
        fetchPolicy: 'cache-first',
        // eslint-disable-next-line no-use-before-define
        onCompleted: () => generateTableRows(),
    });

    const generateTableRows = useCallback((): void => {
        if (data) {
            const allUsers = data.usersByOrganization.map(({ firstName, lastName, email, userTeams, id }) =>
                createData(firstName, lastName as string, email, getTeams(userTeams as UserTeam[]), id)
            );
            setRows(allUsers);
        } else {
            setRows([]);
        }
    }, [data]);

    useEffect(() => {
        // Handle other components resetting the search bar
        if (!searchTerm) {
            setLocalValue('');
        }
    }, [searchTerm]);

    const regex = new RegExp(`.*${searchTerm}.*`, 'i');

    return (
        <Grid container className={classes.container} justify="center" data-testid="team-management-container">
            <Grid item xs={7}>
                <Paper>
                    <Toolbar className={classes.tableToolbar}>
                        <Grid container justify="flex-end">
                            <Grid item xs={7}>
                                <TextField
                                    variant="outlined"
                                    value={localValue}
                                    onChange={handleSearchInputChange}
                                    InputProps={{
                                        className: classes.inputProps,
                                        startAdornment: <SearchOutlined className={classes.startAdornment} />,
                                        endAdornment: (
                                            <ClearOutlined
                                                className={classes.endAdornment}
                                                onClick={handleClear}
                                                data-testid="clear-icon"
                                            />
                                        ),
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                        'data-testid': 'search',
                                    }}
                                    placeholder="Search Users"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table} aria-label="team management table">
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {rows &&
                                    stableSort(rows, getComparator(order, orderBy))
                                        .filter((userRow) =>
                                            searchTerm
                                                ? regex.test(
                                                      `${userRow.firstName} ${userRow.lastName} ${userRow.email} ${userRow.teams}`
                                                  )
                                                : userRow
                                        )
                                        .map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{row.firstName}</TableCell>
                                                <TableCell>{row.lastName}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.teams}</TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Menu
                    anchorEl={anchorEl}
                    open={actionMenuOpen}
                    onClose={handleDelete}
                    MenuListProps={{
                        'aria-labelledby': 'actions-menu-button',
                    }}
                >
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </MenuItem>
                    <MenuItem onClick={handleEdit}>
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </MenuItem>
                </Menu>
            </Grid>
        </Grid>
    );
}
