import { Action } from 'store';
import { Tag } from '../../typings/generated';

export const SET_TAGS = 'SET_TAGS';
export const ADD_TAGS = 'ADD_TAGS';

export const setTags = (tags: Tag[]): Action => ({
    type: SET_TAGS,
    payload: { tags },
});

export const addTags = (tags: Tag[]): Action => ({
    type: ADD_TAGS,
    payload: { tags },
});
