import { ReactElement } from 'react';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LicenseInfo } from '@material-ui/x-license';
import { BrowserRouter as Router } from 'react-router-dom';

import Snackbar from '../snackbar/Snackbar';
import Apollo from './Apollo';
import KeyCloak from './Keycloak';
import ReactRouter from './ReactRouter';
import Redux from './Redux';
import ThemeProvider from './Theme';

interface Props {
    children: ReactElement;
}

const Providers = ({ children }: Props): ReactElement => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_LICENSE || '');
    return (
        <Redux>
            <KeyCloak>
                <Apollo>
                    <ThemeProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Router>
                                {children}
                                <ReactRouter />
                                <Snackbar />
                            </Router>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </Apollo>
            </KeyCloak>
        </Redux>
    );
};

export default Providers;
