import { ChangeEvent, ReactElement, useEffect } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { WorkItem } from 'typings';

import useHandleWorkItemChanges, { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import { editPlannedItem } from 'store/actions/WorkItems';
import { AssigneeSelectSubComponentProps } from '../selectInterfaces';

const AssigneeSelectForm = ({
    workItem,
    currentTeamMembers,
    setShowForm,
}: AssigneeSelectSubComponentProps): ReactElement | null => {
    const id = `assignee-select-${workItem.workItemId}`;
    const dispatch = useDispatch();
    const { control, errors, handleSubmit, getValues } = useForm();

    const onUpdateWorkItem: UpdateWorkItemHandler = (updatedWorkItem) => {
        dispatch(editPlannedItem(updatedWorkItem as WorkItem, updatedWorkItem.parentWorkItemId as number | undefined));
        setShowForm(false);
    };

    const { editingField, setEditingField, onSubmit, onError, handleSelectOnChange } = useHandleWorkItemChanges({
        workItem,
        onUpdateWorkItem,
        getValues,
    });

    useEffect(() => {
        if (editingField === null && setEditingField) {
            setEditingField('assignees');
        }
    }, [editingField, setEditingField]);

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <label htmlFor="assignees">
                <Controller
                    control={control}
                    render={({ onChange, value }) => (
                        <Select
                            id={id}
                            variant="outlined"
                            color="primary"
                            error={errors.assignees}
                            open={editingField === 'assignees'}
                            onClose={() => setShowForm(false)}
                            value={value}
                            onChange={(event: ChangeEvent<unknown>) => {
                                onChange(event);
                                handleSelectOnChange();
                            }}
                            fullWidth
                            inputProps={{ 'data-testid': 'edit-assignees' }}
                        >
                            <MenuItem key={-1} value="">
                                Unassigned
                            </MenuItem>
                            {currentTeamMembers?.usersByTeam.map((currentTeamMember) => {
                                if (`${currentTeamMember.firstName} ${currentTeamMember.lastName}` !== 'Super Admin') {
                                    return (
                                        <MenuItem key={currentTeamMember.id} value={currentTeamMember.id}>
                                            {currentTeamMember.firstName} {currentTeamMember.lastName}
                                        </MenuItem>
                                    );
                                }
                                return null;
                            })}
                        </Select>
                    )}
                    name="assignees"
                    defaultValue={workItem?.assignees?.[0]?.userId || ''}
                    rules={{ required: false }}
                />
            </label>
        </form>
    );
};

export default AssigneeSelectForm;
