import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useState } from 'react';

import { Button, Chip, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import _, { trim } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';

import FieldTitle from 'components/formUtils/FieldTitle';
import { addView } from 'store/actions/View';
import { Store } from 'store/reducers';
import { ExtendedView } from 'store/reducers/View';
import { FilterWorkItemInput, useCreateViewMutation, View } from 'typings/generated';
import ConfirmModal from '../modal/ConfirmModal';

interface SaveViewProps {
    setTabIndex: Dispatch<SetStateAction<number>>;
    openViews: ExtendedView[];
}

const useStyles = makeStyles((theme: Theme) => ({
    chipContainer: {
        '& .MuiChip-root': {
            borderColor: '#a45910',
            color: '#a45910',
            fontWeight: 600,
            marginRight: theme.spacing(0.5),
        },
    },
    confirmModal: {
        '& .MuiDialog-paperWidthMd': {
            maxWidth: theme.spacing(58),
        },
    },
    characterLimit: {
        textAlign: 'end',
    },
}));

const SaveView = ({ setTabIndex, openViews }: SaveViewProps): ReactElement => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { view } = useSelector((state: Store) => state.SelectedView);
    const [name, setName] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [executeCreateView] = useCreateViewMutation({
        onCompleted: (data) => {
            dispatch(addView(data.createView as View));
            setTabIndex(openViews.length);
            setIsOpen(false);
            setName('');
        },
    });

    const { email } = useSelector((state: Store) => state.User);
    const labeledValues = [
        { key: 'Group by', value: view?.groupBy },
        { key: 'View Type', value: 'Table' },
        { key: 'Division', value: 'TBD' },
    ];

    const onClick = () => setIsOpen(true);
    const onClose = () => {
        setIsOpen(false);
        setName('');
    };

    const onConfirm = () =>
        executeCreateView({
            variables: {
                data: {
                    email,
                    name,
                    viewTypeId: 1,
                    isOpen: true,
                    groupBy: view?.groupBy,
                    filter: JSON.parse(view?.filters || '{}') as FilterWorkItemInput,
                },
            },
        });

    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value);

    return (
        <>
            <Button color="primary" variant="outlined" onClick={onClick} disabled={!view}>
                Save View As...
            </Button>
            <ConfirmModal
                className={classes.confirmModal}
                isOpen={isOpen}
                title="Save View As..."
                confirmText="Confirm"
                confirmDisabled={trim(name).length === 0}
                onConfirm={onConfirm}
                confirmVariant="contained"
                onCancel={onClose}
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FieldTitle title="View Settings" />
                        </Grid>
                        <Grid className={classes.chipContainer} item xs>
                            {labeledValues.map((labeledValue) => (
                                <Chip
                                    key={labeledValue.key}
                                    variant="outlined"
                                    label={`${labeledValue.key}: ${_.startCase(labeledValue.value as string)}`}
                                    size="small"
                                />
                            ))}
                        </Grid>
                        {/* {Object.entries(JSON.parse(view.filters)).map(([key, value]) => {

                        })} */}
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <FieldTitle title="*View Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor="name">
                                    <TextField
                                        required
                                        id="name"
                                        variant="outlined"
                                        value={name}
                                        onChange={onNameChange}
                                        fullWidth
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </label>
                            </Grid>
                            <Grid className={classes.characterLimit} item xs={12}>
                                <FieldTitle title={`${name?.length || 0}/30 Characters`} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            />
        </>
    );
};

export default SaveView;
