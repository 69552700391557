import { Snackbar } from '../../typings/snackbar';
import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from '../actions/Snackbar';
/* eslint-disable no-param-reassign */
import { Action } from '../index';

export interface State extends Snackbar {
    open: boolean;
}

const initialState: State = {
    message: '',
    open: false,
    actionName: undefined,
    onActionClick: undefined,
};

export default (prevState: State, action: Action): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;
    switch (type) {
        case OPEN_SNACKBAR: {
            return { ...state, ...payload.snackbar, open: true };
        }
        case CLOSE_SNACKBAR: {
            return {
                ...state,
                message: '',
                actionName: undefined,
                onActionClick: undefined,
                anchorOrigin: undefined,
                variant: undefined,
                open: false,
            };
        }
        default:
            return { ...state };
    }
};
/* eslint-enable no-param-reassign */
