import Avatar from '@material-ui/core/Avatar';

interface Assignee {
    assigneeName: string;
    assigneeAvatar?: string | null;
    className?: string;
}

export default ({ assigneeName, assigneeAvatar, className }: Assignee): JSX.Element => {
    if (assigneeAvatar && assigneeAvatar !== null) {
        return <Avatar alt={assigneeName} src={assigneeAvatar} className={className} />;
    }
    return (
        <Avatar alt={assigneeName} className={className}>
            {assigneeName.charAt(0)}
        </Avatar>
    );
};
