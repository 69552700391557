import getStatusId from 'components/createWorkItem/getStatusId';
import {
    CreateWorkItemForeignKeys,
    CreateWorkItemInput,
    Maybe,
    MutationCreateWorkItemArgs,
    Status,
    Tag,
    Team,
    UserTeam,
    WorkItemUser,
} from 'typings/generated';

export const groupFilters = {
    DEADLINE: 'deadline',
    STATUS: 'status',
    ASSIGNEE: 'assignee',
    TAGS: 'tags',
    PRIORITY: 'priority',
    DIVISION: 'division',
    ALL: '',
};

const quickAddUtility = (
    inputValue: string,
    groupByValue: unknown,
    groupBy: Maybe<string> | undefined,
    statuses: Status[],
    teamId: number,
    userTeams: UserTeam[]
): MutationCreateWorkItemArgs => {
    const value = groupByValue || null;
    const priorityValue = (): string => {
        if (
            groupByValue === 'Critical' ||
            groupByValue === 'High' ||
            groupByValue === 'Normal' ||
            groupByValue === 'Low'
        ) {
            return groupByValue as string;
        }
        return '';
    };
    let data: CreateWorkItemInput = { name: inputValue };
    let foreignKeys: CreateWorkItemForeignKeys = { itemTypeId: 1, statusId: getStatusId(statuses, false, {}), teamId };
    switch (groupBy) {
        case groupFilters.DEADLINE: {
            data = {
                ...data,
                dueDate: value,
            };
            break;
        }
        case groupFilters.STATUS: {
            foreignKeys = {
                ...foreignKeys,
                statusId: (value as Status).statusId,
            };
            break;
        }
        case groupFilters.ASSIGNEE: {
            foreignKeys = {
                ...foreignKeys,
                assignees: value ? [(value as WorkItemUser).userId] : [],
            };
            break;
        }
        case groupFilters.TAGS: {
            foreignKeys = {
                ...foreignKeys,
                tags: [(value as Tag).tagId],
            };
            break;
        }
        case groupFilters.PRIORITY: {
            data = {
                ...data,
                urgencyScale: priorityValue(),
            };
            break;
        }
        case groupFilters.DIVISION: {
            data = {
                ...data,
                isRequested: !userTeams?.some((userTeam: UserTeam) => userTeam.teamId === (value as UserTeam).teamId),
            };
            foreignKeys = {
                ...foreignKeys,
                teamId: (value as Team).teamId,
            };
            break;
        }
        default: {
            break;
        }
    }
    return { data, foreignKeys };
};

export default quickAddUtility;
