import { Grid, makeStyles, Theme } from '@material-ui/core';
import { defaultBorder } from 'assets/themes/base';
import { FunctionComponent, ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    sidePanel: {
        height: '100%',
        minWidth: '400px',
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    grid: {
        background: theme.palette.background.paper,
        gridTemplateColumns: '410px .8fr',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '250px 1fr',
        },
        height: '100%',
    },
    spacer: {
        paddingTop: theme.spacing(4),
        padding: theme.spacing(2),
    },
    main: {
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
        background: theme.palette.background.default,
        border: defaultBorder(theme),
        borderTop: 'none',
    },
}));

interface Props {
    leftPanel?: ReactElement | ReactElement[] | null;
    rightPanel?: ReactElement | ReactElement[] | null;
}

const SidePanelLayout: FunctionComponent<Props> = ({ children, leftPanel, rightPanel }) => {
    const classes = useStyles();

    return (
        <Grid container direction="row" alignItems="flex-start" className={classes.grid}>
            <Grid item container xs={2} className={classes.sidePanel}>
                <Grid item className={classes.spacer} xs={12}>
                    {leftPanel}
                </Grid>
            </Grid>
            <Grid item xs container className={classes.main}>
                <Grid item container className={classes.spacer}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={2} className={classes.sidePanel}>
                <Grid item className={classes.spacer} xs={12}>
                    {rightPanel}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SidePanelLayout;
