import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortByName } from 'utils/sortBy';
import { setStorageItem } from 'utils/TypeSafeStorage';
import { setSelectedTeam } from '../../store/actions/Team';
import { Store } from '../../store/reducers';
import { Team, UserTeam } from '../../typings/generated';
import './TeamSelector.scss';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: 0,
        padding: 0,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default (): JSX.Element => {
    const dispatch = useDispatch();
    const { selectedTeam } = useSelector((state: Store) => state.Team);
    const user = useSelector((state: Store) => state.User);
    const classes = useStyles();

    const teams = user.userTeams?.map((userTeam: UserTeam) => userTeam.team);
    const teamsAlphabetized = sortByName(teams);

    const onChange = (event: ChangeEvent<{ value: any }>): void => {
        const { value }: { value: Team['teamId'] } = event.target;
        const selected = teams.find((team: Team) => team.teamId === value);
        if (selected) {
            dispatch(setSelectedTeam(selected));
            setStorageItem('selected-team', selected);
        }
    };

    return (
        <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="theme-select-label">Team</InputLabel>
            <Select
                color="primary"
                labelId="theme-select-label"
                id="theme-select"
                disableUnderline
                value={selectedTeam?.teamId || ''}
                onChange={onChange}
            >
                {teamsAlphabetized.map((t) => (
                    <MenuItem key={t.teamId} value={t.teamId}>
                        {t.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
