import { Theme } from '@material-ui/core';

import { dark, darkHighContrast, light, lightHighContrast } from '../../assets/themes';
import { SET_THEME } from '../actions/MuiTheme';

const localStorageKey = 'prioritize-theme';
export type ThemeTypes = 'dark' | 'light' | 'darkHighContrast' | 'lightHighContrast';

export interface ThemePayload {
    theme: ThemeTypes;
}

export interface ThemeState {
    theme: Theme;
    name: ThemeTypes;
    colorScheme: 'dark' | 'light';
}

const getThemeState = (themeName: ThemeTypes | null): ThemeState => {
    switch (themeName) {
        case 'light': {
            return { theme: light, name: 'light', colorScheme: 'light' };
        }
        case 'lightHighContrast': {
            return { theme: lightHighContrast, name: 'lightHighContrast', colorScheme: 'light' };
        }
        case 'dark': {
            return { theme: dark, name: 'dark', colorScheme: 'dark' };
        }
        case 'darkHighContrast': {
            return { theme: darkHighContrast, name: 'darkHighContrast', colorScheme: 'dark' };
        }
        default: {
            return { theme: light, name: 'light', colorScheme: 'light' };
        }
    }
};

const storage = localStorage.getItem(localStorageKey);
const initialState: ThemeState = getThemeState(storage as ThemeTypes);

interface Request {
    type: string;
    payload: ThemePayload;
}

const MuiTheme = (prevState: ThemeState, { type, payload }: Request): ThemeState => {
    const state = prevState ?? initialState;

    // Change this back to a switch statement when the actions exceed 2
    // sonarqube requires at least 3 cases in a switch

    if (type === SET_THEME) {
        // Save to local storage
        localStorage.setItem(localStorageKey, payload.theme);
        return { ...state, ...getThemeState(payload.theme) };
    }
    return { ...state };
};

export default MuiTheme;
