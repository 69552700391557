import { Status } from '../../typings';

type Data = { [x: string]: string | number };

export default (statuses: Status[], requested: boolean, formData: Data): number => {
    let statusId: number;
    if (requested) {
        statusId = statuses.find((element) => element.name === 'Unplanned')!.statusId;
    } else if (formData.statusId) {
        statusId = formData.statusId as number;
    } else {
        statusId = statuses.find((element) => element.name === 'Not Started')!.statusId;
    }

    return statusId;
};
