import { ThemePayload } from '../reducers/MuiTheme';

export const SET_THEME = 'SET_THEME';

interface Theme {
    type: string;
    payload: ThemePayload;
}

export const setTheme = (payload: ThemePayload): Theme => ({
    type: SET_THEME,
    payload,
});
