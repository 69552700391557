import { gql } from '@apollo/client';

export const UPDATE_WORK_ITEM = gql`
    mutation UpdateWorkItem(
        $workItemId: Float!
        $foreignKeys: UpdateWorkItemForeignKeys
        $data: UpdateWorkItemInput!
        $files: [Upload!]
        $sizes: [Float!]
    ) {
        updateWorkItem(workItemId: $workItemId, foreignKeys: $foreignKeys, data: $data, files: $files, sizes: $sizes) {
            workItemId
            parentWorkItemId
            name
            priority
            effort
            dueDate
            startDate
            description
            urgencyScale
            requester
            attachments {
                attachmentId
                modifiedDate
                path
                fileName
                size
                isGoogleDriveFile
            }
            itemType {
                itemTypeId
                name
                code
            }
            team {
                teamId
                name
                dept
            }
            statusId
            status {
                statusId
                name
                icon
            }
            officeId
            specialInstruction
            requestOriginator
            receivedDate
            isRequested
            externalReferenceNumber
            assignedTeam
            assignedLead
            assignedApprover
            isRemoved
            children {
                name
                workItemId
                parentWorkItemId
                dueDate
                createdDate
                statusId
                requester
                teamId
                isRemoved
                attachments {
                    attachmentId
                    modifiedDate
                    path
                    fileName
                    size
                    isGoogleDriveFile
                }
                assignees {
                    userId
                    user {
                        firstName
                        lastName
                        avatar
                    }
                }
            }
            history {
                field
                oldValue
                newValue
                createdDate
                user {
                    lastName
                    firstName
                }
            }
            workItemParties {
                workItemPartyId
                isRemoved
                partyId
                partyTypeId
                partyType {
                    partyTypeId
                    name
                    code
                }
                party {
                    partyId
                    name
                    workPhone
                    workPhoneExtension
                    cellPhone
                    preferredMethod
                    emailAddress
                }
            }
            assignees {
                userId
                user {
                    firstName
                    lastName
                    avatar
                }
            }
            workItemTags {
                tag {
                    name
                    tagId
                }
                workItemTagId
            }
        }
    }
`;

export const CREATE_WORK_ITEM = gql`
    mutation CreateWorkItem(
        $data: CreateWorkItemInput!
        $foreignKeys: CreateWorkItemForeignKeys!
        $files: [Upload!]
        $sizes: [Float!]
    ) {
        createWorkItem(data: $data, foreignKeys: $foreignKeys, files: $files, sizes: $sizes) {
            workItemId
            name
            priority
            effort
            createdDate
            dueDate
            startDate
            description
            specialInstruction
            urgencyScale
            requester
            attachments {
                attachmentId
                path
                fileName
            }
            itemType {
                itemTypeId
                name
                code
            }
            teamId
            team {
                teamId
                name
                dept
            }
            statusId
            status {
                statusId
                name
                icon
            }
            isRemoved
            isRequested
            assignees {
                userId
                user {
                    firstName
                    lastName
                    avatar
                }
            }
            workItemTags {
                tag {
                    name
                    tagId
                }
                workItemTagId
            }
        }
    }
`;

export const DELETE_WORK_ITEM = gql`
    mutation DeleteWorkItem($workItemId: Float!) {
        deleteWorkItem(workItemId: $workItemId) {
            workItemId
            isRemoved
        }
    }
`;

export const DUPLICATE_WORK_ITEM = gql`
    mutation duplicateWorkItem($workItemId: Float!) {
        duplicateWorkItem(data: { workItemId: $workItemId }) {
            workItemId
            name
            priority
            effort
            createdDate
            dueDate
            startDate
            description
            specialInstruction
            urgencyScale
            requester
            attachments {
                attachmentId
                path
                fileName
            }
            itemType {
                itemTypeId
                name
                code
            }
            teamId
            team {
                teamId
                name
                dept
            }
            statusId
            status {
                statusId
                name
                icon
            }
            isRemoved
            isRequested
            assignees {
                userId
                user {
                    firstName
                    lastName
                    avatar
                }
            }
        }
    }
`;

export default { UPDATE_WORK_ITEM, DELETE_WORK_ITEM };
