import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import FieldTitle from 'components/formUtils/FieldTitle';
import { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';

export interface FormData {
    name: string;
    page: string;
    notes: string;
}

interface FeedbackDialogProps {
    isOpen: boolean;
    onCancel: () => void;
    onSubmit: (formData: FormData) => void;
}

const FeedbackDialog = ({ isOpen, onCancel, onSubmit }: FeedbackDialogProps): ReactElement => {
    const methods = useForm({ defaultValues: { name: '', page: 'N/A', notes: '' } });
    const { control, handleSubmit, errors } = methods;

    return (
        <Dialog open={isOpen} scroll="paper" maxWidth="md">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs={12} justify="flex-end">
                    <IconButton aria-label="close" data-testid="close-button" size="small" onClick={onCancel}>
                        <Close />
                    </IconButton>
                </Grid>
                <DialogTitle>Submit Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We value your feedback so that we can better improve our product.
                    </DialogContentText>
                    <Grid container spacing={4}>
                        <Grid item xs={6} container>
                            <Grid item xs={12}>
                                <FieldTitle title="Your Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor="name">
                                    <Controller
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <TextField
                                                id="name"
                                                variant="outlined"
                                                color="primary"
                                                value={value}
                                                onChange={onChange}
                                                fullWidth
                                                error={!!errors.name}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ 'data-testid': 'edit-name' }}
                                            />
                                        )}
                                        name="name"
                                        inputProps={{ 'data-testid': 'name' }}
                                        rules={{ required: true, minLength: 1 }}
                                    />
                                </label>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} container>
                            <Grid item xs={12}>
                                <FieldTitle title="Page/Location" />
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor="page">
                                    <Controller
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <TextField
                                                select
                                                id="page"
                                                variant="outlined"
                                                color="primary"
                                                value={value}
                                                onChange={onChange}
                                                fullWidth
                                                error={!!errors.page}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ 'data-testid': 'edit-page' }}
                                            >
                                                <MenuItem value="N/A">N/A</MenuItem>
                                                <MenuItem value="List View">List View</MenuItem>
                                                <MenuItem value="Work Library">Work Library</MenuItem>
                                                <MenuItem value="Work Details">Work Details</MenuItem>
                                            </TextField>
                                        )}
                                        name="page"
                                        inputProps={{ 'data-testid': 'page' }}
                                        rules={{ required: true, minLength: 1 }}
                                    />
                                </label>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={12}>
                                <FieldTitle title="Description" />
                            </Grid>
                            <Grid item xs={12}>
                                <label htmlFor="description">
                                    <Controller
                                        control={control}
                                        render={({ onChange, value }) => (
                                            <TextField
                                                multiline
                                                rows={12}
                                                rowsMax={12}
                                                id="description"
                                                variant="outlined"
                                                color="primary"
                                                value={value}
                                                onChange={onChange}
                                                fullWidth
                                                error={!!errors.notes}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ 'data-testid': 'edit-notes' }}
                                            />
                                        )}
                                        name="notes"
                                        inputProps={{ 'data-testid': 'notes' }}
                                        rules={{ required: true, minLength: 1 }}
                                    />
                                </label>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Submit Feedback
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default FeedbackDialog;
