import { Action } from 'store';
import { WorkItem } from 'typings/generated';

export const SET_SELECTED_WORK_ITEM = 'SET_SELECTED_WORK_ITEM';
export const CLEAR_SELECTED_WORK_ITEM = 'CLEAR_SELECTED_WORK_ITEM';
export const SET_IS_DIRTY = 'SET_IS_DIRTY';
export const UPDATE_PARENT = 'UPDATE_PARENT';
export const UPDATE_CHILD = 'UPDATE_CHILD';
export const ADD_CHILDREN = 'ADD_CHILDREN';
export const REMOVE_CHILD = 'REMOVE_CHILD';

export const setSelectedWorkItem = (selectedWorkItem: WorkItem): Action => ({
    type: SET_SELECTED_WORK_ITEM,
    payload: { selectedWorkItem },
});

export const updateParentWorkItem = (modifiedWorkItem: Partial<WorkItem>): Action => ({
    type: UPDATE_PARENT,
    payload: { modifiedWorkItem },
});

export const clearSelectedWorkItem = (): Action => ({
    type: SET_SELECTED_WORK_ITEM,
    payload: {},
});

export const updateChildWorkItem = (workItemId: number, modifiedWorkItem: Partial<WorkItem>): Action => ({
    type: UPDATE_CHILD,
    payload: { modifiedWorkItem, id: workItemId },
});

export const addChildren = (workItems: WorkItem[]): Action => ({
    type: ADD_CHILDREN,
    payload: { items: workItems },
});

export const removeChild = (workItemId: number): Action => ({
    type: REMOVE_CHILD,
    payload: { id: workItemId },
});

export const setIsDirty = (isDirty: boolean): Action => ({
    type: SET_IS_DIRTY,
    payload: { isDirty },
});
