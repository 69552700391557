import { ReactElement, useState } from 'react';
import { SelectProps } from '../selectInterfaces';
import DeadlineSelectForm from './DeadlineSelectForm';
import DeadlineSelectView from './DeadlineSelectView';

const DeadlineSelect = (props: SelectProps): ReactElement => {
    const [showForm, setShowForm] = useState(false);
    return showForm ? (
        <DeadlineSelectForm setShowForm={setShowForm} {...props} />
    ) : (
        <DeadlineSelectView setShowForm={setShowForm} {...props} />
    );
};

export default DeadlineSelect;
