import { Button, makeStyles, Typography } from '@material-ui/core';
import useIsATeamMember from 'hooks/useIsATeamMember';
import { ReactElement } from 'react';

interface Props {
    onClickCreateSubtask: () => void;
    teamId: number;
}

const useStyles = makeStyles(() => ({
    createSubtaskButton: {
        maxWidth: 'fit-content',
    },
    createSubtaskButtonText: {
        fontSize: '0.875em',
        fontWeight: 'bold',
    },
}));

export default function CreateSubtaskButton({ onClickCreateSubtask, teamId }: Props): ReactElement {
    const classes = useStyles();
    const isATeamMember = useIsATeamMember({ belongsToTeamId: teamId });
    return (
        <Button
            className={classes.createSubtaskButton}
            disabled={!isATeamMember}
            variant="text"
            color="primary"
            onClick={onClickCreateSubtask}
            data-testid="createSubtaskButton"
        >
            <Typography className={classes.createSubtaskButtonText}>+ Create Subtask</Typography>
        </Button>
    );
}
