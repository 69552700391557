import { View } from 'typings/generated';
import { CLEAR_SELECTED_VIEW, SET_SELECTED_VIEW } from '../actions/SelectedView';
import { Action } from '../index';

export interface State {
    view: View | undefined;
    selectedView?: boolean;
}

const initialState: State = {
    view: undefined,
    selectedView: undefined,
};

export default (prevState: State, action: Action): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;
    switch (type) {
        case SET_SELECTED_VIEW: {
            return { ...state, view: payload.selectedView };
        }
        case CLEAR_SELECTED_VIEW: {
            return { ...state, view: undefined };
        }
        default:
            return { ...state };
    }
};
