import { createContext } from 'react';

import { GridSpacing } from '@material-ui/core';

type State = {
    tabIndex: number;
    setTabIndex: (index: number) => void;
    gridSpacing: GridSpacing;
    unavailable: string;
};

const init: State = {
    tabIndex: 0,
    setTabIndex: (index: number) => undefined, // eslint-disable-line
    gridSpacing: 3,
    unavailable: 'N/a',
};

export const AppContext = createContext<State>(init);

export default AppContext;
