import { gql } from '@apollo/client';

export const GET_DOWNLOAD_URL = gql`
    query DownloadAttachment($attachmentId: Float!) {
        downloadAttachment(attachmentId: $attachmentId) {
            url
            fileName
        }
    }
`;

export const DELETE_ATTACHMENT = gql`
    mutation DeleteAttachment($attachmentId: Float!) {
        deleteAttachment(attachmentId: $attachmentId)
    }
`;

export const GET_ATTACHMENTS = gql`
    query GetAttachments($workItemId: Float!) {
        attachments(workItemId: $workItemId) {
            attachmentId
            modifiedDate
            fileName
            size
            isGoogleDriveFile
            path
        }
    }
`;

export default { GET_DOWNLOAD_URL, DELETE_ATTACHMENT };
