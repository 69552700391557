import { Grid, Typography } from '@material-ui/core';

export default (): JSX.Element => {
    return (
        <Grid container justify="center" alignContent="center" alignItems="center">
            <Grid item xs="auto">
                <Typography variant="h1" color="textPrimary">
                    Page Not Found
                </Typography>
            </Grid>
        </Grid>
    );
};
