import { ReactElement } from 'react';

import { Checkbox, Chip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface PriorityAutoCompleteProps {
    disableCreation?: boolean;
    onChange: (newValue: Array<string>) => void;
    value: string[];
    id: string;
}
const PriorityAutoComplete = ({
    disableCreation,
    onChange,
    id,
    value: priorityValue,
}: PriorityAutoCompleteProps): ReactElement => {
    const options = ['Low', 'Normal', 'High', 'Critical'];
    return (
        <Autocomplete
            multiple
            id={id}
            value={priorityValue}
            onChange={(_event, newValue) => onChange(newValue)}
            options={options}
            freeSolo={!disableCreation}
            renderOption={(option, { selected }) => (
                <>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        color="primary"
                        checked={selected}
                    />
                    {option}
                </>
            )}
            renderTags={(value: string[], getPriorityProps) =>
                value.map((option, index: number) => (
                    <Chip variant="outlined" label={option} size="small" {...getPriorityProps({ index })} />
                ))
            }
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Enter a priority..." />}
        />
    );
};

export default PriorityAutoComplete;
