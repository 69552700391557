import { useSelector } from 'react-redux';
import { Store } from 'store/reducers';
import { Status } from 'typings';

const useGetCompletedStatuses = (): Status[] => {
    const { statuses } = useSelector((state: Store) => state.Status);
    const statusCompleted = statuses.find((status: Status) => status.name === 'Completed');
    const statusClosed = statuses.find((status: Status) => status.name === 'Closed');
    const statusNegativeResponse = statuses.find((status: Status) => status.name === 'Negative Response');

    if (!statusCompleted || !statusClosed || !statusNegativeResponse) {
        return [];
    }

    return [statusCompleted, statusClosed, statusNegativeResponse];
};

export default useGetCompletedStatuses;
