import { ReactElement, useEffect, useState } from 'react';

import {
    Avatar as AvatarIcon,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Popover,
    Theme,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { UserTeam } from 'typings';

import { getContrastText } from 'assets/themes/base';
import TeamSelector from 'components/teamSelector/TeamSelector';
// import ThemeSelector from 'components/ThemeSelector';
import { openSnackbar } from 'store/actions/Snackbar';
import { Store } from 'store/reducers';
import { useUpdateUserMutation } from 'typings/generated';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(4),
        maxWidth: 300,
        background: theme.palette.background.default,
    },
    icon: {
        backgroundColor: getContrastText(theme, theme.palette.type === 'light'),
        color: getContrastText(theme, theme.palette.type === 'dark'),
    },
    padding: {
        marginTop: theme.spacing(2),
    },
}));

export default function Avatar(): ReactElement {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [keycloak] = useKeycloak();
    const [approvedTeamAlert, setApprovedTeamAlert] = useState(false);
    const isLoggedIn = keycloak?.authenticated;
    const user = useSelector((state: Store) => state.User);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [userTeams, setUserTeams] = useState('');
    const [updateParty] = useUpdateUserMutation({
        onCompleted: (response) => {
            setApprovedTeamAlert(response.updateUser.hasApprovedTeamAlerts);
        },
        onError({ graphQLErrors }) {
            graphQLErrors.forEach(({ message }) => dispatch(openSnackbar({ message })));
        },
    });

    useEffect(() => {
        const teams = user?.userTeams
            ?.map((team: UserTeam) => team.team.name)
            .join(', ')
            .trim();

        setUserTeams(teams);
    }, [user]);

    useEffect(() => {
        setApprovedTeamAlert(user.hasApprovedTeamAlerts);
    }, [user]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateParty({
            variables: { email: user.email, data: { hasApprovedTeamAlerts: event.target.checked } },
        });
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const userInitials = user.firstName && user.lastName && `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;

    return (
        <div>
            <IconButton onClick={handleClick} aria-label="Avatar">
                <AvatarIcon className={classes.icon} data-testid="profile-navicon">
                    {userInitials}
                </AvatarIcon>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid container spacing={2} className={classes.container}>
                    <Grid container item spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h2">{`${user.firstName} ${user.lastName}`}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="h3">
                                {user.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2">{userTeams}</Typography>
                        </Grid>
                        {/* <Grid item xs={12} className={classes.padding}>
                            <Divider />
                        </Grid> */}
                        {/* <Grid item container justify="flex-start" className={classes.padding}>
                            <ThemeSelector />
                        </Grid> */}

                        <Grid item xs={12} className={classes.padding}>
                            <Divider />
                        </Grid>
                        <Grid item container justify="flex-start" className={classes.padding}>
                            <TeamSelector />
                        </Grid>
                        <Grid item container justify="flex-start" className={classes.padding}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={approvedTeamAlert}
                                        onChange={handleChange}
                                        name="checkedA"
                                        data-testid="tasker-switch"
                                        color="primary"
                                    />
                                }
                                label={<Typography variant="button">Approve Team Alerts</Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container item justify="flex-end">
                        <Grid item>
                            {isLoggedIn ? (
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => {
                                        keycloak.logout({ redirectUri: window.location.origin });
                                        sessionStorage.clear();
                                        localStorage.clear();
                                    }}
                                >
                                    Logout
                                </Button>
                            ) : (
                                <Button variant="text" color="primary" onClick={() => keycloak.login()}>
                                    Login
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </div>
    );
}
