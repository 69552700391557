import { SHOW_MODAL } from '../actions/Modal';
import { Action } from '../index';

export interface State {
    show?: boolean;
    title?: string;
    content?: string;
}

const initialState: State = {
    show: false,
    title: '',
    content: '',
};

export default (prevState: State, action: Action): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;

    // Change this back to a switch statement when the actions exceed 2
    // sonarqube requires at least 3 cases in a switch

    if (type === SHOW_MODAL) {
        return { ...state, ...payload };
    }

    return { ...state };
};
