import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { Organization } from 'typings/organization';
import { WorkItem } from '../typings';
import { Filters } from '../typings/filters';
import { Tag, Team, View, WorkItem as GeneratedWorkItem } from '../typings/generated';
import { ItemType } from '../typings/itemType';
import { Snackbar } from '../typings/snackbar';
import { Status } from '../typings/status';
import { loadState } from './middlewares';
import rootReducer from './reducers/index';

const persistedState = loadState(); // get localStorage state
const initialState = persistedState || {};

export interface Action {
    type: string;
    payload: Partial<{
        selectedWorkItem: GeneratedWorkItem;
        id: number;
        item: WorkItem | GeneratedWorkItem;
        parentWorkItemId: number;
        items: WorkItem[] | GeneratedWorkItem[];
        newPriority: number;
        selected: WorkItem;
        statuses: Status[];
        itemTypes: ItemType[];
        teams: Team[];
        startDate: string;
        endDate: string;
        team: Team;
        searchTerm: string;
        filters: Filters;
        snackbar: Snackbar;
        isDirty: boolean;
        organizations: Organization[];
        organization: Organization;
        allItems: WorkItem[];
        modifiedWorkItem: Partial<GeneratedWorkItem>;
        tags: Tag[];
        views: View[];
        view: Partial<View>;
        selectedView: View;
    }>;
}

const composeEnhancers =
    process.env.NODE_ENV !== 'development' ? compose() : composeWithDevTools({ trace: true, traceLimit: 10 });

export default createStore(rootReducer, initialState, composeEnhancers(applyMiddleware()));
