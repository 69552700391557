import { ChangeEvent, ReactElement, useEffect } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import useHandleWorkItemChanges, { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import { editPlannedItem } from 'store/actions/WorkItems';
import { WorkItem } from 'typings/generated';
import { PrioritySelectSubComponentProps } from '../selectInterfaces';

const PrioritySelectForm = ({
    workItem,
    priorities,
    setShowForm,
    isSubtask = false,
}: PrioritySelectSubComponentProps): ReactElement => {
    const dispatch = useDispatch();
    const { control, errors, handleSubmit, getValues } = useForm();
    const id = `priority-select-${workItem.workItemId}`;

    const onUpdateWorkItem: UpdateWorkItemHandler = (updatedWorkItem) => {
        dispatch(editPlannedItem(updatedWorkItem as WorkItem, updatedWorkItem.parentWorkItemId as number | undefined));
        setShowForm(false);
    };

    const { editingField, setEditingField, onSubmit, onError, handleSelectOnChange } = useHandleWorkItemChanges({
        workItem,
        onUpdateWorkItem,
        getValues,
    });

    useEffect(() => {
        if (editingField === null && setEditingField) {
            setEditingField('urgencyScale');
        }
    }, [editingField, setEditingField]);

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <label htmlFor={id}>
                <Controller
                    control={control}
                    render={({ onChange, value }) => (
                        <Select
                            id={id}
                            variant="outlined"
                            color="primary"
                            error={errors.urgencyScale}
                            open={editingField === 'urgencyScale'}
                            onClose={() => setShowForm(false)}
                            value={value}
                            onChange={(event: ChangeEvent<unknown>) => {
                                onChange(event);
                                handleSelectOnChange();
                            }}
                            fullWidth
                        >
                            {priorities.map((priority) => (
                                <MenuItem key={priority} value={priority}>
                                    {priority}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    name="urgencyScale"
                    defaultValue={workItem.urgencyScale}
                    rules={{ required: false }}
                />
            </label>
        </form>
    );
};

export default PrioritySelectForm;
