import { MenuItem, Select } from '@material-ui/core';
import useHandleWorkItemChanges, { UpdateWorkItemHandler } from 'hooks/useHandleWorkItemChanges';
import { ChangeEvent, ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { editPlannedItem } from 'store/actions/WorkItems';
import { WorkItem } from 'typings/generated';
import { StatusSelectSubComponentProps } from '../selectInterfaces';

const StatusSelectForm = ({
    workItem,
    statuses,
    setShowForm,
    isSubtask = false,
}: StatusSelectSubComponentProps): ReactElement => {
    const dispatch = useDispatch();
    const { control, errors, handleSubmit, getValues } = useForm();
    const id = `status-select-${workItem.workItemId}`;

    const onUpdateWorkItem: UpdateWorkItemHandler = (updatedWorkItem) => {
        dispatch(editPlannedItem(updatedWorkItem as WorkItem, updatedWorkItem.parentWorkItemId as number | undefined));
        setShowForm(false);
    };

    const { editingField, setEditingField, onSubmit, onError, handleSelectOnChange } = useHandleWorkItemChanges({
        workItem,
        onUpdateWorkItem,
        getValues,
    });

    useEffect(() => {
        if (editingField === null && setEditingField) {
            setEditingField('statusId');
        }
    }, [editingField, setEditingField]);

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <label htmlFor={id}>
                <Controller
                    control={control}
                    render={({ onChange, value }) => (
                        <Select
                            id={id}
                            variant="outlined"
                            color="primary"
                            error={errors.statusId}
                            open={editingField === 'statusId'}
                            onClose={() => setShowForm(false)}
                            value={value}
                            onChange={(event: ChangeEvent<unknown>) => {
                                onChange(event);
                                handleSelectOnChange();
                            }}
                            fullWidth
                        >
                            {statuses
                                .filter((status) => status.name !== 'Pending')
                                .map((status) => (
                                    <MenuItem key={status.statusId} value={status.statusId}>
                                        {status.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    )}
                    name="statusId"
                    defaultValue={workItem.statusId}
                    rules={{ required: false }}
                />
            </label>
        </form>
    );
};

export default StatusSelectForm;
