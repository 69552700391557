import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { ClickAwayListener, Grid, makeStyles, Theme } from '@material-ui/core';
import { ContentState, convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import MUIRichTextEditor, { TMUIRichTextEditorRef } from 'mui-rte';

import { defaultBorder } from 'assets/themes/base';
import { WorkItem } from '../../typings/generated';

interface Props {
    label?: string;
    id: string;
    defaultValue?: string | EditorState;
    onChange?: () => void;
    disabled?: boolean;
    onClick?: () => void;
    controls?: string[];
    // Need this when wrapping this component in a "SaveAndCancel" button
    enableAutoFocus?: boolean;
}

export const RICH_TEXT_EDITOR_FIELDS: Array<keyof WorkItem> = ['description', 'specialInstruction'];

export const convertEditorStateToString = (editorState: EditorState): string => {
    // Get the description from the wysiwyg
    return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
};

const getFocusedBorder = (focused: boolean, disabled: boolean, theme: Theme): string => {
    const borderWidth = disabled ? undefined : 2;
    const borderColor = disabled ? undefined : theme.palette.primary.main;
    return focused ? defaultBorder(theme, borderWidth, borderColor) : defaultBorder(theme);
};

const useStyles = makeStyles((theme: Theme) => ({
    container: ({ disabled, focused }: { disabled: boolean; focused: boolean }) => ({
        border: getFocusedBorder(focused, disabled, theme),
        borderRadius: 4,
    }),
}));

const RichTextEditor = ({
    label = '',
    defaultValue,
    id,
    onChange = () => null,
    onClick = () => null,
    disabled = false,
    enableAutoFocus = false,
    controls = ['title', 'bold', 'italic', 'underline', 'strikethrough', 'link', 'numberList', 'bulletList', 'clear'],
}: Props): ReactElement => {
    const [focused, setFocused] = useState(false);
    const classes = useStyles({ disabled, focused });
    const placeholder = label || 'Enter a description of the work';
    const ref = useRef<TMUIRichTextEditorRef>(null);

    const convertStringToRawContentState = (value: string | null): RawDraftContentState => {
        if (value) {
            return convertToRaw(ContentState.createFromText(value));
        }

        return convertToRaw(EditorState.createEmpty().getCurrentContent());
    };

    const getInitialState = useCallback((initialValue: string | EditorState = ''): string => {
        if (!initialValue) {
            return '';
        }

        // Does the string contain a "blocks" key
        const regex = new RegExp(/.*blocks.*/, 'ig');

        if (initialValue && typeof initialValue !== 'string') {
            // If it's an ContentState, convert it to a stringified RawDraftContentState
            return JSON.stringify(convertToRaw(initialValue.getCurrentContent()));
        }

        if (regex.test(initialValue)) {
            // It is a stringified RawDraftContentState, return
            return initialValue;
        }

        // Convert existing description strings to stringified RawDraftContentStates
        return JSON.stringify(convertStringToRawContentState(initialValue));
    }, []);

    // Whenever MUIRichTextEditor's defaultValue changes it resets the cursor back to the start
    // React-Hook-Form onChange event changes "value" which is used as defaultValue and causes the cursor to go back to the start
    // Store the "defaultValue" in state so that it never changes after mounting and then MUIRichTextEditor can treat the value as uncontrolled
    const [parsedValue, setParsedValue] = useState<string | undefined>(getInitialState(defaultValue));

    useEffect(() => {
        const isEditorEnabled = disabled === false;
        // When the state variable is focused
        const isProgrammaticallyFocused = isEditorEnabled && focused;

        if ((enableAutoFocus && isEditorEnabled && ref.current) || isProgrammaticallyFocused) {
            ref.current?.focus();
            setFocused(true);
        }
    }, [enableAutoFocus, ref, disabled, focused]);

    useEffect(() => {
        if (!defaultValue || !focused) {
            // Keep setting the parsed value for the editor until it is defined or focused
            setParsedValue(getInitialState(defaultValue));
        }
    }, [focused, defaultValue, getInitialState]);

    const handleClick = () => {
        if (disabled) {
            onClick();
        }
        setFocused(true);
    };

    return (
        <ClickAwayListener onClickAway={() => setFocused(false)}>
            <Grid container item xs={12} className={classes.container} onClick={handleClick}>
                <MUIRichTextEditor
                    onFocus={handleClick}
                    controls={disabled ? [] : controls}
                    label={placeholder}
                    readOnly={disabled}
                    defaultValue={parsedValue}
                    id={id}
                    onChange={onChange}
                    ref={ref}
                />
            </Grid>
        </ClickAwayListener>
    );
};

export default RichTextEditor;
