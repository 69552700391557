import { Tag } from '../../typings/generated';
import { ADD_TAGS, SET_TAGS } from '../actions/Tag';
/* eslint-disable no-param-reassign */
import { Action } from '../index';

export interface State {
    tags: Tag[];
}

const initialState: State = {
    tags: [],
};

interface Payload {
    tags: Tag[];
}

interface TagAction extends Omit<Action, 'payload'> {
    payload: Payload;
}

export default (prevState: State, action: TagAction): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;

    // Change this back to a switch statement when the actions exceed 2
    // sonarqube requires at least 3 cases in a switch

    if (type === SET_TAGS) {
        return { ...state, tags: payload.tags };
    }

    if (type === ADD_TAGS) {
        return { ...state, tags: [...state.tags, ...payload.tags] };
    }

    return { ...state };
};
/* eslint-enable no-param-reassign */
