import { Button, ButtonProps, Grid } from '@material-ui/core';
import { FunctionComponent } from 'react';

interface Props {
    onCancel: () => void;
    saveDisabled: boolean;
    saveButtonType?: ButtonProps['type'];
    onSaveClick?: ButtonProps['onClick'];
}
const SaveAndCancel: FunctionComponent<Props> = ({
    children,
    onCancel,
    onSaveClick,
    saveDisabled = false,
    saveButtonType = 'submit',
}) => {
    return (
        <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
                {children}
            </Grid>
            <Grid item xs={12} container direction="row-reverse">
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={saveDisabled}
                        type={saveButtonType}
                        size="small"
                        onClick={onSaveClick}
                    >
                        Save
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={onCancel} size="small">
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SaveAndCancel;
