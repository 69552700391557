import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { getContrastText } from 'assets/themes/base';
import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedOrganization } from 'store/actions/Organization';
import { setStorageItem } from 'utils/TypeSafeStorage';
import { Store } from '../../store/reducers';

const useStyles = makeStyles((theme) => ({
    root: {
        color: getContrastText(theme, theme.palette.type === 'light'),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default (): JSX.Element | null => {
    const dispatch = useDispatch();
    const { organizations = [], selectedOrganization } = useSelector((state: Store) => state.Organization);
    const classes = useStyles();

    const onChange = (event: ChangeEvent<{ value: unknown }>): void => {
        const org = organizations.find((o) => o.organizationId === event.target.value);
        if (org?.organizationId) {
            dispatch(setSelectedOrganization(org));
            setStorageItem('selected-organization', org);
        }
    };

    if (organizations.length < 2) {
        return null;
    }

    return (
        <label htmlFor="orgSelector">
            <FormControl className={classes.formControl}>
                <InputLabel className={classes.root} id="organization-select-label">
                    Organization
                </InputLabel>
                <Select
                    value={selectedOrganization?.organizationId || ''}
                    onChange={onChange}
                    disableUnderline
                    classes={{
                        root: classes.root,
                        icon: classes.root,
                    }}
                    inputProps={{ 'data-testid': 'orgSelector', id: `orgSelector` }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    {organizations.map((o) => (
                        <MenuItem key={o.organizationId} value={o.organizationId}>
                            {o.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </label>
    );
};
