import { Button, Dialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routePaths } from 'components/providers/ReactRouter';
import { deleteItem } from '../../store/actions/WorkItems';
import { WorkItem } from '../../typings';
import { useDeleteWorkItemMutation } from '../../typings/generated';

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: 'bold',
    },
    nameSection: {
        paddingTop: '16px',
        paddingBottom: '24px',
    },
    errorColor: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

interface Props {
    isOpen: boolean;
    onClose: () => void;
    workItem?: WorkItem | Partial<WorkItem>;
    descendantCount?: number;
    isSubTask?: boolean;
    handleSubTaskRoute?: () => void;
}

export default ({
    isOpen,
    onClose,
    workItem,
    descendantCount = 0,
    isSubTask,
    handleSubTaskRoute,
}: Props): JSX.Element => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleRoute = () => {
        if (isSubTask && handleSubTaskRoute) {
            handleSubTaskRoute();
        } else {
            history.push(`/${routePaths.WORK_ITEMS}`);
        }
    };

    const [deleteWorkItem] = useDeleteWorkItemMutation({
        onCompleted: (response) => {
            dispatch(deleteItem(response.deleteWorkItem.workItemId));
            onClose();
            handleRoute();
        },
    });

    const classes = useStyles();

    const onConfirm = () => {
        deleteWorkItem({
            variables: {
                workItemId: workItem?.workItemId as number,
            },
        });
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="xs">
            <DialogContent>
                <Typography variant="body1" className={classes.bold}>
                    Delete {isSubTask ? 'subtask:' : 'work item:'}
                </Typography>
                <Typography variant="body1" className={classes.nameSection}>
                    {workItem?.name} {descendantCount > 0 && <b>and {descendantCount} related subtask?</b>}
                </Typography>
                <Typography variant="body1">
                    <i>This cannot be undone.</i>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="primary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" className={classes.errorColor} onClick={onConfirm}>
                    Delete {descendantCount && descendantCount > 0 ? `${descendantCount + 1} Items` : `1 Item`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
