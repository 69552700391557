import { ReactElement } from 'react';

// why do this? Removes potential misspelling and duplicate declaration of types
export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_TASK_EDITOR_MODAL = 'SHOW_TASK_EDITOR_MODAL';

interface Modal {
    type: string;
    payload: {
        show: boolean;
        title: string;
        content: string | ReactElement;
    };
}

// why do this? Makes dispatching a store actions easy and look like a function inside a component
export const showModal = (show: boolean, title: string, content: string): Modal => ({
    type: SHOW_MODAL,
    payload: { show, title, content },
});

export const showTaskEditorModal = (show: boolean, title: string, content: string): Modal => ({
    type: SHOW_TASK_EDITOR_MODAL,
    payload: { show, title, content },
});
