import { WorkItem } from '../typings';
import { UpdateWorkItemForeignKeys } from '../typings/generated';

type Data = { [x: string]: string | number };

interface Variables {
    data: Partial<WorkItem>;
    foreignKeys: UpdateWorkItemForeignKeys;
    workItemId: WorkItem['workItemId'];
}

export default (workItem: WorkItem, changedField: Partial<WorkItem>): Variables => {
    const variables: Variables = {
        workItemId: workItem.workItemId,
        data: {},
        foreignKeys: {},
    };

    const currentAssignee = workItem.assignees && workItem.assignees.length > 0 ? workItem.assignees[0] : null;

    Object.entries(changedField).forEach(([field, value]) => {
        switch (field) {
            case 'statusId':
                variables.foreignKeys.statusId = value as number;
                break;
            case 'officeId':
                variables.foreignKeys.officeId = value as number;
                break;
            case 'assignees':
                variables.foreignKeys.assignees = {
                    add: value ? [value as string] : undefined,
                    remove: currentAssignee ? [currentAssignee?.userId] : undefined,
                };
                break;
            default:
                variables.data = { ...variables.data, [field as keyof WorkItem]: value };
        }
    });

    return variables;
};
