import { ItemType } from '../../typings/itemType';
import { SET_ITEM_TYPES } from '../actions/ItemType';
/* eslint-disable no-param-reassign */
import { Action } from '../index';

export interface State {
    itemTypes: ItemType[];
}

const initialState: State = {
    itemTypes: [],
};

interface Payload {
    itemTypes: ItemType[];
}

interface ItemTypeAction extends Omit<Action, 'payload'> {
    payload: Payload;
}

export default (prevState: State, action: ItemTypeAction): State => {
    const state = prevState ?? initialState;
    const { type, payload } = action;

    // Change this back to a switch statement when the actions exceed 2
    // sonarqube requires at least 3 cases in a switch

    if (type === SET_ITEM_TYPES) {
        return { ...state, itemTypes: payload.itemTypes };
    }

    return { ...state };
};
/* eslint-enable no-param-reassign */
