import { createMuiTheme } from '@material-ui/core/styles';

import base, { customBorder, PrioritizeTheme } from './base';

const primary = '#B65405';
const secondary = '#67C88F';
const tertiary = '#C0C3C3';
const defaultBackground = '#fff';
const error = '#A8000E';
const commonBlack = '#232626';

const colors: PrioritizeTheme = {
    primary: {
        main: primary,
    },
    secondary: {
        main: secondary,
    },
    tertiary: {
        main: tertiary,
    },
    tasker: {
        main: '#404040',
    },
    appBar: {
        background: '#004d40',
        tabIndicator: defaultBackground,
    },
    card: {
        main: defaultBackground,
    },
    error: {
        light: '#ED5A5A',
        main: error,
        dark: '#D32F2F',
        contrastText: defaultBackground,
    },
    background: {
        default: defaultBackground,
        paper: '#F4F8F8',
    },
    divider: '#000',
};

const lightHighContrast = createMuiTheme({
    ...base,
    palette: {
        type: 'light',
        primary: colors.primary,
        secondary: colors.secondary,
        tertiary: colors.tertiary,
        tasker: colors.tasker,
        appBar: colors.appBar,
        divider: colors.divider,
        card: colors.card,
        background: colors.background,
        error: colors.error,
    },
    overrides: {
        ...base.overrides,
        MuiFormHelperText: {
            root: {
                color: error,
            },
        },
        MuiToggleButton: {
            root: {
                borderColor: commonBlack,
                padding: '8px',
                color: commonBlack,
                minWidth: '120px',
                '&$selected': {
                    border: customBorder(commonBlack),
                    backgroundColor: '#D5D5D5',
                    color: commonBlack,
                },
            },
        },
        MuiToggleButtonGroup: {
            groupedHorizontal: {
                '&:not(:first-child)': {
                    borderLeft: customBorder(commonBlack),
                },
            },
        },
    },
});

export default lightHighContrast;
