import { ReactElement, useState } from 'react';

import { SelectProps } from '../selectInterfaces';
import PrioritySelectForm from './PrioritySelectForm';
import PrioritySelectView from './PrioritySelectView';

const PrioritySelect = (props: SelectProps): ReactElement => {
    const [showForm, setShowForm] = useState(false);

    const Priorities = ['Low', 'Normal', 'High', 'Critical'];

    return showForm ? (
        <PrioritySelectForm setShowForm={setShowForm} priorities={Priorities} {...props} />
    ) : (
        <PrioritySelectView setShowForm={setShowForm} priorities={Priorities} {...props} />
    );
};

export default PrioritySelect;
