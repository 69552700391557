import { createMuiTheme } from '@material-ui/core/styles';

import base, { customBorder, PrioritizeTheme } from './base';

const primary = '#F2BC73';
const secondary = '#67C88F';
const tertiary = '#202020';
const paper = '#313131';
const card = '#181818';
const error = '#ff818b';
const textSecondary = '#bcbcbc';
const textPrimary = '#fff';

const colors: PrioritizeTheme = {
    primary: {
        main: primary,
    },
    secondary: {
        main: secondary,
    },
    tertiary: {
        main: tertiary,
    },
    tasker: {
        main: '#484848',
    },
    appBar: {
        background: card,
        tabIndicator: primary,
    },
    card: {
        main: card,
    },
    error: {
        main: error,
    },
    background: {
        default: tertiary,
        paper,
    },
    divider: textPrimary,
};

const darkHighContrast = createMuiTheme({
    ...base,
    palette: {
        type: 'dark',
        primary: colors.primary,
        secondary: colors.secondary,
        tertiary: colors.tertiary,
        tasker: colors.tasker,
        appBar: colors.appBar,
        divider: colors.divider,
        card: colors.card,
        background: colors.background,
        error: colors.error,
    },
    overrides: {
        ...base.overrides,
        MuiFormHelperText: {
            root: {
                color: error,
            },
        },
        MuiTab: {
            textColorPrimary: {
                color: textSecondary,
            },
        },
        MuiToggleButton: {
            root: {
                borderColor: colors.divider,
                padding: '8px',
                color: colors.divider,
                minWidth: '120px',
                '&$selected': {
                    border: customBorder(textPrimary),
                    backgroundColor: colors.background.paper,
                    color: colors.text,
                },
            },
        },
        MuiToggleButtonGroup: {
            groupedHorizontal: {
                '&:not(:first-child)': {
                    borderLeft: customBorder(textPrimary),
                },
            },
        },
    },
});

export default darkHighContrast;
